import { InjectionToken, Injector, NgModule } from '@angular/core';
import {
	HTTP_INTERCEPTORS as BASE_HTTP_INTERCEPTORS,
	HttpClient,
	HttpHandler,
	HttpInterceptor,
	ɵHttpInterceptingHandler,
} from '@angular/common/http';
import { HeaderInterceptor } from './auth-interceptor.service';

export const HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>('HTTP_INTERCEPTORS');

@NgModule({
	providers: [
		{
			provide: HttpClient,
			useClass: HttpClient,
		},
		{
			provide: HttpHandler,
			useClass: ɵHttpInterceptingHandler,
		},
		{
			provide: BASE_HTTP_INTERCEPTORS,
			multi: true,
			//deps: [Injector, HTTP_INTERCEPTORS],
			useExisting: HeaderInterceptor,
		},
	],
})
export class InterceptorInheritanceModule {}
