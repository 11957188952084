import { createAction, ExtractActionTypes } from 'src/app/store/store.types';
import { StaffListPayload, StaffListRequestParams, Employee, IEmployeeInvitation } from './staff.types';

export const GET_APPROVED_STAFF_LIST = '[staff] Get Approved Staff List';
export const APPROVED_STAFF_LIST_SUCCESS = '[staff] Approved Staff List Success';
export const SEARCH_EMPLOYEE_LIST = '[staff] Search Employee';
export const SEARCH_EMPLOYEE_SUCCESS = '[staff] Search Employee Success';
export const SET_SELECTED_EMPLOYEE = '[staff] Set Selected Employee';
export const SEND_STAFF_LOGIN_CREDENTIALS = '[staff] Send login credentials';
export const SEND_STAFF_LOGIN_CREDENTIALS_MANY = '[staff] Send login credentials many';
export const SEND_STAFF_LOGIN_CREDENTIALS_MANY_RES = '[staff] Send login credentials many response';

export const staffActions = {
	getApprovedStaffList: (payload: StaffListRequestParams) => createAction(GET_APPROVED_STAFF_LIST, { payload }),
	approvedStaffListSuccess: (payload: StaffListPayload) => createAction(APPROVED_STAFF_LIST_SUCCESS, { payload }),
	searchEmployeeList: (payload: any) => createAction(SEARCH_EMPLOYEE_LIST, { payload }),
	searchEmployeeSuccess: (payload: []) => createAction(SEARCH_EMPLOYEE_SUCCESS, { payload }),
	setSelectedEmployee: (payload: Employee) => createAction(SET_SELECTED_EMPLOYEE, { payload }),
	sendStaffLoginCredetials: (payload: IEmployeeInvitation) => createAction(SEND_STAFF_LOGIN_CREDENTIALS, { payload }),
	manyStaffsLoginCredetials: (payload) => createAction(SEND_STAFF_LOGIN_CREDENTIALS_MANY, { payload }),
	manyStaffsLoginCredetialsRes: (payload) => createAction(SEND_STAFF_LOGIN_CREDENTIALS_MANY_RES, { payload }),
};

export type StaffActions = ExtractActionTypes<typeof staffActions>;
