import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AppStoreModule } from './store/store.module';
import { EnrolmentViewComponent } from './admin/views/admission/enrolment/enrolment-view/enrolment-view.component';
import { FacilitySideMenuComponent } from './admin/views/admission/approval/facility-side-menu/facility-side-menu.component';
import { AddAssignstudentComponent } from './admin/views/transportation/assign_students/add-assignstudent/add-assignstudent.component';
import { VisitorFormComponent } from './admin/views/hostel/visitors/visitor-form/visitor-form.component';
import { VisitorEditFormComponent } from './admin/views/hostel/visitors/visitor-edit-form/visitor-edit-form.component';
import { OnlineOfflineStatusComponent } from './online-offline-status/online-offline-status.component';

@NgModule({
	declarations: [
		AppComponent,
		FacilitySideMenuComponent,
		AddAssignstudentComponent,
		VisitorFormComponent,
		VisitorEditFormComponent,
		OnlineOfflineStatusComponent,
		// EnrolmentViewComponent
	],
	imports: [BrowserModule, AppRoutingModule, BrowserAnimationsModule, SharedModule.forRoot(), AppStoreModule.forRoot()],
	providers: [],
	bootstrap: [AppComponent],
})
export class AppModule {}
