import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminState } from '../admin.store';

const selectFeatureAdmin = createFeatureSelector<AdminState>('admin');

const selectStaffState = createSelector(selectFeatureAdmin, (state) => state.staff);

export const selectStaffList = createSelector(selectStaffState, (state) => state.directoryList);

export const selectSearchStaffList = createSelector(selectStaffState, (state) => state.searchEmployee);

export const builkStaffLoginCredRes = createSelector(selectStaffState, (state) => state.staffLoginInvitaionRes);
