import { Directive, ElementRef, HostListener } from '@angular/core'
@Directive({
  selector: '[inputNotStartWithNum]'
})
export class InputNotStartWithNumDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event:any) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/^[0-9][A-Za-z0-9_]*$/, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

