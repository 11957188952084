import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { TransportationService } from 'src/app/admin/shared/services/transportation.service';
import { AlertService } from 'src/app/shared/Services/alert.service';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { sideNavigationActions } from 'src/app/store/side-navigation/side-navigation.actions';
import { admissionActions } from 'src/app/admin/nav/store/admission/admission.action';
import { selectSearchStudentResult } from 'src/app/admin/nav/store/admission/admission.selectors';
import { ApiStatus } from 'src/app/store/store.types';
import { commonActions } from 'src/app/store/common/common.action';
import { transactionAction } from '../../store/transportation.actions';
import * as moment from 'moment';
import { selectStudentTransportSaveApiStatusSuccess } from 'src/app/store/common/common.selector';
import { selectSideNavigationData } from 'src/app/store/side-navigation/side-navigation.selectors';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-add-assignstudent',
	templateUrl: './add-assignstudent.component.html',
	styleUrls: ['./add-assignstudent.component.scss'],
})
export class AddAssignstudentComponent implements OnInit, OnDestroy {
	@Output() onClose = new EventEmitter<boolean>();
	@ViewChild('multiStudentSearch') multiStudentSearchInput: ElementRef;

	createAssignStudentForm: FormGroup;

	rootData: any[] = [];
	busesData: any[] = [];
	studentData: any[] = [];
	_studentData: any[] = [];
	instopData: any[] = [];
	notExit: boolean = false;

	selectedValues = [];
	selectFormControl = new FormControl();
	searchTextboxControl = new FormControl();
	filteredOptions: Observable<any[]>;

	public currentDate: Date = new Date();
	public createHomeworkObj: any = {
		fromMaxDate: '',
		toMinDate: '',
		toMaxDate: '',
	};
	displayStudentName = (value) => (value === '' ? '' : `${value.first_name} ${value.last_name}`);
	studentSearchResult$: Observable<any[]>;
	unsubscribe$ = new Subject<undefined>();

	constructor(
		private alertService: AlertService,
		private transportationService: TransportationService,
		private formBuilder: FormBuilder,
		private store$: Store,
		private dialogRef: MatDialogRef<AddAssignstudentComponent>,
	) {}

	ngOnInit(): void {
		this.store$.dispatch(commonActions.updateApiStatus({ type: 'transport_student', status: ApiStatus.PROGRESS }));
		this.store$
			.select(selectStudentTransportSaveApiStatusSuccess)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((status) => {
				if (status) {
					this.onCloseClick();
				}
			});
		this.store$
			.select(selectSideNavigationData)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((data) => {
				this.getAssignStudentForm();
				if (data && data.mode === 'edit') {
					const { selectedRecord } = data;
					this.createAssignStudentForm.patchValue({
						route: selectedRecord.route.primary_key,
						vehicle: selectedRecord.vehicle.primary_key,
						student: selectedRecord.student,
						get_in_stop: selectedRecord.get_in_stop.primary_key,
						get_out_stop: selectedRecord.get_out_stop.primary_key,
						from_date: new Date(selectedRecord.from_date),
						to_date: new Date(selectedRecord.to_date),
					});
					this.selectRootName({
						vehicle_id: selectedRecord.vehicle.primary_key,
						route_uuid: selectedRecord.route.primary_key,
					});
				}
			});
		this.studentSearchResult$ = this.store$.select(selectSearchStudentResult).pipe(takeUntil(this.unsubscribe$));
		this.getRootList();
		this.createHomeworkObj.fromMaxDate = new Date(this.currentDate.setDate(new Date().getDate() + 10));
    let academicDataObj: any = JSON.parse(localStorage.getItem('academicDataObj'));
    let academic_end_date = academicDataObj.currentAcademic.academic_end_date;
		this.createHomeworkObj.toMaxDate = (academic_end_date);
	}

	getAssignStudentForm() {
		this.createAssignStudentForm = this.formBuilder.group({
			route: ['', Validators.required],
			vehicle: ['', Validators.required],
			student: ['', Validators.required],
			get_out_stop: ['', Validators.required],
			get_in_stop: ['', Validators.required],
			from_date: ['', Validators.compose([Validators.required])],
			to_date: ['', Validators.compose([Validators.required])],
		});
	}

	onInputChange() {
		const searchInput = this.multiStudentSearchInput.nativeElement.value
			? this.multiStudentSearchInput.nativeElement.value.toLowerCase()
			: '';
		this.studentData = this._studentData.filter((s) => {
			const name: string = s.first_name.toLowerCase();
			return name.indexOf(searchInput) > -1;
		});
	}

	getRootList() {
		this.transportationService.getRootlists().subscribe((res: any) => {
			this.rootData = res.data;
		});
	}

	selectRootName(section) {
		console.log(section, "select root after call API")
		let params: any = [];
		let academicDataObj: any = JSON.parse(localStorage.getItem('academicDataObj'));
		params.school = academicDataObj.schoolID;
		params.primary_key = section.vehicle;
		this.transportationService.getBusessList(params).subscribe((res: any) => {
			this.busesData = res.data;
		});

		let param: any = [];
		param.school = academicDataObj.schoolID;
		param.route = section.route_uuid;
		this.transportationService.getRouteStop(param).subscribe((res: any) => {
			if (res['status code'] === 200 && res.data.length>0) {
				this.instopData = res.data;
			}else {
				this.alertService.setAlert({message:'Stop data not found',type:'danger'});
				this.createAssignStudentForm.reset();
			}

			// this.alertService.setAlert({ message: 'From is date is greater than To date', type: 'danger' });
		},(error:any)=>{
			// this.alertService.setAlert({message:error.error.message,type:'danger'});
			this.alertService.setAlert({message:'Stop data not found',type:'danger'});
			this.createAssignStudentForm.reset();

		  }
		  );
	}

	getBusName() {
		this.transportationService.getBusesList().subscribe((res: any) => {
			this.busesData = res.data;
		});
	}
	selectBusName() {

	}
	selectInStopName() {

	}
	selectOutStopName() {

	}

	addAssignStudent() {
		const { student, route, from_date, to_date, ...rest } = this.createAssignStudentForm.value;
		console.log(route, "select route name")
		if (new Date(from_date).getTime() > new Date(to_date).getTime()) {
			return this.alertService.setAlert({ message: 'From is date is greater than To date', type: 'danger' });
		}
		const formData = {
			...rest,
			student: student.primary_key,
			route: route,
			from_date: moment(from_date).format('YYYY-MM-DD'),
			to_date: moment(to_date).format('YYYY-MM-DD'),
		};
		this.store$.dispatch(commonActions.updateApiStatus({ type: 'transport_student', status: ApiStatus.PROGRESS }));
		this.store$.dispatch(transactionAction.assignStudentToTransport(formData));
	}

	// onCloseClick() {
	// 	this.store$.dispatch(sideNavigationActions.closeSideNav());
	// }

	// onCloseClick() {
	// 	this.store$.dispatch(this.dialogRef.close());
	// }

	onCloseClick() {
		this.dialogRef.close();
	  }

	searchEvent(event) {
		this.store$.dispatch(admissionActions.searchEnrolledStudent(event.target.value));
	}

	onDateChange() {
		if (this.createAssignStudentForm.value.start_date) {
			this.createHomeworkObj.toMinDate = this.createAssignStudentForm.value.start_date;
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
