<section>
    <div class="row">
        <div class="col-12 cardDesign">
            <div>
                <div style="position: relative;">
                    <mat-icon class="closeIcon"  (click)='confirmExist(false)'>close</mat-icon>
                </div>
                <div class="text-center">
                    <p class="confirmText">Are you sure you want to Exit?</p>
                </div>
                <div class="row">
                    <div class="col-6">
                        <button type="button" (click)='confirmExist(true)' *ngIf='loadBtn'
                         mat-raised-button class="yesBtn">Yes</button>
                          <button mat-raised-button *ngIf='!loadBtn' type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Processing...
                          </button>
                    </div>
                    <div class="col-6">
                        <button type="button" (click)='confirmExist(false)'
                        mat-raised-button class="noBtn">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>