<header mat-dialog-title class="p-2 d-flex align-items-center justify-content-start">
    <h1 class="m-0 title">{{ title }}</h1>
</header>
<mat-divider></mat-divider>
<div mat-dialog-content class="p-5 d-flex flex-column">
    <form [formGroup]="transportForm" *ngIf="sideNavData?.type === 'transport'">
    <div class="d-flex flex-column">
            <mat-form-field appearance="outline" class="materialInputHeigt">
                <mat-label>Select Route</mat-label>
                <mat-select formControlName="route" (selectionChange)="onBusRouteChange($event)">
                    <mat-option
                    *ngFor="let route of busRouteList$ | async"  
                    [value]="route.route_uuid">{{route.route_number}}</mat-option>
                </mat-select>
            </mat-form-field>
             <mat-form-field appearance="outline" class="materialInputHeigt">
                <mat-label>Select Vehicle</mat-label>
                <mat-select formControlName="vehicle" (selectionChange)="onVehicleChange($event)">
                    <mat-option
                    *ngFor="let vehicle of transportVehicleList$ | async"  
                    [value]="vehicle.primary_key">{{vehicle.vehicle_id}} - {{vehicle.class_of_vehicle}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="materialInputHeigt">
                <mat-label>Select Get In Stop</mat-label>
                <mat-select formControlName="get_in_stop">
                    <mat-option
                    *ngFor="let stops of busRouteStopsList$ | async"  
                    [value]="stops.primary_key">{{stops.stop_name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="materialInputHeigt">
                <mat-label>Select Get Out Stop</mat-label>
                <mat-select formControlName="get_out_stop">
                    <mat-option
                    *ngFor="let stops of busRouteStopsList$ | async"  
                    [value]="stops.primary_key">{{stops.stop_name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field  appearance="outline" class="materialInputHeigt">
                <mat-label>From Date</mat-label>
                <input matInput [matDatepicker]="from_date"  [min]="minDate" 
                (click)='from_date.open()'   (focus)='from_date.open()'
                formControlName="from_date" name="from_date" readonly/>
                <mat-datepicker-toggle matSuffix [for]="from_date" style="color: #f79d04;"></mat-datepicker-toggle>
                <mat-datepicker #from_date></mat-datepicker>
            </mat-form-field>
            <mat-form-field  appearance="outline" class="materialInputHeigt">
                <mat-label>To Date</mat-label>
                <input matInput [matDatepicker]="to_date"  [min]="minDate" 
                (click)='to_date.open()'   (focus)='to_date.open()'
                formControlName="to_date" name="to_date" readonly/>
                <mat-datepicker-toggle matSuffix [for]="to_date" style="color: #f79d04;"></mat-datepicker-toggle>
                <mat-datepicker #to_date></mat-datepicker>
            </mat-form-field>
    </div>
</form>
<form [formGroup]="allocateBedForm" *ngIf="sideNavData?.type === 'hostel'">
    <mat-form-field appearance="outline" class="materialInputHeigt">
        <mat-label>Select Hostel</mat-label>
        <mat-select (selectionChange)="onHostelSelection($event,1)" formControlName="hostel" name="hostel">
            <ng-container *ngFor="let hostel of hostelList$ | async">
                <mat-option [value]="hostel.primary_key">{{ hostel.hostel_name }}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
    <!-- <mat-form-field appearance="outline" class="materialInputHeigt">
        <mat-label>Select Blocks</mat-label>
        <mat-select (selectionChange)="onBlockSelection($event)" formControlName="block" name="hostel">
            <ng-container *ngFor="let block of hostelBlocksList$ | async">
                <mat-option [value]="block.primary_key">{{ block.block_name }}</mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field> -->
    <mat-form-field appearance="outline" class="materialInputHeigt">
        <mat-label>- Select Block -</mat-label>
        <mat-select  (selectionChange)="onBlockSelection($event)"
        msInfiniteScroll (infiniteScroll)="getNextBlocks()" formControlName='block'
         [complete]="offset">
          <mat-option   *ngFor="let block of hostelBlocks" [value]="block.primary_key">
            {{block.block_name}}
        </mat-option>
        </mat-select>
      </mat-form-field>
    <mat-form-field appearance="outline" class="materialInputHeigt">
        <mat-label>Select Room</mat-label>
        <mat-select (selectionChange)="onRoomSelection()" msInfiniteScroll (infiniteScroll)="getNextRooms()"
        formControlName='hostel_room' [complete]="hostelRooms.scroll">
        <ng-container *ngFor='let room of hostelRooms.list' >              
            <mat-option [value]='room.primary_key' *ngIf='room.status'>
              {{room.room_number}}
            </mat-option>
          </ng-container>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="materialInputHeigt">
        <mat-label>Select Bed</mat-label>
        <mat-select msInfiniteScroll (infiniteScroll)="onRoomSelection()"
        formControlName='bed_number' [complete]="hostelBeds.scroll">
        <ng-container *ngFor='let bed of hostelBeds.list' >              
            <mat-option [value]='bed.primary_key'>
              {{bed.bed_number}}
            </mat-option>
          </ng-container>
        </mat-select>
    </mat-form-field>
    <mat-form-field  appearance="outline" class="materialInputHeigt">
        <mat-label>From Date</mat-label>
        <input matInput [matDatepicker]="from_date"  [min]="minDate" 
        (click)='from_date.open()'   (focus)='from_date.open()'
        formControlName="from_date" name="from_date" readonly/>
        <mat-datepicker-toggle matSuffix [for]="from_date" style="color: #f79d04;"></mat-datepicker-toggle>
        <mat-datepicker #from_date></mat-datepicker>
    </mat-form-field>
    <mat-form-field  appearance="outline" class="materialInputHeigt">
        <mat-label>To Date</mat-label>
        <input matInput [matDatepicker]="to_date"  [min]="minDate" 
        (click)='to_date.open()'   (focus)='to_date.open()'
        formControlName="to_date" name="to_date" readonly/>
        <mat-datepicker-toggle matSuffix [for]="to_date" style="color: #f79d04;"></mat-datepicker-toggle>
        <mat-datepicker #to_date></mat-datepicker>
    </mat-form-field>
</form>
</div>
<footer class="d-flex align-items-center justify-content-center" mat-dialog-actions>
    <button *ngIf="sideNavData?.type === 'transport'" mat-flat-button (click)="onSaveTransport()"  [disabled]="transportForm.invalid" class="background-primary text-white mr-2">Add</button>
    <button *ngIf="sideNavData?.type === 'hostel'" mat-flat-button (click)="onSaveHostelbed()"  [disabled]="allocateBedForm.invalid" class="background-primary text-white mr-2">Add</button>
    <button mat-flat-button (click)="onCancelDialog()">Cancel</button>
</footer>