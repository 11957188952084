import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-offline-status',
  templateUrl: './online-offline-status.component.html',
  styleUrls: ['./online-offline-status.component.scss']
})
export class OnlineOfflineStatusComponent implements OnInit {

  @Input() onlineStatusMessage: string;
  @Input() onlineStatus: string;

  constructor() { }

  ngOnInit(): void {

  }

  reload() : void {
    window.location.reload();
}

}
