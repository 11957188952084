import { AlertService } from './../../../shared/Services/alert.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  schoolId: any;
  employeeID: any;
  mobile_number: any;
  private $studentAttendanceSub = new Subject<any>();
  private $studentClassWiseList = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient, private alertService: AlertService) {
    this.schoolId = localStorage.getItem('school_id');
    this.employeeID = localStorage.getItem('employee_id');
    this.mobile_number = localStorage.getItem('loginMobile');
  }

  //get academic years
  getAcademicYears(): Observable<any> {
    let params = new HttpParams();
    params = params.append('school', this.schoolId);
    const url = `${environment.base_url}academic_sessions`;
    return this.http.get(url, { params });
  }

  //get sessions
  getSession(params: any): Observable<any> {
    return this.http.get(environment.base_url + "academic_sessions", { params: params });
  }

  //get sessions homework
  getSessionh(): Observable<any> {
    return this.http.get(environment.base_url + "academic_sessions");
  }

  //get divisions
  getDivisions(): Observable<any> {
    return this.http.get(environment.base_url + "division", { params: { school: this.schoolId } });
  }

  getSchoolClass_default(): Observable<any> {
    const url = `${environment.base_url}classes_section/list/${this.schoolId}`;
    return this.http.get(url);
  }

  getSchoolClassSession(divisionID: any): Observable<any> {
    return this.http.get(`${environment.base_url}classes_section/division_classes/${this.schoolId}/${divisionID}`);
  }

  // getSectionWiseStudentAttendance(session, month, classes, section, acedemaciYear): any {
  //   const formData: FormData = new FormData();
  //   formData.append('school_id', this.schoolId);
  //   formData.append('session_serial_no', session);
  //   formData.append('month', month);
  //   formData.append('class', classes);
  //   formData.append('section', section);
  //   formData.append('academic_start_date', acedemaciYear.start_date);
  //   formData.append('academic_end_date', acedemaciYear.end_date);
  //   const url = `${environment.base_url}/school/get_class_section_wise_student_attendance`;
  //   this.http.post(url, formData).subscribe((res: any) => {
  //     this.$studentAttendanceSub.next(res);
  //   }, (error) => {
  //     this.$studentAttendanceSub.next(null);
  //     this.alertService.setAlert({ message: error.error.message, type: 'danger' });
  //   });
  // }

  getClassSectionStudentList(classes, section, page): any {
    const formData: FormData = new FormData();
    formData.append('school_id', this.schoolId);
    formData.append('classes', classes);
    formData.append('section', section);
    formData.append('page', page);
    const url = `${environment.base_url}/school/get_class_and_section_students_list`;
    this.http.post(url, formData).subscribe((res: any) => {
      console.log(res)
      this.$studentClassWiseList.next(res);
    });
  }

  getStudendantAttendance(): Observable<any> {
    return this.$studentAttendanceSub.asObservable();
  }

  getStudentClassWiseList(): Observable<any> {
    return this.$studentClassWiseList.asObservable();
  }

  getSchoolInformation(): Observable<any> {
    let params = new HttpParams();
    params = params.append('school', this.schoolId);
    const url = `${environment.base_url}school_details`;
    return this.http.get(url, { params });
  }

  getEmployeeInformation(data:any): Observable<any> {
    let params = new HttpParams();
    params = params.append('school', this.schoolId);
    for (const key in data) {
      params = params.append(key, data[key]);
    }
    const url = `${environment.base_url}school_employee`;
    return this.http.get(url, { params });
  }

  //add the student attendance
  add_student_attendance(payload: any): Observable<any> {
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    return this.http.post<any>(environment.base_url + "student_attendance/create", formData);
  }
  	//get enrolled student list
	getSchoolStudentsList(params: any): Observable<any> {
		return this.http.get(environment.base_url + 'student_personal/get', { params: params });
	}
 //get attendance barrier
 getClassAttendacneBarrier(params):Observable<any>{
  let param = new HttpParams();
  for (const key in params) {
    param = param.append(key,params[key])
  }
  let Url = `${environment.base_url}school_class_attendance_barrier`;
  return this.http.get(Url, {params:param});
}
 //get attendance barrier
 getHostelattendanceBarrier(params):Observable<any>{
  let param = new HttpParams();
  for (const key in params) {
    param = param.append(key,params[key])
  }
  let Url = `${environment.base_url}hostel_attendance_barrier`;
  return this.http.get(Url, {params:param});
}
}
