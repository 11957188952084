import { Component } from '@angular/core';

@Component({
	template: `
		<main>
			<div>
				<div>
					<span>403&nbsp;error</span>
					<span>Unauthorized</span>
				</div>
				<svg viewBox="0 0 200 600">
					<polygon
						points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 200 591.044514 200 8"
					></polygon>
				</svg>
			</div>
			<svg class="crack" viewBox="0 0 200 600">
				<polyline
					points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514"
				></polyline>
			</svg>
			<div>
				<svg viewBox="0 0 200 600">
					<polygon
						points="118.302698 8 59.5369448 66.7657528 186.487016 193.715824 14 366.202839 153.491505 505.694344 68.1413353 591.044514 0 591.044514 0 8"
					></polygon>
				</svg>
				<div>
					<span>Sorry&nbsp;about&nbsp;that!</span>
					<span>
						<a href="javascript:void(0)" routerLink="/admin/dashboard">
							<b>Return&nbsp;Home?</b>
						</a>
					</span>
				</div>
			</div>
		</main>
	`,
	styleUrls: ['./unauthorized.component.scss'],
})
export default class UnauthorisedComponent {
	constructor() {}
}
