import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { is } from 'ramda';
export const isNullOrUndefined = (arg) => arg === undefined || arg === null;

export const isNotNullOrNotUndefined = (arg) => arg !== undefined || arg !== null;

export const removeKeyWhere =
	(predicate: (key: string, value: any) => boolean) =>
	<O extends Record<string, any>, R = Partial<O>>(object: O): R =>
		Object.entries(object).reduce<Partial<R>>((result, [key, value]) => {
			if (predicate(key, value)) {
				return result;
			}
			return {
				...result,
				[key]: value,
			};
		}, {} as Partial<R>) as R;

export const removeUnusedProperty = removeKeyWhere((_, value) => value === undefined || value === null || value === '');

export const jsonToFormData = (object) => {
	const formdata = Object.keys(object).reduce((formData, key) => {
		if (is(Array, object[key])) {
			formData.append(key, JSON.stringify(object[key]));
		} else formData.append(key, object[key]);
		return formData;
	}, new FormData());
	return formdata;
};

export const replaceParamsInString = (value: string, replaceParams: Record<string, string>): string =>
	Object.keys(replaceParams).reduce((result, next) => {
		return result.replace(`{{${next}}}`, replaceParams[next]);
	}, value);
export	const noWhitespaceValidator = (control: FormControl):any=> {
		const isWhitespace = (control.value || '').trim().length === 0;
		const isValid = !isWhitespace;
		return isValid ? null : { 'whitespace': true };
	}
export const countNumConditions = (arr)=>{
	const count = arr.filter(obj => {
	if (obj.deleted === false) {
		return true;
	}

	return false;
	}).length;
	return count;
}
const a = [
	'',
	'one ',
	'two ',
	'three ',
	'four ',
	'five ',
	'six ',
	'seven ',
	'eight ',
	'nine ',
	'ten ',
	'eleven ',
	'twelve ',
	'thirteen ',
	'fourteen ',
	'fifteen ',
	'sixteen ',
	'seventeen ',
	'eighteen ',
	'nineteen ',
];

const b = ['', '', 'twenty', 'thirty', 'fourty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

export const transformAmount=(value: any, args?: any)=> {
	if (value) {
		let num: any = Number(value);
		if (num) {
			if ((num = num.toString()).length > 9) {
				return 'We are not the Iron Bank, you can lower down the stakes :)';
			}
			const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
			if (!n) {
				return '';
			}
			let str = '';
			str += Number(n[1]) !== 0 ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'crore ' : '';
			str += Number(n[2]) !== 0 ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'lakh ' : '';
			str +=
				Number(n[3]) !== 0 ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'thousand ' : '';
			str += Number(n[4]) !== 0 ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'hundred ' : '';
			str +=
				Number(n[5]) !== 0
					? (str !== '' ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'rupee'
					: '';
			return str;
		} else {
			return '';
		}
	}else if(value === 0) {
		return 'Zero';
	} else {
		return '';
	}
}
export const convert12To24Format = (time)=>{
  let convertTime = moment(time, ["h:mm A"]).format("HH:mm");
  return convertTime;
}
