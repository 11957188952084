<!-- <div class="container " style="text-align: center;">
    <div class="row">
        <div class="col " style="margin-top: 45vh">
            <mat-icon aria-hidden="false" aria-label="Example home icon" class="bug">bug_report</mat-icon>
            <h1 for="error">This page is not found ! <span style="color: red;">404</span></h1>
        </div>
    </div>
</div> -->
<section class="totalPage">
<div id="error-page">
  <div class="content">
    <h2 class="header" data-text="404">
      404</h2>
    <h4 data-text="Opps! Page not found">
      Opps! Page not found</h4>
    <p>
      Sorry, the page you're looking for doesn't exist. If you think something is broken, report a problem.</p>
    <div class="btns">
      <a href="#" [routerLink]="['/admin/dashboard']">return home</a>
      <a href="javascript:void()" >report problem</a>
    </div>
  </div>
</div>
</section>