import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { removeUnusedProperty } from 'src/app/helpers/utils';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class HostelAllocateService {
	schoolId: any;
	employeeID: any;
	constructor(private _http: HttpClient) {
		this.schoolId = localStorage.getItem('school_id');
		this.employeeID = localStorage.getItem('employee_id');
	}

	getAttendanceOverview(date:any): Observable<any> {
		let params = new HttpParams();
		params = params.append('school', this.schoolId);
		params = params.append('date', date);
		const url = `${environment.base_url}hostel_block_vice_attendance_dashboard`;
		return this._http.get(url, { params: params });
	}

	getAttendanceOverviewFilter(data:any): Observable<any> {
		let params = new HttpParams();
		params = params.append('school', this.schoolId);

		for (const key in data) {
			params = params.append(key, data[key]);
		}
		const url = `${environment.base_url}hostel_block_vice_attendance_dashboard`;
		return this._http.get(url, { params: params });
	}

	//create beds
	studentBedAllocation(data: any): Observable<any> {
		let formData = new FormData();

		for (const key in data) {
			formData.append(key, data[key]);
		}
		const getUrl = environment.base_url + `student_bed_allocation`;
		return this._http.post<any>(getUrl, formData);
	}
	//get beds
	get_allocated_beds(params: any): Observable<any> {
		const getUrl = environment.base_url + `student_bed_allocation`;
		return this._http.get<any>(getUrl, { params: params });
	}
	//get beds
	get_hostel_students(params: any): Observable<any> {
		const getUrl = environment.base_url + `get_hostel_attendance_block_date`;
		return this._http.get<any>(getUrl, { params: params });
	}
	//get beds
	get_students(params: any): Observable<any> {
		const getUrl = environment.base_url + `student_personal/get`;
		return this._http.get<any>(getUrl, { params: params });
	}

	//CREATE FOOD MENU
	createFood_menu(data: any): Observable<any> {
		let formData = new FormData();

		for (const key in data) {
			formData.append(key, data[key]);
		}
		const getUrl = environment.base_url + `hostel_food_menu/post`;
		return this._http.post<any>(getUrl, formData);
	}
	//get beds
	get_food_menu(params: any): Observable<any> {
		const getUrl = environment.base_url + `hostel_food_menu/get`;
		return this._http.get<any>(getUrl, { params: params });
	}

	// updateFood_menu(data: any): Observable<any> {
	// 	let formData = new FormData();
	// 	for (const key in data) {
	// 		formData.append(key, data[key]);
	// 	}
	// 	const getUrl = environment.base_url + `hostel_food_menu/update`;
	// 	return this._http.patch<any>(getUrl, formData);
	// }

	getSchoolAllEmployee(params:any): Observable<any> {
		const url = `${environment.base_url}school_employee`;
		return this._http.get(url, { params: params });
	}

	studentHostelHolidayStatus(params:any): Observable<any> {
    let formData = new FormData();
        for (const key in params) {
          formData.append(key,params[key])
        }
		const url = `${environment.base_url}hostel_schedule_students_attendance`;
		return this._http.patch(url, formData);
	}
getStudentHolidayAttendance(params):Observable<any>{
  let httpParams = new HttpParams();
  for (const key in params) {
   httpParams = httpParams.append(key,params[key]);
  }
  const url = `${environment.base_url}hostel_schedule_students_attendance`;
  return this._http.get(url, {params:httpParams});
}

getStudentAttendanceDifference(params):Observable<any>{
  let httpParams = new HttpParams();
  for (const key in params) {
   httpParams = httpParams.append(key,params[key]);
  }
  const url = `${environment.base_url}hostel_attendance_students_difference_list`;
  return this._http.get(url, {params:httpParams});
}

updateStudentHostelAttendanceDifference(params):Observable<any>{
  let formData = new FormData();
  for (const key in params) {
    formData.append(key,params[key]);
  }
  const url = `${environment.base_url}hostel_room_attendance/patch`;
  return this._http.patch(url, formData);
}

updateStudentAttendanceDifference(params):Observable<any>{
  let httpPatams = new HttpParams();
  for (const key in params) {
    httpPatams = httpPatams.append(key,params[key]);
  }
  const url = `${environment.base_url}student_attendance/update?${httpPatams}`;
  return this._http.patch(url,'');
}
	//student attendance remarks
	studentAttendanceRemarks(data: any): Observable<any> {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		const getUrl = environment.base_url + `attendance_remark/post`;
		return this._http.post<any>(getUrl, formData);
	}
	//student attendance create
	studentAttendanceHostelCreate(data: any): Observable<any> {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		const getUrl = environment.base_url + `student_attendance/create`;
		return this._http.post<any>(getUrl, formData);
	}
	//student attendance update
	studentAttendanceHostelUpdate(data: any): Observable<any> {
		let params = new HttpParams();
		for (const key in data) {
			params = params.append(key, data[key]);
		}
		const getUrl = environment.base_url + `student_attendance/update`;
		return this._http.patch<any>(getUrl,'',{params:params});
	}

	//student attendance create
	uploadHostelStudentExcel(data: any): Observable<any> {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		const getUrl = environment.base_url + `upload_hostel_student_data`;
		return this._http.post<any>(getUrl, formData);
	}
  	//student attendance create
	downloadHostelAttendanceDifference(data: any): Observable<any> {
		let params = new HttpParams();
		for (const key in data) {
			params = params.append(key, data[key]);
		}
		const getUrl = environment.base_url + `students_attendance_difference_report`;
		return this._http.get<any>(getUrl, {params:params});
	}
  //allocate students export
	downloadHostelAllocateStudents(data: any): Observable<any> {
    let formatData = removeUnusedProperty(data);
		let params = new HttpParams();
		for (const key in formatData) {
			params = params.append(key, formatData[key]);
		}
		const getUrl = environment.base_url + `student_bed_allocation_report`;
		return this._http.get<any>(getUrl, {params:params});
	}
}
