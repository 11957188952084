import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Data } from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class TransportationService {
  schoolId: any;
  employeeID: any;
  acadmicID: any;
  addedby: any;
  public unfillStudent=new Subject;
  constructor(private http: HttpClient) {
    this.schoolId = localStorage.getItem('school_id');
    this.employeeID = localStorage.getItem('employee_id');
    this.addedby = localStorage.getItem('added_by')
    const academic_year = JSON.parse(localStorage.getItem('academicDataObj'));
    this.acadmicID = academic_year.academicID
  }

  setRefresh(status:any){
    // console.log(status);
    this.unfillStudent.next(status);
  }
  getRefresh(){
    return this.unfillStudent.asObservable();
  }


  getDepartmentsList(): Observable<any> {
    let params = new HttpParams();
    params = params.append('school', this.schoolId);
    const url = `${environment.base_url}departments`;
    return this.http.get(url, { params: params });
  }

  getBusesList(): Observable<any> {
    let params = new HttpParams();
    params = params.append('school', this.schoolId);
    const url = `${environment.base_url}transport_vehicle/get`;
    return this.http.get(url, { params: params });
  }

  getBusessList(params: any): Observable<any> {
    return this.http.get(environment.base_url + "transport_vehicle/get", { params: params });
  }


  getEmployeeList(): Observable<any> {
    let params = new HttpParams();
    params = params.append('school', this.schoolId);
    params = params.append('page', '1');
    params = params.append('employee_status', 'Approved')
    const url = `${environment.base_url}school_employee`;
    return this.http.get(url, { params: params });
  }

  getDriverList(): Observable<any> {
    let params = new HttpParams();
    params = params.append('school', this.schoolId);
    // params = params.append('page', '1');
    // params = params.append('employee_status', 'Approved')
    const url = `${environment.base_url}transport_driver/get`;
    return this.http.get(url, { params: params });
  }

  getAssignStudentList(): Observable<any> {
    let params = new HttpParams();
    params = params.append('school', this.schoolId);
    // params = params.append('page', '1');
    // params = params.append('employee_status', 'Approved')
    const url = `${environment.base_url}transport_student/get`;
    return this.http.get(url, { params: params });
  }

  postDriver(data: any): Observable<any> {
    const startdate = moment(data.start_date).format('YYYY-MM-DD');
    const enddate = moment(data.end_date).format('YYYY-MM-DD');
    // let datestart;
    // let dateend;
    // if(startdate){
    //   datestart = moment(startdate).format('YYYY-MM-DD');
    // };
    // if(enddate){
    //   dateend = moment(enddate).format('YYYY-MM-DD');
    // };

    const formData = new FormData();
    formData.append('school', this.schoolId);
    formData.append('vehicle', data.bus);
    formData.append('driver_details', data.employees);
    formData.append('added_by', this.employeeID);
    formData.append('care_taker', data.caretaker)
    formData.append('from_date', startdate);
    if(enddate != "Invalid date"  && enddate != undefined && enddate != null){
      formData.append('to_date', enddate);
    }
    const urlGet = `${environment.base_url}transport_driver/post`;
    return this.http.post(urlGet, formData);
  }

  FormDates(date: Date) {
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    return `${year}-${month}-${day}`
  }

  postStudent(data: any) {
    const startdate = this.FormDates(data.start_date);
    const enddate = this.FormDates(data.end_date);
    const formData = new FormData();
    formData.append('school', this.schoolId);
    formData.append('vehicle', data.bus);
    formData.append('student', data.students);
    formData.append('added_by', this.employeeID);
    formData.append('route', data.root.route_uuid);
    formData.append('get_in_stop', data.instop);
    formData.append('get_out_stop', data.outstop);
    formData.append('student_status_for_bus', 'True');
    formData.append('academic_year', this.acadmicID);
    formData.append('from_date', startdate);
    formData.append('to_date', enddate)
    const urlGet = `${environment.base_url}transport_student/post`;
    return this.http.post(urlGet, formData);

  }

  getRootlists(): Observable<any> {
    let params = new HttpParams();
    params = params.append('school', this.schoolId);
    const url = `${environment.base_url}transport_route/get`;
    return this.http.get(url, { params: params });
  }

  getStudentList() {
    let params = new HttpParams();
    params = params.append('school', this.schoolId);
    params = params.append('academic_year', this.acadmicID);
    params = params.append('search_input', '')
    const url = `${environment.base_url}search_school_student`;
    return this.http.get(url, { params: params });
  }


  // getRouteStop(){
  //   let params = new HttpParams();
  // 	params = params.append('school', this.schoolId);
  //   params = params.append('route', "6eaacd65-3bb6-4a7c-ba42-8cbb0bd34c9f")
  // 	const url = `${environment.base_url}transport_stops/get`;
  // 	return this.http.get(url, { params: params });
  // }

  getRouteStop(param: any): Observable<any> {
    return this.http.get(environment.base_url + "transport_stops/get", { params: param });
  }


}
