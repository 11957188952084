import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { FullCalendarModule } from '@fullcalendar/angular';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSortModule } from '@angular/material/sort';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const materialModules: any[] = [
	//   CdkTreeModule,
	MatAutocompleteModule,
	MatButtonModule,
	MatCardModule,
	MatCheckboxModule,
	MatChipsModule,
	MatDividerModule,
	MatExpansionModule,
	MatIconModule,
	MatInputModule,
	MatListModule,
	MatMenuModule,
	//   MatProgressSpinnerModule,
	MatProgressBarModule,
	MatPaginatorModule,
	MatRippleModule,
	MatSelectModule,
	MatSidenavModule,
	MatSnackBarModule,
	MatSortModule,
	MatTableModule,
	MatToolbarModule,
	MatFormFieldModule,
	MatButtonToggleModule,
	//   MatTreeModule,
	OverlayModule,
	MatDialogModule,
	DragDropModule,
	//   PortalModule,
	//   MatBadgeModule,
	MatRadioModule,
	MatDatepickerModule,
	MatNativeDateModule,
	MatTooltipModule,
	MatGridListModule,
	FullCalendarModule,
	MatTabsModule,
	MatStepperModule,
	CdkStepperModule,
	MatSelectInfiniteScrollModule,
  MatSlideToggleModule
];
@NgModule({
	imports: [...materialModules],
	exports: [...materialModules],
})
export class AngularMaterialModule {
	static forRoot() {
		return {
			ngModule: AngularMaterialModule,
			providers: [MatDatepickerModule],
		};
	}
}
