import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from } from 'rxjs';
import { catchError, map, switchMap ,mergeMap} from 'rxjs/operators';
import { BaseResponse } from 'src/app/admin/nav/store/store.types';
import { removeUnusedProperty } from 'src/app/helpers/utils';
import { ApiService } from 'src/app/shared/Services/api.service';
import { alertAction } from '../alert/alert.actions';
import {
	commonActions,
	CommonActions,
	GET_ACADEMIC_SESSIONS,
	GET_BUS_ROUTE_LIST,
	GET_BUS_ROUTE_STOPS_LIST,
	GET_CLASSES,
	GET_CLASSES_AND_SECTIONS,
	GET_DEPARTMENT_LIST,
	GET_DIVISION,
	GET_ROLES_LIST,
	GET_SMS_TEMPLATE,
	GET_STUDENT_BARRIER,
	GET_SUBJECTS,
	GET_TRANSPORT_VEHICLE_LIST,
	GET_WINGS_LIST,
} from './common.action';

@Injectable()
export class CommonEffects {
	constructor(private action$: Actions<CommonActions>, private apiService: ApiService) {}

	@Effect()
	getWingsList$ = this.action$.pipe(
		ofType(GET_WINGS_LIST),
		switchMap(() => {
			const { schoolID } = JSON.parse(localStorage.getItem('academicDataObj'));
			return this.apiService.doGet<BaseResponse>(`wings/list/${schoolID}`).pipe(
				map((response) => commonActions.wingsListSuccess(response.data)),
				catchError(() => from([commonActions.wingsListSuccess([])]))
			);
		})
	);

	@Effect()
	getDepartmentList$ = this.action$.pipe(
		ofType(GET_DEPARTMENT_LIST),
		map((action) => action.wing),
		switchMap((wing) => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
				school,
				wing,
			};
			return this.apiService.doGet<BaseResponse>(`departments`, removeUnusedProperty(params)).pipe(
				map((response) => commonActions.departmentListSuccess(response.data)),
				catchError(() => from([commonActions.departmentListSuccess([])]))
			);
		})
	);

	@Effect()
	getRolesList$ = this.action$.pipe(
		ofType(GET_ROLES_LIST),
		switchMap(({ wing, department }) => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
				school,
				wing,
				department,
			};
			return this.apiService.doGet<BaseResponse>(`roles`, removeUnusedProperty(params)).pipe(
				map((response) => commonActions.rolesListSuccess(response.data)),
				catchError(() => from([commonActions.rolesListSuccess([])]))
			);
		})
	);

	@Effect()
	getDivisionList$ = this.action$.pipe(
		ofType(GET_DIVISION),
		switchMap(() => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
				school,
			};
			return this.apiService.doGet<BaseResponse>('division', params).pipe(
				map((response) => {
					const data = response.data.sort((a, b) => {
						return a.division_name.localeCompare(b.division_name);
					});
					return commonActions.divisionListSuccess(data);
				}),
				catchError(() => from([commonActions.divisionListSuccess([])]))
			);
		})
	);

	@Effect()
	getClassesAndSectionList$ = this.action$.pipe(
		ofType(GET_CLASSES_AND_SECTIONS),
		map((action) => action.division),
		switchMap((division) => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			return this.apiService.doGet<BaseResponse>(`classes_section/division_classes/${school}/${division}`).pipe(
				map((response) => {
					// const data = response.data.sort((a, b) => {
					// 	return a.classes.localeCompare(b.classes);
					// });
          //From server only getting correct order
					return commonActions.classesAndSectionsListSuccess(response.data);
				}),
				catchError(() => from([commonActions.classesAndSectionsListSuccess([])]))
			);
		})
	);

	@Effect()
	getClassesList$ = this.action$.pipe(
		ofType(GET_CLASSES),
		switchMap(() => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			return this.apiService.doGet<BaseResponse>(`classes_section/list/${school}`).pipe(
				map((response) => {
					// const data = response.data.sort((a, b) => {
					// 	return a.classes.localeCompare(b.classes);
					// });
					return commonActions.classesAndSectionsListSuccess(response.data);
				}),
				catchError(() => from([commonActions.classesAndSectionsListSuccess([])]))
			);
		})
	);

	@Effect()
	getStudentBarrier$ = this.action$.pipe(
		ofType(GET_STUDENT_BARRIER),
		switchMap(() => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			return this.apiService
				.doGet<BaseResponse>('staff_and_student_barrier', { school })
				.pipe(map((response) => commonActions.studentBarrierSuccess(response.data)));
		})
	);

	@Effect()
	getSmsTemplate$ = this.action$.pipe(
		ofType(GET_SMS_TEMPLATE),
		map((action) => action.smsType),
		switchMap((smsType) => {
			return this.apiService.doGet<BaseResponse>(`sms_template/get?sms_type=${smsType}&status=True`).pipe(
				mergeMap((response) =>
        [
          commonActions.smsTemplateSuccess(response.data),
          response.data.length === 0 ? alertAction.error('SMS Templates not available to this SMS type'):
          alertAction.success('')
        ] ),
				catchError((error) => from([commonActions.smsTemplateSuccess(error)]))
			);
		})
	);

	@Effect()
	getAcademicSessions$ = this.action$.pipe(
		ofType(GET_ACADEMIC_SESSIONS),
		map((action) => action.session),
		switchMap((primary_key) => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
				school,
				primary_key,
			};
			return this.apiService.doGet<BaseResponse>(`academic_sessions`, params).pipe(
				map((response) => commonActions.academicSessionSuccess(response.data)),
				catchError(() => from([commonActions.academicSessionSuccess(null)]))
			);
		})
	);

	@Effect()
	getSubjects$ = this.action$.pipe(
		ofType(GET_SUBJECTS),
		map((action) => action.requestData),
		switchMap((payload) => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
				school,
				...payload,
			};
			return this.apiService.doGet<BaseResponse>(`classes_subjects`, params).pipe(
				map((response) => commonActions.subjectsListSuccess(response.data)),
				catchError(() => from([commonActions.subjectsListSuccess([])]))
			);
		})
	);

	@Effect()
	getBusRoutes$ = this.action$.pipe(
		ofType(GET_BUS_ROUTE_LIST),
		switchMap(() => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
				school,
			};
			return this.apiService.doGet<BaseResponse>(`transport_route/get`, params).pipe(
				map((response) => commonActions.busRouteListSuccess(response.data)),
				catchError(() => from([commonActions.busRouteListSuccess([])]))
			);
		})
	);

	@Effect()
	getBusRoutesStops$ = this.action$.pipe(
		ofType(GET_BUS_ROUTE_STOPS_LIST),
		map((action) => action.routeId),
		switchMap((route) => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
				school,
				route,
			};
			return this.apiService.doGet<BaseResponse>(`transport_stops/get`, params).pipe(
				map((response) => commonActions.busRouteStopsListSuccess(response.data)),
				catchError(() => from([commonActions.busRouteStopsListSuccess([])]))
			);
		})
	);

	@Effect()
	getTransportVehicles$ = this.action$.pipe(
		ofType(GET_TRANSPORT_VEHICLE_LIST),
		switchMap(() => {
			const { schoolID: school } = JSON.parse(localStorage.getItem('academicDataObj'));
			const params = {
				school,
			};
			return this.apiService.doGet<BaseResponse>(`transport_vehicle/get`, params).pipe(
				map((response) => commonActions.transportVehicleListSuccess(response.data)),
				catchError(() => from([commonActions.transportVehicleListSuccess([])]))
			);
		})
	);
}
