import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
	selector: '[defaultImageSet]',
})
export class DefaultImageDirective implements OnDestroy {
	count = 0;
    school_data:any;
	@Input() defaultImageSet: string;

	constructor(private eRef: ElementRef) {
        let schoolId = localStorage.getItem('school_id');
		let schoolDetails = JSON.parse(localStorage.getItem('schoolData'));
		schoolDetails.map((val, i) => {
			if (val.school_id == schoolId) {
				this.school_data = val;
			}
		});
    }

	@HostListener('error')
	loadFallbackImage() {
		//let fallbackUrl = JSON.parse(localStorage.getItem('schoolData'))[0].school_logo;
		const element: HTMLImageElement = this.eRef.nativeElement;
		if (this.count >= 1) this.defaultImageSet;
        element.setAttribute('src', this.school_data.school_logo);
		this.count++;
	}

	ngOnDestroy(): void {
		this.count = 0;
	}
}
