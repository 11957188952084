import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { equals } from 'ramda';
import { admissionActions } from 'src/app/admin/nav/store/admission/admission.action';
import { selectSearchStudentResult, selectStudentList } from 'src/app/admin/nav/store/admission/admission.selectors';
import { EnrolmentSuccessPayload } from 'src/app/admin/nav/store/admission/admission.types';
import { staffActions } from 'src/app/admin/nav/store/staff/staff.action';
import { selectStaffList } from 'src/app/admin/nav/store/staff/staff.selector';
import { StaffListPayload } from 'src/app/admin/nav/store/staff/staff.types';
import { ApprovalStatus } from 'src/app/admin/nav/store/approval/approval.type';

@Component({
	templateUrl: './employee-and-student-search.component.html',
	styleUrls: ['./employee-and-student-search.component.scss'],
})
export class StudentAndEmployeeSearchComponent implements OnInit, OnDestroy {
	studentList$: Observable<EnrolmentSuccessPayload>;
	searchStudents$: Observable<any>;
	employeeList$: Observable<StaffListPayload>;
	searchThroughList = new FormControl('');
	searchType = '';
	studentType = '';
	selectedList = [];
	selectionType = 'multiple';
  studentSearchType:boolean = false;
	unsubscribe = new Subject<undefined>();
	@HostListener('window:keyup.esc') onKeyUp() {
		this.dialogRef.close({ selectedList: this.selectedList, searchType: this.searchType });
	}
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public dialogRef: MatDialogRef<StudentAndEmployeeSearchComponent>,
		private store$: Store
	) {
    console.log(data)
		this.searchType = data.searchType;
		this.selectedList = data.selectedList;
		this.selectionType = data.selectionType;
		this.studentType = data.student_type;
	}

	ngOnInit(): void {
		this.studentList$ = this.store$.select(selectStudentList).pipe(takeUntil(this.unsubscribe));
    this.searchStudents$ = this.store$.select(selectSearchStudentResult).pipe(takeUntil(this.unsubscribe));
		this.employeeList$ = this.store$.select(selectStaffList).pipe(takeUntil(this.unsubscribe));

		this.searchThroughList.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((searchKey) => {
			if (this.data.searchType === 'Student') {
        this.studentSearchType = true;
        if(searchKey === ''){
          this.studentSearchType = false;
        }
				// this.studentList$ = this.store$.select(selectStudentList).pipe(
				// 	map((payload) => ({
				// 		...payload,
				// 		data: payload.data.filter((student) => {
				// 			return (
				// 				student.first_name.toLocaleLowerCase().indexOf(searchKey.toLowerCase()) === 0 ||
				// 				student.last_name.toLocaleLowerCase().indexOf(searchKey.toLowerCase()) === 0
				// 			);
				// 		}),
				// 	}))
				// );
        this.store$.dispatch(admissionActions.searchEnrolledStudent(searchKey));
			}
			if (this.data.searchType === 'Staff') {
				this.employeeList$ = this.store$.select(selectStaffList).pipe(
					map((payload) => ({
						...payload,
						data: payload.data.filter((employee) => {
							return (
								employee.first_name.toLocaleLowerCase().indexOf(searchKey.toLowerCase()) === 0 ||
								employee.last_name.toLocaleLowerCase().indexOf(searchKey.toLowerCase()) === 0
							);
						}),
					}))
				);
			}
		});
		if (this.data.searchType === 'Student') this.getStudentList();
		else this.getEmployeeList();

		this.dialogRef.backdropClick().subscribe((_) => {
			this.dialogRef.close({ selectedList: this.selectedList, searchType: this.searchType });
		});
	}

	getStudentList(page: number = 1) {
    this.studentType = this.studentType === 'All' ? this.studentType ='' : this.studentType;
		this.store$.dispatch(
			admissionActions.getEnrolmentList({
				page,
				status: ApprovalStatus.APPROVED,
        student_type:this.studentType
			})
		);
	}

	getEmployeeList(page: number = 1) {
		this.store$.dispatch(
			staffActions.getApprovedStaffList({
				page,
			})
		);
	}

	isThisSelected(id) {
		const filterList = this.selectedList.reduce((prev, current) => {
			return [...prev, current.id];
		}, []);
		return filterList.includes(id);
	}

	onSelectionChange(selectedData) {
		const { primary_key: id, first_name, last_name } = selectedData.option.value;
		if (!this.isThisSelected(id)) {
			this.selectedList.push({ id, name: `${first_name} ${last_name}`, type: this.searchType });
		} else {
			const index = this.selectedList.findIndex((value) => equals(value.id, id));
			this.selectedList.splice(index, 1);
		}
	}

	disableBasedOnSelectionType(primaryKey) {
		if (this.selectionType === 'single') {
			const isRecordExist = this.selectedList.filter((value) => value.type === this.searchType);
			return this.selectedList.length > 0 && isRecordExist.length > 0 && isRecordExist[0].id !== primaryKey;
		}
		return false;
	}

	onPagination(page) {
		if (this.data.searchType === 'Student') this.getStudentList(page);
		else this.getEmployeeList(page);
	}

  closeIcon(){
    this.dialogRef.close({ selectedList: this.selectedList, searchType: this.searchType });
  }

	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
	}
}
