import { NgbAlertModule, NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from './Services/alert.service';
import { ErrorPageComponent } from './Components/error-page/error-page.component';
import { AlertComponent } from './Components/alert/alert.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { LogoutConfirmationComponent } from '../admin/views/logout/logout-confirmation/logout-confirmation.component';
import { ApiService } from './Services/api.service';
import { ImageUrlFallbackDirective } from './directives/image-url-fallback.directive';
import { YesOrNoPipe } from './pipes/true-or-false.pipe';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FileInputAccessorModule } from 'file-input-accessor';
import { DefaultImageDirective } from './directives/default-school-logo.directive';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxPrintElementModule } from 'ngx-print-element';
import { HttpClientModule } from '@angular/common/http';
import UnauthorisedComponent from './Components/unauthorised/unauthorised.component';
import { NumbersAlphaDirective } from './directives/numbersAlpha';
import { NumbersOnlyDirective } from './directives/numbers-only-directive';
import { OnlyAlphabetsDirective } from './directives/alphabets-only';
import { AlphaWithSpecialCharDirective } from './directives/alphaWithSpecialChar';
import { InterceptorInheritanceModule } from '../Authentication/interceptor-inheritance.module';
import { AlphaNumSpecialCharDirective } from './directives/alphanumspecialchar';
import { SafePipe } from './pipes/safe.pipe';
import { PercentageDirective } from './directives/percentage.directive';
import { AlphaNumSymbolsDirective } from './directives/alphaNumSpecSymbols';
import { InputNotStartWithNumDirective } from './directives/input-not-start-num.directive';
import { CommonInputDirective } from './directives/common-input-directive';
import { FilterPipe } from './pipes/search-filter.pipe';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { StudentDefaultImage } from './directives/students-default-image.directive';
import { FiltersPipe } from './pipes/filter.pipe';
import { VehiclePipe } from './pipes/filters.pipe';
import { CommonService } from '../admin/shared/services/common.service';

@NgModule({
	declarations: [
		AlertComponent,
		ErrorPageComponent,
		UnauthorisedComponent,
		LogoutConfirmationComponent,
		ImageUrlFallbackDirective,
		YesOrNoPipe,
		DefaultImageDirective,
		NumbersAlphaDirective,
		NumbersOnlyDirective,
		OnlyAlphabetsDirective,
		AlphaWithSpecialCharDirective,
		AlphaNumSpecialCharDirective,
		SafePipe,
		PercentageDirective,
		AlphaNumSymbolsDirective,
		InputNotStartWithNumDirective,
		CommonInputDirective,
		FilterPipe,
		FiltersPipe,
		VehiclePipe,
		StudentDefaultImage,
	],
	imports: [
		InterceptorInheritanceModule,
		HttpClientModule,
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		SharedRoutingModule,
		NgbAlertModule,
		NgbModule,
		AngularMaterialModule,
		NgxMaterialTimepickerModule,
		MatSelectInfiniteScrollModule,
		NgbPaginationModule,
		FullCalendarModule,
		NgxPrintElementModule,
	],
	exports: [
		InterceptorInheritanceModule,
		HttpClientModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		AlertComponent,
		ErrorPageComponent,
		LogoutConfirmationComponent,
		ImageUrlFallbackDirective,
		YesOrNoPipe,
		AngularMaterialModule,
		NgxMaterialTimepickerModule,
		MatSelectInfiniteScrollModule,
		NgbPaginationModule,
		FullCalendarModule,
		FileInputAccessorModule,
		DefaultImageDirective,
		NgxPrintElementModule,
		NumbersAlphaDirective,
		NumbersOnlyDirective,
		OnlyAlphabetsDirective,
		AlphaWithSpecialCharDirective,
		AlphaNumSpecialCharDirective,
		SafePipe,
		PercentageDirective,
		AlphaNumSymbolsDirective,
		InputNotStartWithNumDirective,
		CommonInputDirective,
		FilterPipe,
		FiltersPipe,
		VehiclePipe,
		StudentDefaultImage
	],
	providers:[CommonService,
		VehiclePipe,
		{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
	]
})
export class SharedModule {
	static forRoot() {
		return {
			ngModule: SharedModule,
			providers: [ApiService, AlertService],
		};
	}
}
