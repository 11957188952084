<section>
    <div class="row m-0">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 bg-white">
        <div class="notfiHead rounded-top">
          <h3 class="m-0">Create Permission Pass</h3>
          <div>
            <mat-icon (click)="onClose()">close</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="totalCard scrollAdmin">
        <form [formGroup]="visitorForm" (ngSubmit)="onVisitorSubmit()" class="p-3">
            <div class="border">
                <!-- <h1 class="border-bottom pl-2 pt-1 pb-1 m-0 group-title">To Meet</h1> -->
                <div class="d-flex flex-column p-2">
                        <mat-form-field class="materialInputHeight"  appearance="outline">
                            <mat-label class="required">Select Hostel</mat-label>
                            <mat-select formControlName="hostel" name="hostel">
                                <ng-container *ngFor="let hostel of hostelList$ | async">
                                    <mat-option (click)="onHostelSelection(hostel.primary_key)"  *ngIf="hostel.status"
                                    [value]="hostel.primary_key">{{ hostel.hostel_name }}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error *ngIf="visitorForm.get('hostel').hasError('required')">Required</mat-error>
                          </mat-form-field>
                          <section class="d-flex">
                            <mat-form-field *ngIf="
                            visitorForm.get('hostel').value !== ''
                            " class="materialInputHeigt w-100 mx-1" appearance="outline">
                              <mat-label>Search with StudentID/Name</mat-label>
                                <input
                                    type="text"
                                    matInput #studentID
                                    (keyup)="searchEvent($event,'search')"
                                    placeholder="Search with StudentID/Name"

                                />
                                <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWithSearch">
                                    <mat-option
                                        style="height: 55px"
                                        *ngFor="let option of hostelStudentSearchResult$ | async"
                                        [value]="option"
                                        (click)="onStudentSelection(option)"
                                        class="search-list"
                                    >
                                        <div class="circular">
                                            <img aria-hidden appImageUrlFallback="assets/images/user.png" src="{{ option.photo }}" />
                                        </div>

                                        <span class="ml-10 mr-10">{{ option.first_name }} {{ option.last_name }}</span>
                                        <small>{{ option.class_name }}{{ option.section_name }}</small>
                                    </mat-option>
                                    <mat-option [disabled]="'true'" *ngIf="( hostelStudentSearchResult$ | async ).length === 0"> No record found </mat-option>
                                </mat-autocomplete> -->
                                <!-- <mat-icon class="search-icon" matSuffix>search</mat-icon> -->
                                <mat-error *ngIf="visitorForm.get('student').hasError('required')">Required</mat-error>
                            </mat-form-field>
                            <mat-form-field *ngIf="
                            visitorForm.get('hostel').value !== ''
                            " class="materialInputHeigt w-100 mx-1" appearance="outline">
                              <mat-label>Search Student</mat-label>
                                <input
                                    type="text"
                                    matInput #searchStudent
                                    (keyup)="searchEvent($event,'select')"
                                    placeholder="Search Student"
                                    [matAutocomplete]="auto"
                                />
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayWithSearch">
                                    <mat-option
                                        style="height: 55px"
                                        *ngFor="let option of hostelStudentSearchResult$ | async"
                                        [value]="option"
                                        (click)="onStudentSelection(option,'select')"
                                        class="search-list"
                                    >
                                        <div class="circular">
                                            <img  src="{{ option.photo }}"  student-default-image  width="20"  height="20"
                                            [image]="option.photo"
                                            [path]="option.gender === 'Male' ? 'assets/images/Boy.png':
                                            option.gender === 'Female' ? 'assets/images/Girl.png':
                                             'assets/images/Boy.png'" />
                                        </div>

                                        <span class="ml-10 mr-10">{{ option.first_name }} {{ option.last_name }}</span>
                                        <small>{{ option.class_name }}{{ option.section_name }}</small>
                                    </mat-option>
                                    <mat-option [disabled]="'true'" *ngIf="isShowdata &&( hostelStudentSearchResult$ | async ).length === 0"> No record found </mat-option>
                                </mat-autocomplete>
                                <mat-icon class="search-icon" matSuffix>search</mat-icon>
                                <mat-error *ngIf="visitorForm.get('student').hasError('required')">Required</mat-error>
                            </mat-form-field>
                          </section>

                          <div class="row">
                            <div class="col-12 d-flex align-items-center">
                                <label class="m-0" for="Class">Student Name</label> &nbsp;
                                <h3 class="m-0">: &nbsp; {{selectStudentDetails?.first_name}} {{selectStudentDetails?.last_name}}</h3>
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <label class="m-0" for="Class">Class Name</label> &nbsp;
                                <h3 class="m-0">: &nbsp;{{selectStudentDetails?.class_name}}</h3>
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <label class="m-0" for="Section">Section</label>&nbsp;
                                <h3 class="m-0">:&nbsp;{{selectStudentDetails?.section_name}}</h3>
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <label class="m-0" for="Section">Room No</label>&nbsp;
                                <h3 class="m-0">:&nbsp;{{selectStudentDetails?.hostel_room}}</h3>
                            </div>
                          </div>
                          <mat-form-field *ngIf="visitorForm.get('student').value !== ''"
                          class="materialInputHeight w-100" appearance="outline">
                            <mat-label>Relation To Student</mat-label>
                            <mat-select (selectionChange)="onRelationToStudentChange($event)" formControlName="relation_to_student">
                                <mat-option *ngFor="let rel of studentRelationTypes"
                                 [value]="rel.relation_type">{{rel.relation_type}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="(guardianList$ | async).length > 0" class="materialInputHeight w-100" appearance="outline">
                            <mat-label>Choose Guardian</mat-label>
                            <mat-select (selectionChange)="onGuardianChange($event)">
                                <mat-option *ngFor="let guardian of guardianList$ | async" [value]="guardian">{{ guardian.guardian_first_name }} {{ guardian.guardian_last_name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                </div>

            </div>
            <div class="border mt-2">
                <h1 class="border-bottom pl-2 pt-1 pb-1 m-0 group-title">Basic Details</h1>
                <div class=" p-2">
                    <div class="d-flex align-items-center">
                        <div class="pl-4 pr-4">
                            <label for="Photo">Photo</label>
                            <div class="circular">
                                <img src="{{visitorForm.controls.visitor_photo.value || '../../../../../../assets/images/attachementproof.png'}}"
                                (click)="visitor_photo.click()" title="Choose Image" alt="Image" class="border photo"/>
                                <input type="file"  accept=".jpg,.png,.jpeg"
                                    style="display:none;"
                                    formControlName="visitor_photo"
                                    (change)="onFileInput($event, 'visitor_photo')"
                                    #visitor_photo />
                            </div>
                        </div>
                        <div class="flex-fill d-flex flex-column">
                            <mat-form-field class="w-100 materialInputHeight"  appearance="outline">
                                <mat-label>Relative  Name</mat-label>
                                <input matInput formControlName="visitor_name" name="visitor_name"
                                (keypress)='lettersAndSpace($event)' maxlength="50"/>
                                <mat-error *ngIf="visitorForm.get('visitor_name').hasError('required')">Required</mat-error>
                            </mat-form-field>
                            <mat-form-field class="w-100 materialInputHeight"  appearance="outline">
                                <mat-label>Gender</mat-label>
                                <mat-select formControlName="gender" name="Gender">
                                  <mat-option value="Male" >Male</mat-option>
                                  <mat-option value="Female">Female</mat-option>
                                  <mat-option value="Others">Others</mat-option>
                                </mat-select>
                                <mat-error *ngIf="visitorForm.get('gender').hasError('required')">Required</mat-error>
                              </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex">
                        <mat-form-field class="materialInputHeight"  appearance="outline">
                            <mat-label>Mobile Number</mat-label>
                            <input matInput formControlName="mobile_number"  maxlength="10" name="mobile_number"
                            numbersOnly/>
                            <mat-error *ngIf="visitorForm.get('mobile_number').hasError('required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <mat-form-field class="w-100 materialInputHeight"  appearance="outline">
                        <mat-label>-Purpose Type-</mat-label>
                        <mat-select formControlName="purpose" name="purpose" (selectionChange)="selectPurposeType($event.value)">
                          <mat-option (click)="selectPurposeType(purpose)" *ngFor='let purpose of purposeTypes'
                          [value]="purpose" >{{purpose}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="visitorForm.get('gender').hasError('required')">Required</mat-error>
                      </mat-form-field>

                    <mat-form-field class="w-100" *ngIf="purposeReason"  appearance="outline">
                        <mat-label class="required">Purpose</mat-label>
                        <textarea matInput formControlName="purpose_description" name="purpose" maxlength="1000"></textarea>
                        <mat-error *ngIf="visitorForm.get('purpose_description').hasError('required')">Required</mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100"  appearance="outline">
                        <mat-label>Address</mat-label>
                        <textarea matInput formControlName="address" maxlength="200" name="address"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="border mt-2">
                <h1 class="border-bottom pl-2 pt-1 pb-1 m-0 group-title">Permission Time</h1>
                <div class="d-flex flex-column p-2">
                    <div class="d-flex flex-fill">
                        <mat-form-field  appearance="outline" class="flex-fill materialInputHeight">
                            <mat-label class="required">From Date</mat-label>
                            <input matInput [matDatepicker]="from_date" [min]="minDate"  [max]="maxDate"
                            (click)='from_date.open()'   (focus)='from_date.open()'
                            formControlName="from_date" name="from_date" readonly tabindex="-1"/>
                            <mat-datepicker-toggle matSuffix [for]="from_date"  class="color-primary"
                            style="font-size: 16px;" tabindex="-1"></mat-datepicker-toggle>
                            <mat-datepicker #from_date tabindex="-1"></mat-datepicker>
                            <mat-error *ngIf="visitorForm.get('from_date').hasError('required')">Required</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="ml-2 materialInputHeight">
                            <mat-label>From Time</mat-label>
                            <input  matInput formControlName='from_time' [ngxTimepicker]="from_time" readonly
                            tabindex="-1"/>
                            <mat-datepicker-toggle matSuffix [for]="from_time" tabindex="-1">
                                <mat-icon matDatepickerToggleIcon  class="color-primary" style="font-size: 16px;">
                                    schedule</mat-icon>
                            </mat-datepicker-toggle>
                            <ngx-material-timepicker #from_time></ngx-material-timepicker>
                            <mat-error *ngIf="visitorForm.get('from_time').hasError('required')">Required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="data d-flex">
                        <mat-form-field  appearance="outline" class="materialInputHeight">
                            <mat-label>Permission time</mat-label>
                            <input matInput numbersOnly maxlength="2" autocomplete="off"
                              formControlName="to_date" name="to_date" placeholder="Permission time"/>
                            <mat-error >Days between 1-30</mat-error>
                        </mat-form-field>
                        <mat-form-field  appearance="outline" class="materialInputHeight">
                            <mat-label>-Time Span-</mat-label>
                            <mat-select formControlName="days_time_type">
                                <mat-option value="Days">Days</mat-option>
                                <mat-option value="Hours">Hours</mat-option>
                              </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="materialInputHeight">
                            <mat-label>-Permitted by-</mat-label>
                            <mat-select msInfiniteScroll (infiniteScroll)="getNextEmployees($event)"
                             [complete]="stopEmpList" formControlName="added_by">
                              <mat-option   *ngFor="let emp of employeeListNames"
                               [value]="emp.primary_key">
                               {{emp?.first_name}} {{emp?.last_name}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="border mt-2">
                <h1 class="border-bottom pl-2 pt-1 pb-1 m-0 group-title">Attachment</h1>
                <div class="d-flex p-2 justify-content-center">
                    <div class="attachment d-flex flex-column align-items-center">
                        <label>ID CARD</label>
                        <img src="{{visitorForm.controls.visitor_id_card_photo.value || '../../../../../../assets/images/attachementproof.png'}}" class="rounded shadow"
                        (click)="visitor_id_card_photo.click()" title="Choose Image" alt="Image"/>
                        <input type="file"  accept=".jpg,.png,.jpeg"
                            style="display:none;"
                            formControlName="visitor_id_card_photo"
                            (change)="onFileInput($event, 'visitor_id_card_photo')"
                            #visitor_id_card_photo />
                    </div>
                    <div class="attachment ml-2 d-flex flex-column align-items-center">
                        <label>SIGNATURE</label>
                        <img src="{{visitorForm.controls.signature.value || '../../../../../../assets/images/attachementproof.png'}}" class="rounded shadow"
                        (click)="signature.click()" title="Choose Image" alt="Image"/>
                        <input type="file"  accept=".jpg,.png,.jpeg"
                            style="display:none;"
                            formControlName="signature"
                            (change)="onFileInput($event, 'signature')"
                            #signature />
                    </div>
                </div>
            </div>

            <div class="mt-4 mb-5 d-flex justify-content-center align-items-center">
                <button type="submit" class="sendUpdateBtn pl-3 pr-3" [disabled]="visitorForm.invalid"
                 mat-raised-button>Add</button>
            </div>
        </form>
    </div>
</section>
