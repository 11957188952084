<ng-container *ngIf="{
    studentList: studentList$ | async,
    employeeList: employeeList$ | async,
    searchStudents: searchStudents$ | async
} as observable">
<div>
    <h2 mat-dialog-title class="position-relative">
        <mat-toolbar>
            <mat-form-field appearance="outline" style="font-size: 12px;" class="w-100 search-field">
                <input matInput type="text" [formControl]="searchThroughList" placeholder="Search by firstname, lastname">
                <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                  <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
          </mat-toolbar>
          <mat-icon (click)="closeIcon()" class="closeIcon">close</mat-icon>
    </h2>
    <mat-dialog-content class="mat-typography m-0">
        <mat-selection-list (selectionChange)="onSelectionChange($event)">
            <ng-container *ngIf="searchType === 'Student'">
              <ng-container *ngIf="!studentSearchType">
                <mat-list-option class="m-2" [disabled]="disableBasedOnSelectionType(student.primary_key)"
                [selected]="isThisSelected(student.primary_key)" [value]="student"
                 *ngFor="let student of observable.studentList.data">
                    <mat-card-header>
                        <img  mat-card-avatar src="{{ student.photo }}"
                             student-default-image alt="student"
                                        [image]="student.photo"
                                        [path]="student.gender === 'Male' ? 'assets/images/Boy.png':
                                        student.gender === 'Female' ? 'assets/images/Girl.png':
                                                                            'assets/images/Boy.png'"/>
                        <mat-card-title>{{ student.first_name }} {{ student.last_name }}</mat-card-title>
                        <mat-card-subtitle>{{ student.class_name }} - {{ student.section_name }}</mat-card-subtitle>
                    </mat-card-header>
                </mat-list-option>
              </ng-container>
              <ng-container *ngIf="studentSearchType">
                <mat-list-option class="m-2" [disabled]="disableBasedOnSelectionType(student.primary_key)"
                [selected]="isThisSelected(student.primary_key)" [value]="student"
                 *ngFor="let student of observable.searchStudents">
                    <mat-card-header>
                        <img  mat-card-avatar src="{{ student.photo }}"
                             student-default-image alt="student"
                                        [image]="student.photo"
                                        [path]="student.gender === 'Male' ? 'assets/images/Boy.png':
                                        student.gender === 'Female' ? 'assets/images/Girl.png':
                                                                            'assets/images/Boy.png'"/>
                        <mat-card-title>{{ student.first_name }} {{ student.last_name }}</mat-card-title>
                        <mat-card-subtitle>{{ student.class_name }} - {{ student.section_name }}</mat-card-subtitle>
                    </mat-card-header>
                </mat-list-option>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="searchType === 'Staff'">
                <mat-list-option class="m-2" [disabled]="disableBasedOnSelectionType(employee.primary_key)" [selected]="isThisSelected(employee.primary_key)" [value]="employee" *ngFor="let employee of observable.employeeList.data">
                    <mat-card-header>
                        <img  mat-card-avatar src="{{ employee.employee_photo }}"
                        student-default-image alt="Employee"
                                        [image]="employee.photo"
                                        [path]="employee.gender === 'Male' ? 'assets/images/Staff-male.png':
                                        employee.gender === 'Female' ? 'assets/images/staff-Female.png':
                                                                            'assets/images/Staff-male.png'"/>
                        <mat-card-title>{{ employee.first_name }} {{ employee.last_name }}</mat-card-title>
                        <mat-card-subtitle>{{ employee.department_name }} - {{ employee.role_name }}</mat-card-subtitle>
                    </mat-card-header>
                </mat-list-option>
            </ng-container>
        </mat-selection-list>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <ng-container *ngIf="searchType === 'Student' && !studentSearchType">
            <ngb-pagination
                [collectionSize]="observable.studentList.total_pages * 10"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                (pageChange)="onPagination($event)"
            ></ngb-pagination>
        </ng-container>

        <ng-container *ngIf="searchType === 'Staff'">
            <ngb-pagination
                [collectionSize]="observable.employeeList.total_pages * 10"
                [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                (pageChange)="onPagination($event)"
            ></ngb-pagination>
        </ng-container>
    </mat-dialog-actions>
</div>
</ng-container>
