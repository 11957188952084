<div class="d-flex p-2 pl-3 align-items-center">
  <button title="Close" (click)='onCloseClick()' class="color-basic" mat-icon-button>
    <mat-icon class="refreshIcon">close</mat-icon>
  </button>
  <h4 class="m-0 "><b>Assign Student</b></h4>
  <span class="flex-fill"></span>
  <!-- <button title="Close"  (click)='onCloseClick()' class="color-primary" mat-icon-button>
      <mat-icon class="refreshIcon">close</mat-icon>
  </button> -->
</div>

<div class="content-scroll p-4">
  <form [formGroup]="createAssignStudentForm" (ngSubmit)="addAssignStudent()">

    <div class="d-flex flex-column">
      <mat-form-field class="w-100 materialInputHeigt" appearance="outline">
        <input
          type="text"
          matInput
          formControlName="student"
          (keyup)="searchEvent($event)"
          placeholder="Search Student"
          [matAutocomplete]="searchStudent"
        />
        <mat-autocomplete #searchStudent="matAutocomplete" [displayWith]="displayStudentName">
          <mat-option
            style="height: auto"
            *ngFor="let option of studentSearchResult$ | async"
            class="search-list"
            [value]="option"
          >
          <!-- <pre>{{option|json}}</pre> -->

            <!-- <div class="circular">
              <img aria-hidden appImageUrlFallback="assets/images/user.png" src="{{ option.photo }}" />
            </div> -->
            <div class="circular">
							<img src="{{ option.photo }}" student-default-image
							[image]="option.photo" alt="Student"
							[path]="option.gender === 'Male' ? 'assets/images/Boy.png':
                      option.gender === 'Female' ? 'assets/images/Girl.png':
                                                    'assets/images/Boy.png'" />
						</div>

            <span class="ml-10 mr-10">{{ option.first_name }} {{ option.last_name }}</span>
            <small>{{ option.class_name }}{{ option.section_name }}</small>
          </mat-option>
          <mat-option *ngIf="(studentSearchResult$ | async).length === 0"> No record found </mat-option>
        </mat-autocomplete>
        <mat-icon class="search-icon" matSuffix>search</mat-icon>
      </mat-form-field>
      <div class="d-flex flex-fill">
        <mat-form-field appearance="outline" class="materialInputHeigt w-100">
          <mat-label>-Select Route-</mat-label>

          <mat-select formControlName='route' name='route'>
            <ng-container *ngFor="let rootname of rootData">
              <mat-option (click)="selectRootName(rootname)" [value]="rootname.route_uuid">
                {{rootname.route_number}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="materialInputHeigt w-100 ml-2">
          <mat-label>-Select Bus-</mat-label>
          <mat-select (selectionChange)="selectBusName()" formControlName='vehicle' name='vehicle'>
            <mat-option [value]="bus.primary_key" *ngFor="let bus of busesData">
              {{bus.class_of_vehicle}}, {{bus.vehicle_id}}, {{bus.registration_no}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="d-flex flex-fill">
        <mat-form-field appearance="outline" class="materialInputHeigt w-100">
          <mat-label>-Select In Stop-</mat-label>
          <mat-select (selectionChange)="selectInStopName()" formControlName='get_in_stop' name='get_in_stop'>
            <mat-option [value]="stop.primary_key" *ngFor="let stop of instopData">
              {{stop.stop_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="materialInputHeigt w-100 ml-2">
          <mat-label>-Select Out Stop-</mat-label>
          <mat-select (selectionChange)="selectOutStopName()" formControlName='get_out_stop' name='get_out_stop'>
            <mat-option [value]="stop.primary_key" *ngFor="let stop of instopData">
              {{stop.stop_name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- <mat-form-field appearance="outline" class="materialInputHeigt">
        <mat-label>-Select Student-</mat-label>
        <mat-select formControlName="student" placeholder="Select Student" [multiple]="true">
          <input class="multiserchbox" type="text" autocomplete="off" matInput placeholder="Search student by bame" #multiStudentSearch (input)="onInputChange()">
          <mat-option *ngFor="let data of studentData" [value]="data.primary_key">
            {{data.first_name}}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- Search Input -->

      <!-- <mat-form-field class="w-100 materialInputHeigt" appearance="outline">
        <input
          type="text"
          matInput
          formControlName="student"
          (keyup)="searchEvent($event)"
          placeholder="Search Student"
          [matAutocomplete]="searchStudent"
        />
        <mat-autocomplete #searchStudent="matAutocomplete" [displayWith]="displayStudentName">
          <mat-option
            style="height: 55px"
            *ngFor="let option of studentSearchResult$ | async"
            class="search-list"
            [value]="option"
          >
            <div class="circular">
              <img aria-hidden appImageUrlFallback="assets/images/user.png" src="{{ option.photo }}" />
            </div>

            <span class="ml-10 mr-10">{{ option.first_name }} {{ option.last_name }}</span>
            <small>{{ option.class_name }}{{ option.section_name }}</small>
          </mat-option>
          <mat-option *ngIf="(studentSearchResult$ | async).length === 0"> No record found </mat-option>
        </mat-autocomplete>
        <mat-icon class="search-icon" matSuffix>search</mat-icon>
      </mat-form-field> -->
      <div class="d-flex flex-fill">
        <mat-form-field appearance="outline" class="materialInputHeigt w-100">
          <mat-label>From Date</mat-label>
          <input matInput [matDatepicker]="fromDate" (dateChange)="onDateChange()" (click)="fromDate.open()"
            (focus)='fromDate.open()' [max]="createHomeworkObj.fromMaxDate" formControlName='from_date'
            name='start_date' readonly />
          <mat-datepicker-toggle style="color:#0f73bd" matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate color="primary"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" class="materialInputHeigt w-100 ml-2">
          <mat-label>To Date</mat-label>
          <input matInput [matDatepicker]="toDate" (click)="toDate.open()" (focus)='toDate.open()'
            [min]="createHomeworkObj.toMinDate" [max]="createHomeworkObj.toMaxDate" formControlName='to_date'
            name='end_date' readonly />
          <mat-datepicker-toggle style="color:#0f73bd" matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate color="primary"></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="d-flex w-100 align-items-center justify-content-left mt-3">
        <button type="submit" mat-raised-button class="addDriver" [disabled]="!createAssignStudentForm.valid">Submit</button>
      </div>
    </div>
  </form>
  </div>
