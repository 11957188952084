import { MatDrawerMode } from '@angular/material/sidenav';

export enum ComponentName {
	FACILITY = 'facility-component',
	ADD_STUDENT_TO_TRANSPORT = 'add-student-to-transport-component',
	CREATE_PERMISSION_PASS_FORM = 'create-permission-pass-form',
	UPDATE_PERMISIION_PASS_FORM = 'update-permission-pass-form',
}
export interface SideNavProperties {
	mode?: MatDrawerMode;
	position?: 'start' | 'end';
	disableClose?: boolean;
	width?: string;
	data?: Record<string, any>;
}
export interface SideNavPayload {
	componentName: ComponentName | '';
	properties?: SideNavProperties;
}
