import { BaseResponse } from '../store.types';

export interface StudentApproval {
	student_enquiry_id: string;
	custom_enquiry_no: string;
	first_name: string;
	last_name: string;
	dob: string;
	classes: string;
	class_name: string;
	father_name: string;
	mother_name: string;
	father_mobile_number: string;
	status: string;
	enquiry_date: string;
	follow_up_date: string;
	image: string;
	gender: string;
	follow_up_by_employee: string;
	follow_up_by_employee_id: string;
	student_id: string;
}

export enum ApprovalStatus {
	PENDING = 'Pending',
	REVIEWED = 'Reviewed',
	REJECTED = 'Rejected',
	UPDATED_INFO = 'Update Info',
	APPROVED = 'Approved',
	ALL = 'all'
}
export interface ApprovalRequestParams {
	classes: string;
	page: number;
	status: ApprovalStatus;
	section?: ApprovalStatus;
}

export interface ApprovalListPayload {
	current_page_number: number;
	total_pages: number;
	data: StudentApproval[];
}

export interface ApprovalListSuccessResponse extends BaseResponse, ApprovalListPayload {
	current_page_number: number;
	total_pages: number;
	data: StudentApproval[];
}

export interface SaveParentDetailsPayload {
	saveType: string;
	parentInfo: Record<string, any>;
	addressInfo: Record<string, any>;
}
