import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Store } from '@ngrx/store';
import { Subject, timer } from 'rxjs';
import {fromEvent, Observable, Subscription} from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthService } from './Authentication/auth.service';
import { AlertService } from './shared/Services/alert.service';
import { sideNavigationActions } from './store/side-navigation/side-navigation.actions';
import { selectSideNavigationValues } from './store/side-navigation/side-navigation.selectors';
import { ComponentName } from './store/side-navigation/side-navigation.types';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {
	title = 'patashala-erp';

	componentToOpen: string = '';
	componentName = ComponentName;
	sideNavWidth = '90%';
	@ViewChild('sideNav') sidenav: MatSidenav;
	constructor(private dialogRef: MatDialog,
				private _authService: AuthService,
				private store$: Store,
				private alertService: AlertService) {}

	minutesDisplay = 0;
	secondsDisplay = 0;

	endTime = 1;

	unsubscribe$: Subject<void> = new Subject();
	timerSubscription: any;

	onlineEvent: Observable<Event>;
	offlineEvent: Observable<Event>;
	subscriptions: Subscription[] = [];
	connectionStatusMessage: string;
	connectionStatus: string;
	online:boolean=true;


	ngOnInit() {
		this.resetTimer();
		this._authService.userActionOccured.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
			if (this.timerSubscription) {
				this.timerSubscription.unsubscribe();
			}
			this.resetTimer();
		});

		/**
		* Get the online/offline status from browser window
		*/
		this.onlineEvent = fromEvent(window, 'online');
		this.offlineEvent = fromEvent(window, 'offline');

		this.subscriptions.push(this.onlineEvent.subscribe(e => {
		  this.connectionStatusMessage = 'Back to online';
		  this.connectionStatus = 'online';
		  if (this.connectionStatus = 'online') {
			this.alertService.setAlert({ message: 'Back to Online', type: 'success' });
			this.online=true;
		  }
		//   console.log('Online...');
		}));

		this.subscriptions.push(this.offlineEvent.subscribe(e => {
		  this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
		  this.connectionStatus = 'offline';
		  if (this.connectionStatus = 'offline'){
			this.alertService.setAlert({ message: 'You are not connected to internet', type: 'danger' });
			this.online=false;
		  }
		//   console.log('Offline...');
		}));
	}


	ngAfterViewInit(): void {
		this.listenToSideNavigation();
	}

	listenToSideNavigation = () => {
		this.store$
			.select(selectSideNavigationValues)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((sideNav) => {
				const { open, componentName, mode, position, disableClose, width } = sideNav;
				if (open) {
          console.log(open);

					this.sidenav.open();
					this.sidenav.mode = mode;
					this.sidenav.position = position;
					this.sidenav.disableClose = disableClose;
					this.sideNavWidth = width;
					this.componentToOpen = componentName;
				} else {
					this.sidenav.close();
					this.componentToOpen = '';
				}
			});
	};

	ngOnDestroy() {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
		    /**
			* Unsubscribe all subscriptions to avoid memory leak
			*/
			this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}

	resetTimer(endTime: number = this.endTime) {
		const interval = 20000;
		const duration = endTime * 60;
		this.timerSubscription = timer(0, interval)
			.pipe(take(duration))
			.subscribe(
				(value: any) => this.render((duration - +value) * interval),
				() => {},
				() => {
					this._authService.logOutUser();
					this.dialogRef.closeAll();
				}
			);
	}

	private render(count: any) {
		this.secondsDisplay = this.getSeconds(count);
		this.minutesDisplay = this.getMinutes(count);
	}

	private getSeconds(ticks: number) {
		const seconds = ((ticks % 60000) / 1000).toFixed(0);
		return this.pad(seconds);
	}

	private getMinutes(ticks: number) {
		const minutes = Math.floor(ticks / 60000);
		return this.pad(minutes);
	}

	private pad(digit: any) {
		return digit <= 9 ? '0' + digit : digit;
	}
	@HostListener('window:keyup', ['$event'])
	@HostListener('window:click', ['$event'])
	@HostListener('window:wheel', ['$event'])
	resetTimerCheck() {
		this._authService.notifyUserAction();
	}
}
