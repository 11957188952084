import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertService } from '../shared/Services/alert.service';
import { AuthService } from './auth.service';
import { Store } from '@ngrx/store';
import { sideNavigationActions } from '../store/side-navigation/side-navigation.actions';
import { alertAction } from '../store/alert/alert.actions';

@Injectable({
	providedIn: 'root',
})
export class HeaderInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private dialogRef: MatDialog,
		private _alertService: AlertService,
		private _authService: AuthService,
		private store$: Store
	) {}

	private handleAuthError(err: HttpErrorResponse): Observable<any> {
		//handle your auth error or rethrow
		if (err.status === 401) {
      if(err.url.includes('school_change_password')) {
        this.store$.dispatch(alertAction.error('Current password is not matching'));
        return;
      }
			//navigate /delete cookies or whatever
			this._authService.logOutUser();
			this.dialogRef.closeAll();
			this.store$.dispatch(sideNavigationActions.closeSideNav());
			this.store$.dispatch(alertAction.error('Session is expired. Please login again.'));
			return of(err.message);
		}
		if (err.status === 403) {
			this.store$.dispatch(alertAction.error(`You don't have proper access. Reach out to master admin`));
			//this.router.navigateByUrl('admin/unauthorized');
		}
		return throwError(err);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// let token_id = localStorage.getItem('token_url')!;
		// let token = window.atob(token_id);
		// if (token !== null) {
		// 	req = req.clone({ headers: req.headers.set('X-CSRFToken', token), withCredentials: true });
		// } else {
		// 	req = req.clone({ withCredentials: true });
		// }
		const { schoolID: school, employeeID: employee } = JSON.parse(localStorage.getItem('academicDataObj'));
		const headers = req.headers.append('school', school).append('employee', employee);
		req = req.clone({
			headers,
		});
		return next.handle(req).pipe(catchError((x) => this.handleAuthError(x)));
	}
}
