import { createAction, ExtractActionTypes } from 'src/app/store/store.types';
import { CreateHostelSMSRequest, HostelRoomsRequest, HostelStudentRequest } from './hostel.types';

export const SAVE_BED_ALLOCATE = '[hostel] [allocated bed] save bed';
export const SAVE_BED_ALLOCATE_RES = '[hostel] [allocated bed] res bed';
export const SAVE_BED_ALLOCATE_UPDATE = '[hostel] [allocated bed] update bed';
export const SAVE_BED_ALLOCATE_UPDATE_STATUS = '[hostel] [allocated bed] update bed status';
export const SAVE_BED_ALLOCATE_RES_UPDATE = '[hostel] [allocated bed] update bed res';
export const SAVE_BED_ALLOCATE_LIST = '[hostel] [allocated bed] beds list';
export const SAVE_BED_ALLOCATE_SUCCESS = '[hostel] [allocated bed] success list';
export const SAVE_BED_ALLOCATE_YEAR = '[hostel] year';
export const FOOD_MENU_LIST = '[Food Menu] list';
export const FOOD_MENU_SUCCESS = '[Food Menu] success';
export const FOOD_MENU_CREATE = '[Food Menu] create';
export const FOOD_MENU_CREATE_SUCCESS = '[Food Menu] create success';
export const FOOD_MENU_UPDATE = '[Food Menu] update';
export const FOOD_MENU_UPDATE_SUCCESS = '[Food Menu] update success';

export const ATTENDANCE_OVERVIEW_LIST = '[Attendance Overview] list';

export const GET_VISITORS_LIST = '[hostel] [visitors] Get Visitors List';
export const VISITORS_LIST_SUCCESS = '[hostel] [visitors] Visitors List Success';
export const GET_VISITOR_ONE = '[hostel] [visitors] Get Visitor opne';
export const VISITORS_ONE_RESPONSE = '[hostel] [visitors] Visitor one response';
export const GET_COUNT_VISITORS_LIST = '[hostel] [visitors] Get Visitors List count';
export const GET_COUNT_VISITORS_LIST_DATA = '[hostel] [visitors] Get Visitors List count data';
export const GET_HOSTEL_LIST = '[hostel] Get Hostel List';
export const HOSTEL_LIST_SUCCESS = '[hostel] Hostel List Success';
export const GET_HOSTEL_BLOCKS_LIST = '[hostel] Get Hostel Blocks List';
export const HOSTEL_BLOCKS_LIST_SUCCESS = '[hostel] Hostel Blocks List Success';
export const SEARCH_VISITORS = '[hostel] [visitors] Search Visitors';
export const SAVE_VISITORS = '[hostel] [visitor] Save Visitors';
export const SAVE_VISITORS_RESPONSE = '[hostel] [visitor] Save Visitors resposne';
export const SELECTED_VISITOR = '[hostel] [visitor] Selected Visitor';
export const UPDATE_VISITORS = '[hostel] [visitor] Update Visitors';
export const DELETE_VISITORS = '[hostel] [visitor] Delete Visitors';
export const GET_HOSTEL_SMS_LIST = '[hostel] [sms] Get Hostel SMS List';
export const SUCCESS_HOSTEL_SMS_LIST = '[hostel] [sms] Success Hostel SMS List';
export const GET_HOSTEL_STUDENT_LIST = '[hostel] [sms] Get Hostel Student List';
export const GET_HOSTEL_ROOMS_LIST = '[hostel] Get Hostel Rooms List';
export const HOSTEL_ROOMS_LIST_SUCCESS = '[hostel] Hostel Rooms List Success';
export const HOSTEL_STUDENT_LIST_SUCCESS = '[hostel] [sms] Hostel Student List Success';
export const CREATE_HOSTEL_SMS = '[hostel] Create Hostel SMS';
export const CREATE_STUDENT_HOSTEL_ATTENDANCE = '[hostel] [student] attendance create';
export const CREATE_STUDENT_ATTENDANCE_SUCCESS = '[hostel] [student] attendance create success';
export const CREATE_STUDENT_ATTENDANCE_LIST = '[hostel] [student] attendance list';
export const CREATE_STUDENT_ATTENDANCE_LIST_DATA = '[hostel] [student] attendance data';
export const CREATE_STUDENT_MEAL_ATTENDANCE = '[hostel] [student] meal create';
export const CREATE_STUDENT_MEAL_SUCCESS = '[hostel] [student] meal create success';
export const CREATE_STUDENT_ATTENDANCE_LIST_MEAL = '[hostel] [student] meal list';
export const CREATE_STUDENT_MEAL_DATA = '[hostel] [student] meal list data';
export const SEARCH_HOSTEL_STUDENT_LIST = '[hostel] Search Hostel Student List';
export const SEARCH_HOSTEL_STUDENT_LIST_SUCCESS = '[hostel] Search Hostel Student List Success';
export const GET_HOSTEL_BED_LIST = '[hostel] Get Hostel Bed List';
export const HOSTEL_BED_LIST_SUCCESS = '[hostel] Hostel Bed List Success';
export const GET_HOSTEL_ALLOCATED_BED = '[hostel] Get Hostel Allocated Bed';
export const HOSTEL_ALLOCATED_BED_SUCCESS = '[hostel] Get Hostel Allocated Bed Success';
export const SEARCH_STUDENT_HOSTEL = '[hostel] Get Hostel saerch student';
export const GET_HOSTEL_STUDENTS_SUCCESS = '[hostel] Get Hostel students success';
export const HOSTEL_PERMISSION_PASS_EXPORT = '[hostel] Hostel Permission Pass Export';
export const GET_STUDENT_BED_SHIFTED_HISTORY = '[hostel] Student Bed Shifted History';
export const SUCCESS_STUDENT_BED_SHIFTED_HISTORY = '[hostel] Success Student Bed Shifted History';
export const HOSTEL_DASHBOARD_DATA = '[hostel] hostel dashboard details';
export const HOSTEL_DASHBOARD_DATA_SUCCESS = '[hostel] dashboard details success';
export const HOSTEL_STUDENT_SUPPORT = '[hostel] student support';
export const HOSTEL_STUDENT_SUPPORT_DATA = '[hostel] student support details success';
export const HOSTEL_STUDENT_SUPPORT_UPDATE = '[hostel] student support update data';
export const HOSTEL_STUDENT_SUPPORT_UPDATE_STATUS = '[hostel] student support update details success';

export const SET_VISITORS_FILETRS = '[hostel] set visitors filters wise list';
export const CLICK_ATTENDANCE_BLOCK = '[hostel] attendance block select';
export const CLICK_ATTENDANCE_BLOCK_HOLIDAY = '[hostel] attendance block select holiday';

export const GET_HOSTEL_HOLIDAY_ATTENDANCE = '[hostel] holiday attendance';
export const GET_HOSTEL_HOLIDAY_ATTENDANCE_DATA = '[hostel] holiday attendance data';
export const GET_HOSTEL_HOLIDAY_STUDENTS = '[hostel] holiday attendance students';
export const GET_HOSTEL_HOLIDAY_STUDENTS_DATA = '[hostel] holiday attendance students data';

export const hostelActions = {
	student_bed_allocate: (payload) => createAction(SAVE_BED_ALLOCATE, { payload }),
	student_bed_allocate_res: (payload) => createAction(SAVE_BED_ALLOCATE_RES, { payload }),
	bed_allocated_list: (payload) => createAction(SAVE_BED_ALLOCATE_LIST, { payload }),
	bed_allocated_success: (payload) => createAction(SAVE_BED_ALLOCATE_SUCCESS, { payload }),
	bed_allocated_year: (payload) => createAction(SAVE_BED_ALLOCATE_YEAR, { payload }),
	student_bed_allocate_update: (payload) => createAction(SAVE_BED_ALLOCATE_UPDATE, { payload }),
	student_bed_allocate_update_status: (payload) => createAction(SAVE_BED_ALLOCATE_UPDATE_STATUS, { payload }),
	student_bed_allocate_update_res: (payload) => createAction(SAVE_BED_ALLOCATE_RES_UPDATE, { payload }),

	get_food_menu_list: (payload) => createAction(FOOD_MENU_LIST, { payload }),
	get_food_menu_success: (payload) => createAction(FOOD_MENU_SUCCESS, { payload }),
	get_food_menu_create: (payload) => createAction(FOOD_MENU_CREATE, { payload }),
	get_food_menu_create_success: (payload) => createAction(FOOD_MENU_CREATE_SUCCESS, { payload }),
	get_food_menu_update: (payload) => createAction(FOOD_MENU_UPDATE, { payload }),
	get_food_menu_update_success: (payload) => createAction(FOOD_MENU_UPDATE_SUCCESS, { payload }),

	get_attendance_overview: (payload) => createAction(ATTENDANCE_OVERVIEW_LIST, { payload }),

	getVisitorsList: (payload) => createAction(GET_VISITORS_LIST, { payload }),
	visitorsListSuccess: (payload) => createAction(VISITORS_LIST_SUCCESS, { payload }),
	getVisitorOne: (payload) => createAction(GET_VISITOR_ONE, { payload }),
	visitorsOneSuccess: (payload) => createAction(VISITORS_ONE_RESPONSE, { payload }),
	getHostelList: (payload) => createAction(GET_HOSTEL_LIST,{payload}),
	hostelListSuccess: (payload) => createAction(HOSTEL_LIST_SUCCESS, { payload }),
	getHostelBlocksList: (payload) => createAction(GET_HOSTEL_BLOCKS_LIST, { payload }),
	hostelBlocksListSuccess: (payload) => createAction(HOSTEL_BLOCKS_LIST_SUCCESS, { payload }),
	searchVisitors: (requestData) => createAction(SEARCH_VISITORS, { requestData }),
	saveVisitor: (payload) => createAction(SAVE_VISITORS, { payload }),
	saveVisitorResponse: (payload) => createAction(SAVE_VISITORS_RESPONSE, { payload }),
	selectedVisitor: (payload) => createAction(SELECTED_VISITOR, { payload }),
	updateVisitor: (payload) => createAction(UPDATE_VISITORS, { payload }),
	deleteVisitor: () => createAction(DELETE_VISITORS),
	getHostelSMSList: (payload) => createAction(GET_HOSTEL_SMS_LIST, { payload }),
	successHostelSMSList: (payload) => createAction(SUCCESS_HOSTEL_SMS_LIST, { payload }),
	getHostelStudentList: (payload: HostelStudentRequest) => createAction(GET_HOSTEL_STUDENT_LIST, { payload }),
	hostelStudentListSuccess: (payload) => createAction(HOSTEL_STUDENT_LIST_SUCCESS, { payload }),
	getHostelRoomsList: (payload: HostelRoomsRequest) => createAction(GET_HOSTEL_ROOMS_LIST, { payload }),
	hostelRoomsListSuccess: (payload) => createAction(HOSTEL_ROOMS_LIST_SUCCESS, { payload }),
	createHostelSMS: (payload: CreateHostelSMSRequest) => createAction(CREATE_HOSTEL_SMS, { payload }),
	addHostelAttendance: (payload) => createAction(CREATE_STUDENT_HOSTEL_ATTENDANCE, { payload }),
	addHostelAttendanceSuccess: (payload) => createAction(CREATE_STUDENT_ATTENDANCE_SUCCESS, { payload }),
	addHostelAttendanceList: (payload) => createAction(CREATE_STUDENT_ATTENDANCE_LIST, { payload }),
	addHostelAttendanceListData: (payload) => createAction(CREATE_STUDENT_ATTENDANCE_LIST_DATA, { payload }),
	searchHostelStudent: (searchInput) => createAction(SEARCH_HOSTEL_STUDENT_LIST, { searchInput }),
	searchHostelStudentSuccess: (payload) => createAction(SEARCH_HOSTEL_STUDENT_LIST_SUCCESS, { payload }),
	getHostelBeds: (payload) => createAction(GET_HOSTEL_BED_LIST, { payload }),
	hostelBedsSuccess: (payload) => createAction(HOSTEL_BED_LIST_SUCCESS, { payload }),
	getHostelAllocatedBeds: (payload) => createAction(GET_HOSTEL_ALLOCATED_BED, { payload }),
	hostelAllocatedBedsSuccess: (payload) => createAction(HOSTEL_ALLOCATED_BED_SUCCESS, { payload }),
	createMealAttendance: (payload) => createAction(CREATE_STUDENT_MEAL_ATTENDANCE, { payload }),
	addMealAttendanceSuccess: (payload) => createAction(CREATE_STUDENT_MEAL_SUCCESS, { payload }),
	mealHostelAttendanceListData: (payload) => createAction(CREATE_STUDENT_MEAL_DATA, { payload }),
	mealHostelAttendanceList: (payload) => createAction(CREATE_STUDENT_ATTENDANCE_LIST_MEAL, { payload }),
	hostelPermissionPassExport: (payload) => createAction(HOSTEL_PERMISSION_PASS_EXPORT, { payload }),
	getStudentBedShiftedHistory: () => createAction(GET_STUDENT_BED_SHIFTED_HISTORY),
	successStudentBedShiftedHistory: (payload) => createAction(SUCCESS_STUDENT_BED_SHIFTED_HISTORY, { payload }),

	getHostelDashboard: (payload) => createAction(HOSTEL_DASHBOARD_DATA, { payload }),
	getHostelDashboardData: (payload) => createAction(HOSTEL_DASHBOARD_DATA_SUCCESS, { payload }),

	getHostelStudentSupport: (payload) => createAction(HOSTEL_STUDENT_SUPPORT, { payload }),
	getHostelStudentSupportData: (payload) => createAction(HOSTEL_STUDENT_SUPPORT_DATA, { payload }),
	updateHostelStudentSupport: (payload) => createAction(HOSTEL_STUDENT_SUPPORT_UPDATE, { payload }),
	updateHostelStudentSupportData: (payload) => createAction(HOSTEL_STUDENT_SUPPORT_UPDATE_STATUS, { payload }),


	setVisitorsPassFilters: (payload) => createAction(SET_VISITORS_FILETRS, { payload }),
	getCountWiseVisitors: (payload) => createAction(GET_COUNT_VISITORS_LIST, { payload }),
	getCountWiseVisitorsData: (payload) => createAction(GET_COUNT_VISITORS_LIST_DATA, { payload }),

	selectBlockData: (payload) => createAction(CLICK_ATTENDANCE_BLOCK, { payload }),

	getHostelHolidayAttendace: (payload) => createAction(GET_HOSTEL_HOLIDAY_ATTENDANCE, { payload }),
	getHostelHolidayAttendaceData: (payload) => createAction(GET_HOSTEL_HOLIDAY_ATTENDANCE_DATA, { payload }),

	getHostelHolidaysStudents: (payload) => createAction(GET_HOSTEL_HOLIDAY_STUDENTS, { payload }),
	getHostelHolidaysStudentsData: (payload) => createAction(GET_HOSTEL_HOLIDAY_STUDENTS_DATA, { payload }),

  selectBlockHoliday: (payload) => createAction(CLICK_ATTENDANCE_BLOCK_HOLIDAY, { payload }),
	// searchStudentHostel: (searchText: string) => createAction(SEARCH_STUDENT_HOSTEL, { searchText }),
	// searchStudentSuccessList: (payload: any) => createAction(GET_HOSTEL_STUDENTS_SUCCESS, { payload }),
};

export type HostelActions = ExtractActionTypes<typeof hostelActions>;
