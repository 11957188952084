<app-alert></app-alert>
<mat-drawer-container class="drawer-main-container" *ngIf="online">
    <mat-drawer [ngStyle]="{ 'width': sideNavWidth }" autoFocus="false" #sideNav disableClose="true" mode="over" opened="false" [position]="'end'">
        <ng-container [ngSwitch]="componentToOpen">
            <ng-template [ngSwitchCase]="componentName.FACILITY">
                <app-facility-side-menu></app-facility-side-menu>
            </ng-template>
            <ng-template [ngSwitchCase]="componentName.ADD_STUDENT_TO_TRANSPORT">
                <app-add-assignstudent></app-add-assignstudent>
            </ng-template>
            <ng-template [ngSwitchCase]="componentName.CREATE_PERMISSION_PASS_FORM">
                <app-visitor-form></app-visitor-form>
            </ng-template>
            <ng-template [ngSwitchCase]="componentName.UPDATE_PERMISIION_PASS_FORM">
                <app-visitor-edit-form></app-visitor-edit-form>
            </ng-template>
        </ng-container>
    </mat-drawer>
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>


<app-online-offline-status [onlineStatusMessage]="connectionStatusMessage" 
                           [onlineStatus]="connectionStatus"
                           *ngIf="!online">
</app-online-offline-status>
