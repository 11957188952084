import { Directive, ElementRef, HostListener,Input,OnInit } from '@angular/core'
@Directive({
  selector: '[allowRequiredInput]'
})
export class CommonInputDirective implements OnInit {
    @Input() patternInput:any;
    pattern_format:any;
  constructor(private _el: ElementRef) {
    this.pattern_format = `/^${this.patternInput}+$/g`
  }

  ngOnInit() {

}
  @HostListener('input', ['$event']) onInputChange(event:any) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(this.pattern_format, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
