import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'vehicle' })
export class VehiclePipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any, searchText: any): any {

    return items.filter(function (search) {
        return search.registration_no.toLowerCase().indexOf(searchText.toLowerCase()) > -1
        // return search.first_name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1

    })

}
}