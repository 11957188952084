import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { equals } from 'ramda';
import { selectSideNavigationData } from 'src/app/store/side-navigation/side-navigation.selectors';
import {
	selectBusRoutesList,
	selectBusRouteStopsList,
	selectHostelBedsSaveApiStatusSuccess,
	selectStudentTransportSaveApiStatusSuccess,
	selectTransportVehicleList,
} from 'src/app/store/common/common.selector';
import { commonActions } from 'src/app/store/common/common.action';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sideNavigationActions } from 'src/app/store/side-navigation/side-navigation.actions';
import * as moment from 'moment';
import { admissionActions } from 'src/app/admin/nav/store/admission/admission.action';
import {
	selectHostelBedsList,
	selectHostelRoomsList,
	selectHostelsBlocksList,
	selectHostelsList,
} from 'src/app/admin/nav/store/hostel/hostel.selector';
import { hostelActions } from 'src/app/admin/nav/store/hostel/hostel.actions';
import {
	selectSelectedStudentPrimaryDetail,
	selectStudentPersonalDetails,
} from 'src/app/admin/nav/store/admission/admission.selectors';
import { ApiStatus } from 'src/app/store/store.types';

@Component({
	selector: 'app-facility-side-menu',
	templateUrl: './facility-side-menu.component.html',
	styleUrls: ['facility-side-menu.component.scss'],
})
export class FacilitySideMenuComponent implements OnInit, OnDestroy {
	title = '';
	minDate = new Date();
	sideNavData = null;
	transportForm: FormGroup;
	busRouteList$: Observable<any[]>;
	transportVehicleList$: Observable<any[]>;
	busRouteStopsList$: Observable<any[]>;
	block_page_num:number = 0;
	offset :boolean = false;
	allocateBedForm: FormGroup;
	hostelList$: Observable<any[]>;
	hostelBlocks:any[] = [];
	hostelBeds = {
		list: [],
		currentPage: 0,
		totalPage: 1,
		scroll: false,
	};
	hostelRooms = {
		list: [],
		currentPage: 0,
		totalPage: 1,
		scroll: false,
	};
	unsubscribe$ = new Subject<undefined>();
	constructor(private store$: Store, private fb: FormBuilder) {}

	ngOnInit(): void {
		this.store$.dispatch(commonActions.updateApiStatus({ type: 'student_bed_allocation', status: ApiStatus.PROGRESS }));
		this.store$.dispatch(commonActions.updateApiStatus({ type: 'transport_student', status: ApiStatus.PROGRESS }));
		this.store$
			.select(selectSideNavigationData)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((data) => {
				this.sideNavData = data;
				if (!equals(data, null)) {
					if (equals(data.type, 'transport')) {
						this.title = 'Add Transport Details';
						this.createTransportForm();
						this.busRouteList$ = this.store$.select(selectBusRoutesList).pipe(takeUntil(this.unsubscribe$));
						this.transportVehicleList$ = this.store$
							.select(selectTransportVehicleList)
							.pipe(takeUntil(this.unsubscribe$));
						this.busRouteStopsList$ = this.store$.select(selectBusRouteStopsList).pipe(takeUntil(this.unsubscribe$));

						this.store$
							.select(selectStudentTransportSaveApiStatusSuccess)
							.pipe(takeUntil(this.unsubscribe$))
							.subscribe((status) => {
								if (status) {
									this.store$.dispatch(admissionActions.getStudentTransportDetails());
									this.onCancelDialog();
								}
							});

						this.store$.dispatch(commonActions.getBusRouteList());
						this.store$.dispatch(commonActions.getTransportVehicleList());
					}
					if (equals(data.type, 'hostel')) {
						this.title = 'Allocate Bed to Student';
						this.createAllocateBedForm();
						this.hostelList$ = this.store$.select(selectHostelsList).pipe(takeUntil(this.unsubscribe$));
						this.store$.select(selectHostelsBlocksList).pipe(takeUntil(this.unsubscribe$)).subscribe((res:any)=>{
							if(Object.keys(res).length>0){
							  this.hostelBlocks.push(...res.data); 
							  this.block_page_num = res.current_page_number;     
							}else{
							  this.offset = true;
							}      
						  });
						this.store$
							.select(selectHostelRoomsList)
							.pipe(takeUntil(this.unsubscribe$))
							.subscribe((data) => {
								this.hostelRooms = {
									list: [...this.hostelRooms.list, ...data.data],
									currentPage: data.current_page_number,
									totalPage: data.total_pages,
									scroll: equals(data.current_page_number, data.total_pages),
								};
							});
						this.store$
							.select(selectHostelBedsList)
							.pipe(takeUntil(this.unsubscribe$))
							.subscribe((data) => {
								this.hostelBeds = {
									list: [...this.hostelBeds.list, ...data.data],
									currentPage: data.current_page_number,
									totalPage: data.total_pages,
									scroll: equals(data.current_page_number, data.total_pages),
								};
							});

						this.store$
							.select(selectHostelBedsSaveApiStatusSuccess)
							.pipe(takeUntil(this.unsubscribe$))
							.subscribe((status) => {
								if (status) {
									this.store$
										.select(selectStudentPersonalDetails)
										.pipe(takeUntil(this.unsubscribe$))
										.subscribe((personal) => {
											this.store$.dispatch(
												hostelActions.getHostelAllocatedBeds({
													student: personal.primary_key,
												})
											);
										});

									this.onCancelDialog();
								}
							});

						this.store$.dispatch(hostelActions.getHostelList({status:'True'}));
					}
				}
			});
	}

	createTransportForm() {
		this.transportForm = this.fb.group({
			route: ['', Validators.required],
			vehicle: ['', Validators.required],
			get_in_stop: ['', Validators.required],
			get_out_stop: ['', Validators.required],
			from_date: ['', Validators.required],
			to_date: ['', Validators.required],
		});
	}

	createAllocateBedForm() {
		this.allocateBedForm = this.fb.group({
			hostel: [''],
			block: [''],
			hostel_room: [''],
			bed_number: [''],
			from_date: [''],
			to_date: [''],
		});
	}

	onBusRouteChange({ value }) {
		this.store$.dispatch(commonActions.getBusRouteStopsList(value));
	}

	onVehicleChange({ value }){
		console.log(this.busRouteStopsList$);
	}
	onCancelDialog() {
		this.store$.dispatch(sideNavigationActions.closeSideNav());
	}

	onHostelSelection(event,page:number) {
		if(page === 1){
			this.hostelBlocks = [];
			this.offset = false;
			this.block_page_num = 0;
		}

		let params = {
			hostel: this.allocateBedForm.value.hostel,
			page: page,
			status:'True'
		};
		this.store$.dispatch(hostelActions.getHostelBlocksList(params));
	}

	getNextBlocks() {
		let hostel = this.allocateBedForm.value.hostel;
		this.block_page_num = this.block_page_num + 1;
		this.onHostelSelection(hostel, this.block_page_num);
	}

	onBlockSelection() {
		this.hostelRooms = {
			list: [],
			currentPage: 0,
			totalPage: 0,
			scroll: false,
		};
		this.getNextRooms();
	}

	getNextRooms() {
		if (!equals(this.hostelRooms.currentPage, this.hostelRooms.totalPage) || this.hostelRooms.currentPage === 0) {
			this.hostelRooms.scroll = false;
			this.store$.dispatch(
				hostelActions.getHostelRoomsList({
					hostel: this.allocateBedForm.value.hostel,
					hostel_block: this.allocateBedForm.value.block,
					page: this.hostelRooms.currentPage + 1,
				})
			);
		} else this.hostelRooms.scroll = true;
	}

	onRoomSelection() {
		this.hostelBeds = {
			list: [],
			currentPage: 0,
			totalPage: 0,
			scroll: false,
		};
		this.getHostelBeds();
	}

	getHostelBeds() {
		if (!equals(this.hostelBeds.currentPage, this.hostelBeds.totalPage) || this.hostelBeds.currentPage === 0) {
			this.hostelBeds.scroll = false;
			this.store$.dispatch(
				hostelActions.getHostelBeds({
					hostel_block: this.allocateBedForm.value.block,
					hostel_room: this.allocateBedForm.value.hostel_room,
					page: this.hostelBeds.currentPage + 1,
				})
			);
		} else this.hostelBeds.scroll = true;
	}

	onSaveTransport() {
		if (this.transportForm.valid) {
			const { from_date, to_date, ...rest } = this.transportForm.value;
			const formattedValue = {
				...rest,
				from_date: moment(from_date).format('YYYY-MM-DD'),
				to_date: moment(to_date).format('YYYY-MM-DD'),
			};
			this.store$.dispatch(commonActions.updateApiStatus({ type: 'transport_student', status: ApiStatus.PROGRESS }));
			this.store$.dispatch(admissionActions.addStudentTransport(formattedValue));
		} else {
			this.transportForm.markAllAsTouched();
		}
	}

	onSaveHostelbed() {
		if (this.allocateBedForm.valid) {
			const { from_date, to_date, ...rest } = this.allocateBedForm.value;
			const formattedValue = {
				...rest,
				from_date: moment(from_date).format('YYYY-MM-DD'),
				to_date: moment(to_date).format('YYYY-MM-DD'),
			};

			this.store$
				.select(selectSelectedStudentPrimaryDetail)
				.pipe(filter(Boolean), takeUntil(this.unsubscribe$))
				.subscribe(({ primary_key: student }) => {
					this.store$.dispatch(
						commonActions.updateApiStatus({ type: 'student_bed_allocation', status: ApiStatus.PROGRESS })
					);
					this.store$.dispatch(
						hostelActions.student_bed_allocate({
							student,
							...formattedValue,
						})
					);
				});
		} else {
			this.allocateBedForm.markAllAsTouched();
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
