import { createAction, ExtractActionTypes } from '../store.types';
import {
	EnrolmentRequestParams,
	EnrolmentSuccessPayload,
	IStudentPromotion,
	Student,
	StudentAddressDetails,
	StudentAttendanceDetails,
	StudentGuardianDetails,
	StudentParentDetails,
	StudentPersonalDetails,
} from './admission.types';

export const GET_ENROLMENT_LIST = '[admin] [admission] [enrolment] Get Enrolment list';
export const ENROLMENT_LIST_SUCCESS = '[admin] [admission] [enrolment] Get Enrolment Success';
export const SEARCH_ENROLLED_STUDENT = '[admin] [admission] [enrolment] Search Erolled Student';
export const SEARCH_STUDENT_SUCCESS = '[admin] [admission] [enrolment] Search Student Success';
export const SET_SELECTED_STUDENT = '[admin] [admission] [enrolment] Set Selected Student';
export const GET_STUDENT_PERSONAL_DETAILS = '[admin] [admission] [enrolment] Get Student Personal Details';
export const STUDENT_PERSONAL_DETAILS_SUCCESS = '[admin] [admission] [enrolment] Student Personal Details Success';
export const GET_STUDENT_PARENT_DETAILS = '[admin] [admission] [enrolment] Get Student Parent Details';
export const STUDENT_PARENT_DETAILS_SUCCESS = '[admin] [admission] [enrolment] Student Parent Details Success';
export const GET_STUDENT_ADDRESS_DETAILS = '[admin] [admission] [enrolment] Get Student Address Details';
export const STUDENT_ADDRESS_DETAILS_SUCCESS = '[admin] [admission] [enrolment] Student Address Details Success';
export const GET_STUDENT_GUARDIAN_DETAILS = '[admin] [admission] [enrolment] Get Student Guardinan Details';
export const STUDENT_GUARDIAN_DETAILS_SUCCESS = '[admin] [admission] [enrolment] Student Guardian Details Success';
export const GET_STUDENT_ATTENDANCE_DETAILS = '[admin] [admission] [enrolment] Get Student Attendance Details';
export const GET_STUDENT_TRANSPORT_DETAILS = '[admin] Get Student Transport Details';
export const STUDENT_TRANSPORT_DETAILS_SUCCESS = '[admin] Student Transport Details Success';
export const STUDENT_ATTENDANCE_DETAILS_SUCCESS = '[admin] [admission] [enrolment] Student Attendance Details Success';
export const CLEAR_SELECTED_STUDENT_DATA = '[admin] [admission] [enolment] Clear Selected Student Data';
export const SELECTED_STUDENT_DATA_REMARKS = '[admin] [admission] [enolment] Clear Selected Student remarks';
export const SELECTED_STUDENT_DATA_REMARKS_LIST = '[admin] [admission] [enolment] Clear Selected Student remarks list';
export const SELECTED_STUDENT_DATA_REMARKS_SUCCESS =
	'[admin] [admission] [enolment] Clear Selected Student remarks success';
export const SELECTED_STUDENT_DATA_DELETE = '[admin] [admission] [enolment] Clear Selected Student delete';
export const SELECTED_STUDENT_DATA_DELETE_SUCCESS = '[admin] [admission] [enolment]  Student delete success';
export const GET_STUDENT_FACILITY_DETAILS = '[admin] [admission] [enolment] Get Student Facility Details';
export const STUDENT_FACILITY_DETAILS_SUCCESS = '[admin] [admission] [enolment] Student Facility Details Success';

export const STUDENT_STATUS_CHANGE = '[admin] [direcctory] [enollment] Student status change';
export const STUDENT_STATUS_CHANGE_SUCCESS = '[admin] [direcctory] [enollment] Student status change success';

export const GET_STUDENT_FEES_DETAILS = '[admin] Get Student Fees Details';
export const STUDENT_FEES_DETAILS_SUCCESS = '[admin] Student Fees Details Success';
export const ADD_STUDENT_TRANSPORT = '[admin] Add Student Transport';
export const GET_STUDENTS_LIST_EXPORT = '[admin] get studnets list export';
export const GET_STUDENTS_LIST_EXPORT_RESPONSE = '[admin]  get studnets list export response';
export const GET_STAFF_LIST_EXPORT = '[staff] get staff list export';
export const GET_STAFF_LIST_EXPORT_RESPONSE = '[staff] get staff list export response';
export const STUDENT_PROMOTION_STUDENTS = '[student] promotion students';
export const STUDENT_PROMOTION_LIST_STUDENTS = '[student] promotion list students';
export const STUDENT_PROMOTION_API = '[student] promotion api call';
export const STUDENT_PROMOTION_API_RESPONSE = '[student] promotion response';
export const SEND_STUDENT_LOGIN_CREDENTIALS = '[student] login invitation credentials';
//export const SEND_STUDENT_LOGIN_CREDENTIALS_RES = '[student] login invitation credentials response';
export const SEND_STUDENTS_LOGIN_CREDENTIALS_MANY = '[students] Send login credentials many';
export const SEND_STUDENTS_LOGIN_CREDENTIALS_MANY_RES = '[students] Send login credentials many response';



export const admissionActions = {
	getEnrolmentList: (payload: EnrolmentRequestParams) => createAction(GET_ENROLMENT_LIST, { payload }),
	successEnrolmentList: (payload: EnrolmentSuccessPayload) => createAction(ENROLMENT_LIST_SUCCESS, { ...payload }),
	searchEnrolledStudent: (searchText: string) => createAction(SEARCH_ENROLLED_STUDENT, { searchText }),
	searchStudentSuccess: (payload: any) => createAction(SEARCH_STUDENT_SUCCESS, { payload }),
	setSelectedStudent: (selectedStudent: Student) => createAction(SET_SELECTED_STUDENT, { selectedStudent }),
	getStudentPersonalDetails: () => createAction(GET_STUDENT_PERSONAL_DETAILS),
	studentPersonalDetailsSuccess: (personalDetails: StudentPersonalDetails) =>
		createAction(STUDENT_PERSONAL_DETAILS_SUCCESS, { personalDetails }),
	getStudentParentDetails: () => createAction(GET_STUDENT_PARENT_DETAILS),
	studentParentDetailsSuccess: (parentDetails: StudentParentDetails) =>
		createAction(STUDENT_PARENT_DETAILS_SUCCESS, { parentDetails }),
	getStudentAddressDetails: () => createAction(GET_STUDENT_ADDRESS_DETAILS),
	studentAddressDetailsSuccess: (addressDetails: StudentAddressDetails) =>
		createAction(STUDENT_ADDRESS_DETAILS_SUCCESS, { addressDetails }),
	getStudentGuardianDetails: () => createAction(GET_STUDENT_GUARDIAN_DETAILS),
	studentGuardianDetailsSuccess: (guardianDetails: StudentGuardianDetails[]) =>
		createAction(STUDENT_GUARDIAN_DETAILS_SUCCESS, { guardianDetails }),
	getStudentTransportDetails: () => createAction(GET_STUDENT_TRANSPORT_DETAILS),
	studentTransportDetailsSuccess: (payload) => createAction(STUDENT_TRANSPORT_DETAILS_SUCCESS, { payload }),
	getStudentAttendanceDetails: (params: { month: string,attendance_time:string }) => createAction(GET_STUDENT_ATTENDANCE_DETAILS, params),
	studentAttendanceDetailsSuccess: (attendanceDetails: StudentAttendanceDetails[]) =>
		createAction(STUDENT_ATTENDANCE_DETAILS_SUCCESS, { attendanceDetails }),
	clearSelectedStudentData: () => createAction(CLEAR_SELECTED_STUDENT_DATA),
	SelectedStudentDataRemarks: (payload) => createAction(SELECTED_STUDENT_DATA_REMARKS, { payload }),
	SelectedStudentDataRemarksList: (payload) => createAction(SELECTED_STUDENT_DATA_REMARKS_LIST, { payload }),
	SelectedStudentDataRemarksSuccess: (payload) => createAction(SELECTED_STUDENT_DATA_REMARKS_SUCCESS, { payload }),
	deleteSelectedStudent: (payload) => createAction(SELECTED_STUDENT_DATA_DELETE, { payload }),
	deleteSelectedStudentSuccess: (payload) => createAction(SELECTED_STUDENT_DATA_DELETE_SUCCESS, { payload }),
	getStudentFacilityDetails: () => createAction(GET_STUDENT_FACILITY_DETAILS),
	studentFacilityDetailsSuccess: (payload) => createAction(STUDENT_FACILITY_DETAILS_SUCCESS, { payload }),
	changeStudentStatus: (payload) => createAction(STUDENT_STATUS_CHANGE, { payload }),
	changeStudentStatusSuccess: (payload) => createAction(STUDENT_STATUS_CHANGE_SUCCESS, { payload }),

	getStudentFeesDetails: () => createAction(GET_STUDENT_FEES_DETAILS),
	studentFeesDetailsSuccess: (payload) => createAction(STUDENT_FEES_DETAILS_SUCCESS, { payload }),
	addStudentTransport: (payload) => createAction(ADD_STUDENT_TRANSPORT, { payload }),

	stundentsListExportCall: (payload) => createAction(GET_STUDENTS_LIST_EXPORT, { payload }),
	stundentsListExportReponse: (payload) => createAction(GET_STUDENTS_LIST_EXPORT_RESPONSE, { payload }),
  staffListExportCall: (payload) => createAction(GET_STAFF_LIST_EXPORT, { payload }),
	staffListExportReponse: (payload) => createAction(GET_STAFF_LIST_EXPORT_RESPONSE, { payload }),
  studentPromotionStudents: (payload) => createAction(STUDENT_PROMOTION_STUDENTS, { payload }),
	studentPromotionStudentsList: (payload) => createAction(STUDENT_PROMOTION_LIST_STUDENTS, { payload }),
  studentPromotionApi: (payload:IStudentPromotion) => createAction(STUDENT_PROMOTION_API, { payload }),
	studentPromotionResult: (payload) => createAction(STUDENT_PROMOTION_API_RESPONSE, { payload }),

  sendStudentLoginCredetials: (payload) => createAction(SEND_STUDENT_LOGIN_CREDENTIALS, { payload }),
  //sendStudentLoginCredetialsResponse: (payload) => createAction(SEND_STUDENT_LOGIN_CREDENTIALS_RES, { payload }),
  manyStudentsLoginCredetials: (payload) => createAction(SEND_STUDENTS_LOGIN_CREDENTIALS_MANY, { payload }),
	manyStudentsLoginCredetialsRes: (payload) => createAction(SEND_STUDENTS_LOGIN_CREDENTIALS_MANY_RES, { payload }),
};

export type AdmissionActions = ExtractActionTypes<typeof admissionActions>;
