import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class HostelBarrierService {
	school_id: any;
	employee_id: any;
	constructor(private _http: HttpClient) {
		this.school_id = localStorage.getItem('school_id');
		this.employee_id = localStorage.getItem('employee_id');
	}

	// create pool service
	create_hostel(data: any): Observable<any> {
		let wgArr: any = [];
		const formData = new FormData();
		formData.append('school', this.school_id);
		formData.append('added_by', this.employee_id);
		formData.append('hostel_name', data.hostel_name.trim());
		formData.append('hostel_category', data.hostel_category);
		formData.append('door_no', data.door_no);
		formData.append('street_name', data.street_name);
		formData.append('locality', data.locality);
		formData.append('landmark', data.landmark);
		formData.append('pin_code', data.pin_code);
		formData.append('city', data.city);
		formData.append('state', data.state);
		formData.append('country', data.country);
		formData.append('latitude', data.latitude);
		formData.append('longitude', data.longitude);
		formData.append('incharge', data.incharge);
		formData.append('incharge_mobile_number', data.incharge_mobile_number);
		formData.append('expenses_head', data.expenses_head);
		formData.append('expenses_head_mobile_number', data.expenses_head_mobile_number);
		formData.append('hostel_camera', JSON.stringify(data.addChioces));
		formData.append('number_of_seats', data.number_of_seats);
		formData.append('status', 'True');
		for (let image: number = 0; image <= data.book_cover.length - 1; image++) {
			wgArr.push('image' + Number(image + 1));
			// formData.append('image' + Number(image+1), data.book_cover[image])
			// console.log(data.book_cover[image]);
			formData.append('hostel_images', data.book_cover[image]);
		}
		//formData.append('hostel_images', data.book_cover);

		const getUrl = environment.base_url + `school_hostel_detail`;
		return this._http.post<any>(getUrl, formData);
	}
	// create pool service
	update_hostel(data: any, primary_key: any): Observable<any> {
		let wgArr: any = [];

		const formData = new FormData();
		formData.append('school', this.school_id);
		formData.append('added_by', this.employee_id);
		formData.append('primary_key', primary_key.primary_key);
		formData.append('hostel_name', data.hostel_name);
		formData.append('hostel_category', data.hostel_category);
		formData.append('door_no', data.door_no);
		formData.append('street_name', data.street_name);
		formData.append('locality', data.locality);
		formData.append('landmark', data.landmark);
		formData.append('pin_code', data.pin_code);
		formData.append('city', data.city);
		formData.append('state', data.state);
		formData.append('country', data.country);
		formData.append('latitude', data.latitude);
		formData.append('longitude', data.longitude);
		formData.append('incharge', data.incharge);
		formData.append('incharge_mobile_number', data.incharge_mobile_number);
		formData.append('expenses_head', data.expenses_head);
		formData.append('expenses_head_mobile_number', data.expenses_head_mobile_number);
		formData.append('hostel_camera', JSON.stringify(data.addChioces));
		formData.append('number_of_seats', data.number_of_seats);
		for (let image: number = 0; image <= data.book_cover.length - 1; image++) {
			wgArr.push('image' + Number(image + 1));
			// formData.append('image' + Number(image+1), data.book_cover[image])
			// console.log(data.book_cover[image]);
			formData.append('hostel_images', data.book_cover[image]);
		}
		//formData.append('hostel_images', data.book_cover);

		const getUrl = environment.base_url + `school_hostel_detail`;
		return this._http.patch<any>(getUrl, formData);
	}
	getHostelEvents(params:any): Observable<any> {
		let param = new HttpParams();
		for (const key in params) {
			param = param.append(key, params[key]);
		}
		param = param.append('school', this.school_id);
		const url = environment.base_url + `school_hostel_detail`;
		return this._http.get(url, { params: param });
	}

	//delte hostel bank
	deleteHostel(data: any): Observable<any> {
		let formData = new FormData();
		formData.append('school', this.school_id);
		formData.append('primary_key', data.primary_key);
		formData.append('added_by', this.employee_id);
		const urlGet = `${environment.base_url}school_hostel_detail`;
		return this._http.request('delete', urlGet, { body: formData });
	}
	//delte hostel bank
	updateStatusHostel(data: any): Observable<any> {
		let status: string;
		if (data.status == true) {
			status = 'false';
		} else {
			status = 'true';
		}

		let formData = new FormData();
		formData.append('school', this.school_id);
		formData.append('primary_key', data.primary_key);
		formData.append('added_by', this.employee_id);
		formData.append('status', status);
		const getUrl = environment.base_url + `school_hostel_detail`;
		return this._http.patch<any>(getUrl, formData);
	}

	//delte hostel bank
	get_employees(data: any): Observable<any> {
		const getUrl = environment.base_url + `school_employee`;
		return this._http.get<any>(getUrl, { params: data });
	}

	//get hostel blocks
	get_hostel_blocks(params: any): Observable<any> {
		console.log(params);

		const getUrl = environment.base_url + `school_hostel_blocks`;
		return this._http.get<any>(getUrl, { params: params });
	}
	// create blocks service
	create_hostel_blocks(data: any): Observable<any> {
		const getUrl = environment.base_url + `school_hostel_blocks`;
		return this._http.post<any>(getUrl, data);
	}
	// create blocks service
	update_hostel_blocks(data: any): Observable<any> {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		const getUrl = environment.base_url + `school_hostel_blocks`;
		return this._http.patch<any>(getUrl, formData);
	}

	//delte hostel bank
	delete_block_name(data: any): Observable<any> {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		const urlGet = `${environment.base_url}school_hostel_blocks`;
		return this._http.request('delete', urlGet, { body: formData });
	}

	//ROOMS
	//create rooms
	create_rooms(data: any): Observable<any> {
		const getUrl = environment.base_url + `school_hostel_rooms`;
		return this._http.post<any>(getUrl, data);
	}

	//get rooms
	get_rooms(params: any): Observable<any> {
		const getUrl = environment.base_url + `school_hostel_rooms`;
		return this._http.get<any>(getUrl, { params: params });
	}
	//update room details
	update_room_details(data: any): Observable<any> {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		const getUrl = environment.base_url + `school_hostel_rooms`;
		return this._http.patch<any>(getUrl, formData);
	}
	//delte hostel bank
	delete_room_name(data: any): Observable<any> {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		const urlGet = `${environment.base_url}school_hostel_rooms`;
		return this._http.request('delete', urlGet, { body: formData });
	}

	//BEDS
	//create beds
	create_beds(data: any): Observable<any> {
		const getUrl = environment.base_url + `school_hostel_beds`;
		return this._http.post<any>(getUrl, data);
	}

	//get beds
	get_beds(params: any): Observable<any> {
		const getUrl = environment.base_url + `school_hostel_beds`;
		return this._http.get<any>(getUrl, { params: params });
	}
	//update room details
	update_bed_details(data: any): Observable<any> {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		const getUrl = environment.base_url + `school_hostel_beds`;
		return this._http.patch<any>(getUrl, formData);
	}
	//delte hostel bank
	delete_bed_name(data: any): Observable<any> {
		let formData = new FormData();
		for (const key in data) {
			formData.append(key, data[key]);
		}
		const urlGet = `${environment.base_url}school_hostel_beds`;
		return this._http.request('delete', urlGet, { body: formData });
	}

	get_hostelBarrier_timings(params: any): Observable<any> {
		const getUrl = environment.base_url + `hostel_attendance_barrier`;
		return this._http.get<any>(getUrl, { params: params });
	}

	get_attendance(params: any): Observable<any> {
		// let formData = new FormData();
		// formData.append('school', this.school_id);
		// formData.append('hostel', data);
		// formData.append('active_mode', 'True');
		const getUrl = environment.base_url + `hostel_attendance_barrier`;
		return this._http.get<any>(getUrl, { params: params });
	}

	add_attendance(data: any): Observable<any> {
		let formData = new FormData();
		formData.append('school', this.school_id);
		formData.append('added_by', this.employee_id);
    for (const key in data) {
      formData.append(key, data[key]);
    }
		// formData.append('hostel', data.hostelName);
		// formData.append('time', data.from_time);
		const getUrl = environment.base_url + `hostel_attendance_barrier`;
		return this._http.post<any>(getUrl, formData);
	}

	update_attendances(data: any, time:any): Observable<any> {
		const formData = new FormData();
		formData.append('school', this.school_id);
		formData.append('added_by', this.employee_id);
		formData.append('hostel', data.hostel.primary_key);
		formData.append('time', time);
		formData.append('primary_key', data.primary_key);
		const getUrl = environment.base_url + `hostel_attendance_barrier`;
		return this._http.patch<any>(getUrl, formData);
	}

	update_attendances_status(data: any): Observable<any> {
		const formData = new FormData();
		formData.append('school', this.school_id);
		formData.append('added_by', this.employee_id);
    for (const key in data) {
      formData.append(key, data[key]);
    }
		const getUrl = environment.base_url + `hostel_attendance_barrier`;
		return this._http.patch<any>(getUrl, formData);
	}

	add_incharge(formValues:any, start_dates:any, to_dates:any): Observable<any> {
		let formData = new FormData();
		formData.append('school', this.school_id);
		formData.append('hostel', formValues.hostelName);
		formData.append('staff', formValues.incharge);
		formData.append('blocks', JSON.stringify(formValues.block_name));
		formData.append('staff_type', formValues.staff_type);
    if(formValues.staff_type === "Temporary"){
      formData.append('from_date', start_dates);
      formData.append('to_date', to_dates);
    }
		const getUrl = environment.base_url + `hostel_staff`;
		return this._http.post<any>(getUrl, formData);
	}

	// add_incharge(data: any): Observable<any> {
	// 	const getUrl = environment.base_url + `hostel_staff`;
	// 	return this._http.post<any>(getUrl, data);
	// }

	get_incharge(params: any): Observable<any> {
		const getUrl = environment.base_url + `hostel_staff`;
		return this._http.get<any>(getUrl, { params: params });
	}



  //HOSTEL SCHEDULE BARRIER
	create_hostel_schedule_barrier(data: any): Observable<any> {
    const {schoolID:school,employeeID:added_by} = JSON.parse(localStorage.getItem('academicDataObj'));
    const formData = new FormData();
    formData.append('school',school);
    formData.append('added_by',added_by);
    for (const key in data) {
      formData.append(key,data[key]);
    };
		const getUrl = environment.base_url + `school_hostel_schedule`;
		return this._http.post<any>(getUrl, formData);
	}

	get_hostel_schedule_barrier(data: any): Observable<any> {
    const {schoolID:school} = JSON.parse(localStorage.getItem('academicDataObj'));
    let params = new HttpParams();
    params = params.append('school',school);
    for (const key in data) {
      if(data[key] !== ''){
        params = params.append(key,data[key]);
      }
    };
		const getUrl = environment.base_url + `school_hostel_schedule`;
		return this._http.get<any>(getUrl, {params});
	}
  //delete hostel schedule
	delete_hostel_schedule_barrier(data: any): Observable<any> {
    const {schoolID:school,employeeID:added_by} = JSON.parse(localStorage.getItem('academicDataObj'));
    let formData = new FormData();
    formData.append('school',school);
    formData.append('added_by',added_by);
    for (const key in data) {
      formData.append(key,data[key]);
    };
    const httpOptions: any = {
      body: formData
    };
		const getUrl = environment.base_url + `school_hostel_schedule`;
		return this._http.delete<any>(getUrl, httpOptions);
	}

	get_relation_type(): Observable<any> {
    const {schoolID:school} = JSON.parse(localStorage.getItem('academicDataObj'));
		let params = new HttpParams();
		params = params.append('school', school);
		const url = environment.base_url + `student_relation_types`;
		return this._http.get(url, { params: params });
	}

  add_relation_type(data: any): Observable<any> {
		let Url = `${environment.base_url}student_relation_types`;
		return this._http.post(Url, data);
	}
	update_relation_type(data: any): Observable<any> {
    const {schoolID:school,employeeID:added_by} = JSON.parse(localStorage.getItem('academicDataObj'));
    let formData = new FormData();
    formData.append('school',school);
    formData.append('added_by',added_by);
    for (const key in data) {
      formData.append(key,data[key]);
    };
		let Url = `${environment.base_url}student_relation_types`;
		return this._http.patch(Url, formData);
	}
	delete_relation_type(data: any): Observable<any> {
    const {schoolID:school,employeeID:added_by} = JSON.parse(localStorage.getItem('academicDataObj'));
    let formData = new FormData();
    formData.append('school',school);
    formData.append('added_by',added_by);
    for (const key in data) {
      formData.append(key,data[key]);
    };
    const httpOptions: any = {
      body: formData
    };
		let Url = `${environment.base_url}student_relation_types`;
		return this._http.delete(Url,httpOptions);
	}

	get_relation_types(data:Object): Observable<any> {
    const {schoolID:school} = JSON.parse(localStorage.getItem('academicDataObj'));
		let params = new HttpParams();
		params = params.append('school', school);
    for (const key in data) {
      params = params.append(key, data[key]);
    }
		const url = environment.base_url + `student_relation_types`;
		return this._http.get(url, { params: params });
	}
}
