import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminState } from 'src/app/admin/nav/store/admin.store';

const selectFeatureAdmin = createFeatureSelector<AdminState>('admin');

const selectHostelState = createSelector(selectFeatureAdmin, (state) => state.hostel);

export const selectHostelApiReponse = createSelector(selectHostelState, (state) => state.response);

export const selectHostelApiCreate = createSelector(selectHostelState, (state) => state.success == 'success');

export const selectFoodMenuList = createSelector(selectHostelState, (state) => state.foodMenuList);

export const foodMenuCreate = createSelector(selectHostelState, (state) => {
	return state.createdFood;
});

export const foodMenuUpdate = createSelector(selectHostelState, (state) => {
	return state.updateFood;
});

export const selectVisitorsList = createSelector(selectHostelState, (state) => state.visitors);

export const selectHostelsList = createSelector(selectHostelState, (state) => state.hostels);

export const selectHostelsBlocksList = createSelector(selectHostelState, (state) => state.hostelBlocks);

export const selectSelectedVisitor = createSelector(selectHostelState, (state) => state.selectedVisitor);

export const getRoomAttendance = createSelector(selectHostelState, (state) => state.room_attendance);

export const getRoomAttendanceSuccess = createSelector(selectHostelState, (state) => state.room_attendance_success);

export const getMealAttendanceSuccess = createSelector(selectHostelState, (state) => state.meal_attendance_success);

export const getMealAttendanceData = createSelector(selectHostelState, (state) => state.meal_attendance_data);

export const selectHostelStudentList = createSelector(selectHostelState, (state) => state.studentList);

export const selectHostelRoomsList = createSelector(selectHostelState, (state) => state.rooms);

export const selectHostelBedsList = createSelector(selectHostelState, (state) => state.hostelBeds);

export const selectAllocatedHostelBedsList = createSelector(selectHostelState, (state) => state.allocatedHostelBeds);

export const selectHostelSMSList = createSelector(selectHostelState, (state) => state.smsList);

export const selectSearchHostelStudentList = createSelector(
	selectHostelState,
	(state) => state.searchHostelStudentList
);
export const studentBedUpdateRes = createSelector(selectHostelState, (state) => state.studentBedUpdate);

export const selectStudentBedShiftedHistory = createSelector(selectHostelState, (state) => state.bedShiftedHistory);
export const getHostelDashboardDetails = createSelector(selectHostelState, (state) => state.hostelDashboard);
export const getStudentSupport = createSelector(selectHostelState, (state) => state.studentSupport);
export const getStudentSupportUpdate = createSelector(selectHostelState, (state) => state.studentSupportStatus);
export const getSelectedVisitorFilters = createSelector(selectHostelState, (state) => state.seletedVistorFilter);
export const getVisitorsListCountWise = createSelector(selectHostelState, (state) => state.getVisitorsCountWiseData);
export const openLatestCreatePass = createSelector(selectHostelState, (state) => state.	createPermissionPassRes);


export const getOneVisitorDetails = createSelector(selectHostelState, (state) => state.oneVisitorDetails);
export const getSelectedBlock = createSelector(selectHostelState, (state) => state.selectedBlockData);

export const getStudentHostelHolidayAtt = createSelector(selectHostelState, (state) => state.getHostelStudentHoliday);
export const getHostelHolidayStudents = createSelector(selectHostelState, (state) => state.getHostelHolidayStudents);
export const getClickHolidays = createSelector(selectHostelState, (state) => state.clickHolidayHostel);

// export const selectSearchStudentHostel = createSelector(selectHostelState, (state) => state.studentSearchResult);
