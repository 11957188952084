import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AlertService } from "src/app/shared/Services/alert.service";
import { AlertActions, ALERT_ERROR, ALERT_SUCCESS } from "./alert.actions";

@Injectable()
export class AlertEffects {
    constructor(
        private actions$: Actions<AlertActions>,
        private alertService: AlertService
    ) {}

    @Effect({dispatch: false})
    alertSuccess$ = this.actions$.pipe(
        ofType(ALERT_SUCCESS),
        map(action => action.message),
        switchMap((message) => {
            this.alertService.setAlert({message, type: 'success'});
            return of(EMPTY);
        })
    );

    @Effect({dispatch: false})
    alertError$ = this.actions$.pipe(
        ofType(ALERT_ERROR),
        map(action => action.message),
        switchMap((message) => {
            this.alertService.setAlert({message, type: 'danger'});
            return of(EMPTY);
        })
    );
}