import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { CommonActions } from './common/common.action';
import { commonReducer } from './common/common.reducer';
import { CommonState } from './common/common.types';
import { SideNavigationAction } from './side-navigation/side-navigation.actions';
import { sideNavigationReducer, SideNavigationState } from './side-navigation/side-navigation.reducer';

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('Reducers');

export interface AppState {
	sideNavigation: SideNavigationState;
	common: CommonState;
}

type All_ACTIONS = SideNavigationAction | CommonActions;

export function getReducers(): ActionReducerMap<AppState, All_ACTIONS> {
	return {
		sideNavigation: sideNavigationReducer,
		common: commonReducer,
	};
}

export const reducerProvider = [{ provide: reducerToken, useFactory: getReducers }];
