import { createAction, ExtractActionTypes } from '../store.types';
import {
	Class,
	Department,
	Division,
	Roles,
	RolesRequestParam,
	SMSTemplate,
	SmsTemplateType,
	Wings,
} from './common.types';

export const SET_SELECTED_ACADEMIC_YEAR = '[common] Selected Academic Year';
export const GET_WINGS_LIST = '[common] Get Wings List';
export const WINGS_LIST_SUCCESS = '[common] Wings List Success';
export const GET_DEPARTMENT_LIST = '[common] Get Department List';
export const DEPARTMENT_LIST_SUCCESS = '[common] Department List Success';
export const GET_ROLES_LIST = '[common] Get Roles List';
export const ROLES_LIST_SUCCESS = '[common] Roles List Success';
export const GET_DIVISION = '[common] Get Division List';
export const DIVISION_LIST_SUCCESS = '[common] Division List Success';
export const GET_CLASSES_AND_SECTIONS = '[common] Get Classes And Sections';
export const GET_CLASSES = '[common] Get Classes List';
export const CLASSES_AND_SECTIONS_LIST_SUCCESS = '[common] Classes And Sections List Success';
export const GET_STUDENT_BARRIER = '[common] Get Student Barrier';
export const STUDENT_BARRIER_SUCCESS = '[common] Student Brrier Success';
export const CLEAR_COMMON_STATE = '[common] Clear Common State';
export const GET_SMS_TEMPLATE = '[common] Get SMS Template';
export const SMS_TEMPLATE_SUCCESS = '[common] SMS Template Success';
export const GET_ACADEMIC_SESSIONS = '[common] Get Academic Sessions';
export const ACADEMIC_SESSIONS_SUCCESS = '[common] Academic Sessions Success';
export const GET_SUBJECTS = '[common] Get Subjects List';
export const SUBJECTS_LIST_SUCCESS = '[common] Subjects List Success';
export const UPDATE_API_STATUS = '[common] Update Api Status';
export const GET_BUS_ROUTE_LIST = '[common] Get Bus Route List';
export const BUS_ROUTE_LIST_SUCCESS = '[common] Bus Route List Success';
export const GET_BUS_ROUTE_STOPS_LIST = '[common] Get Bus Route Stops List';
export const BUS_ROUTE_STOPS_LIST_SUCCESS = '[common] Bus Route Stops List Success';
export const GET_TRANSPORT_VEHICLE_LIST = '[common] Get Transport Vehicle List';
export const TRANSPORT_VEHICLE_SUCCESS = '[common] Transport Vehicle List Success';

export const commonActions = {
	setSelectedAcademicYear: (year) => createAction(SET_SELECTED_ACADEMIC_YEAR, { year }),
	getWingsList: () => createAction(GET_WINGS_LIST),
	wingsListSuccess: (payload: Wings[]) => createAction(WINGS_LIST_SUCCESS, { payload }),
	getDepartmentList: (wing: string) => createAction(GET_DEPARTMENT_LIST, { wing }),
	departmentListSuccess: (payload: Department[]) => createAction(DEPARTMENT_LIST_SUCCESS, { payload }),
	getRolesList: (param: RolesRequestParam) => createAction(GET_ROLES_LIST, { ...param }),
	rolesListSuccess: (payload: Roles[]) => createAction(ROLES_LIST_SUCCESS, { payload }),
	getDivision: () => createAction(GET_DIVISION),
	divisionListSuccess: (payload: Division[]) => createAction(DIVISION_LIST_SUCCESS, { payload }),
	getClassesAndSection: (division: string) => createAction(GET_CLASSES_AND_SECTIONS, { division }),
	getClasses: () => createAction(GET_CLASSES),
	classesAndSectionsListSuccess: (payload: Class[]) => createAction(CLASSES_AND_SECTIONS_LIST_SUCCESS, { payload }),
	getStudentBarrier: () => createAction(GET_STUDENT_BARRIER),
	studentBarrierSuccess: (payload) => createAction(STUDENT_BARRIER_SUCCESS, { payload }),
	clearState: (stateToClear: 'department' | 'roles' | 'divisions' | 'classes') =>
		createAction(CLEAR_COMMON_STATE, { stateToClear }),
	getSMSTemplate: (smsType: SmsTemplateType) => createAction(GET_SMS_TEMPLATE, { smsType }),
	smsTemplateSuccess: (payload: SMSTemplate[]) => createAction(SMS_TEMPLATE_SUCCESS, { payload }),
	getAcademicSessions: (session) => createAction(GET_ACADEMIC_SESSIONS, { session }),
	academicSessionSuccess: (payload) => createAction(ACADEMIC_SESSIONS_SUCCESS, { payload }),
	getSubjects: (requestData) => createAction(GET_SUBJECTS, { requestData }),
	subjectsListSuccess: (payload) => createAction(SUBJECTS_LIST_SUCCESS, { payload }),
	updateApiStatus: (payload) => createAction(UPDATE_API_STATUS, { payload }),
	getBusRouteList: () => createAction(GET_BUS_ROUTE_LIST),
	busRouteListSuccess: (payload) => createAction(BUS_ROUTE_LIST_SUCCESS, { payload }),
	getBusRouteStopsList: (routeId) => createAction(GET_BUS_ROUTE_STOPS_LIST, { routeId }),
	busRouteStopsListSuccess: (payload) => createAction(BUS_ROUTE_STOPS_LIST_SUCCESS, { payload }),
	getTransportVehicleList: () => createAction(GET_TRANSPORT_VEHICLE_LIST),
	transportVehicleListSuccess: (payload) => createAction(TRANSPORT_VEHICLE_SUCCESS, { payload }),
};

export type CommonActions = ExtractActionTypes<typeof commonActions>;
