import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[student-default-image]',
})

export class StudentDefaultImage implements OnInit {
    @Input() image: string;
    @Input() path: string;
    constructor(private elementRef: ElementRef) {
    }
    ngOnInit() {
        if(!this.image){
            const element: HTMLImageElement = this.elementRef.nativeElement;
            element.setAttribute('src', this.path);
        }
    }

}
