import { BaseResponse } from '../store.types';

export interface HostelState {
	apiStatus: any;
	response: any;
	success: any;
	foodMenuList: any;
	createdFood: any;
	updateFood: any;
	visitors: VisitorsListPayload;
	searchHostelStudentList: any[];
	studentSearchResult: any[];
	hostels: [];
	hostelBlocks: [];
	hostelBeds: VisitorsListPayload;
	bedShiftedHistory: VisitorsListPayload;
	allocatedHostelBeds: VisitorsListPayload;
	selectedVisitor: Record<string, any>;
	smsList: VisitorsListPayload;
	studentList: VisitorsListPayload;
	rooms: VisitorsListPayload;
	room_attendance: null;
	room_attendance_success: null;
	meal_attendance_success: null;
	meal_attendance_data: null;
	studentBedUpdate: null;
	hostelDashboard: {};
	studentSupport: {};
	studentSupportStatus: {};
	seletedVistorFilter: {};
	 getVisitorsCountWiseData: {
		status: string,
		'status code': number,
		message: string,
		data: [],
		current_page_number: number,
		total_pages: number,
	};
	createPermissionPassRes:null,
	oneVisitorDetails:null,
	selectedBlockData:{},
	getHostelStudentHoliday:{},
	getHostelHolidayStudents:{},
	clickHolidayHostel:{},
}

export type VisitorsListPayload = Pick<VisitorsListSuccessResponse, 'current_page_number' | 'data' | 'total_pages' | 'permissions_overview'>;

export interface VisitorsListSuccessResponse extends BaseResponse {
	data: [];
	current_page_number: number;
	total_pages: number;
	permissions_overview?: any;
}
export interface VisitorsListSuccessResCount extends BaseResponse {
	"status": "success",
	"status code": 200,
	"message": "Retrieved successfully.",
	"current_page_number": 1,
	"total_pages": 2
  }
export interface foodPayload {
	data: any[];
}

export interface IBedAllocate {
	data: {
		status: string;
		'status code': number;
		message: string;
		data: {
			primary_key: string;
			created_at: Date;
			updated_at: null;
			from_date: string;
			to_date: string;
			status: string;
			comment: null;
			school: string;
			hostel: string;
			added_by: string;
			academic_year: string;
			hostel_room: string;
			bed_number: string;
			student: string;
		};
	};
	success: string;
}

export interface HostelStudentRequest {
	hostel: string; // stringify it string[]
	block?: string;
	room?: string;
	classes?: string;
	division?: string;
	student?: [];
	page?: number;
}

export interface HostelRoomsRequest {
	hostel: string;
	hostel_block?: string;
	status?: string;
	page?: number;
}

export interface CreateHostelSMSRequest {
	student?: string[];
	date: string;
	block?: string[];
	room?: string[];
	division?: string[];
	classes?: string[];
	sms: string;
	send_to: string;
	send_time: string;
	sms_send_type: string;
	sms_template_id: string;
}

export const  issue_status : string[] = ['Pending','Resolved'];

export interface IGetStudentSupport{
    "status": string,
    "status code": number,
    "message": string,
    "data": [
        {
            "created_at": Date,
            "updated_at": Date,
            "primary_key": string,
            "school_id":string,
            "academic_year_id": string,
            "student_id": string,
            "replied_by_id": string,
            "hostel_id": string,
            "issue": string,
            "solution": string,
            "issue_status": string,
        }
    ]
}
export interface IGetStudentSupportArray{
 	[index: number]:[
        {
            "created_at": Date,
            "updated_at": Date,
            "primary_key": string,
            "school_id":string,
            "academic_year_id": string,
            "student_id": string,
            "replied_by_id": string,
            "hostel_id": string,
            "issue": string,
            "solution": string,
            "issue_status": string,
        }
    ]

}

export const permissionPurposeTypes:string[] = ['Marriage Occasion','Birthday Celebration','Sick','Death','Outing','Other'];
export const defaultPermissionCount = {
	total_yearly_passes: 0,
	total_day_passes: 0,
	total_students_to_return: 0,
	total_students_returned: 0,
	not_returned_students: 0,
	total_delayed_students: 0,
};

export interface IGetHostelTimings {
	status: string;
	'status code': number;
	message: string;
	data: [
		{
			created_at: Date;
			updated_at: Date;
			primary_key: string;
			school_id: string;
			added_by_id: string;
			hostel_id: string;
			time: string;
			active_mode: boolean;
		}
	];


}
export interface IGetHostelTimingsRes {
	data:[
		{
			created_at: Date;
			updated_at: Date;
			primary_key: string;
			school_id: string;
			added_by_id: string;
			hostel_id: string;
			time: string;
			active_mode: boolean;
		}
	]
}

export const defaultEmployeesVisitor = [
	{
		primary_key:'04075a87-de8f-43f6-b009-8f63f6cefdfd',
		first_name:"K Ashok Reddy"
	},
	{
		primary_key:'44b1ee49-0245-4555-b48d-48587e5b9817',
		first_name:"B. Shyam Sunder Reddy"
	}
]

export interface IGetHosteStudentCount {
	students_count_overview: {
		total_students: number;
		total_left_students: number;
		total_active_students: number;
		total_inactive_students: number;
		total_terminated_students: number;
		total_shifted_students: number;
	};
	total_records:number
}

export const defaultGetHostelStudents={
	students_count_overview: {
		total_students: 0,
		total_left_students: 0,
		total_active_students: 0,
		total_inactive_students: 0,
		total_terminated_students: 0,
		total_shifted_students: 0,
	},
	total_records:0
}
