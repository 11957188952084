import { createAction, ExtractActionTypes } from 'src/app/store/store.types';

export const CREATE_VEHICLE_LISTING = '[transportaion] create';
export const CREATE_VEHICLE_SUCCESS = '[transportaion] create success';
export const UPDATE_VEHICLE_LISTING = '[transportaion] update';
export const UPDATE_VEHICLE_SUCCESS = '[transportaion] update success';
export const GET_VEHICLE_LISTING = '[transportaion] get call';
export const GET_VEHICLE_LISTING_DATA = '[transportaion] get vehcile list';
export const GET_VEHICLE_LISTING_DATA_ONE = '[transportaion] get vehcile paricular';
export const GET_VEHICLE_LISTING_ONE = '[transportaion] get vehcile paricular data';
export const GET_ROUTE_LISTS = '[admin transportaion] get routes';
export const GET_ROUTE_DATA_LIST = '[admin transportaion] get routes lis data';
export const GET_ROUTE_STOPS = '[admin transportaion] get routes stops';
export const GET_ROUTE_STOPS_DATA = '[admin transportaion] get routes stops data';
export const CREATE_ROUTE_STOPS = '[admin transportaion] create routes stops';
export const UPDATE_ROUTE_STOPS = '[admin transportaion] update routes stops';
export const CREATE_ROUTE_STOPS_RESPONSE = '[admin transportaion] create routes stops response';

export const CREATE_ROUTE = '[admin transportaion] create routes api';
export const UPDATE_ROUTE_DATA = '[admin transportaion] update routes api';
export const CREATE_ROUTE_RESPONSE = '[admin transportaion] create routes response';
export const GET_TRANSPORT_ASSIGNED_STUDENT = '[transportation] Get Transport Assigned Student';
export const TRANSPORT_ASSIGNED_STUDENT_SUCCESS = '[transportation] Transport Assigned Student Success';
export const ASSIGN_STUDENT_TO_TRANSPORT = '[Transport] Assign Student to transport';


export const CREATE_ROUTE_BUS_STUDENT = '[transportaion bus and student] create bus and student';
export const CREATE_ROUTE_BUS_ATTENDANCE = '[transportaion bus and attendance] bus attendace';
export const GET_ROUTE_BUS_STUDENT = '[transportaion bus and student] get bus and student';
export const GET_ROUTE_BUS_STUDENT_DATA = '[transportaion bus and student data] get bus and student';
export const ADD_TRANSPORT_STUDENT_ATTE = '[transportaion student attendance] get student attendance';
export const SEARCH_VEHICLE_LIST = '[staff] Search Vehicle';
export const SEARCH_VEHICLE_SUCCESS = '[staff] Search Vehicle Success';



export const transactionAction = {
	create_vehicle_listing: (payload) => createAction(CREATE_VEHICLE_LISTING, { payload }),
	create_vehicle_success: (payload) => createAction(CREATE_VEHICLE_SUCCESS, { payload }),
	update_vehicle_listing: (payload) => createAction(UPDATE_VEHICLE_LISTING, { payload }),
	update_vehicle_success: (payload) => createAction(UPDATE_VEHICLE_SUCCESS, { payload }),
	get_vehicle_listing: (payload) => createAction(GET_VEHICLE_LISTING, { payload }),
	get_vehicle_listing_data: (payload) => createAction(GET_VEHICLE_LISTING_DATA, { payload }),
	get_vehicle_listing_one: (payload) => createAction(GET_VEHICLE_LISTING_DATA_ONE, { payload }),
	get_vehicle_listing_data_one: (payload) => createAction(GET_VEHICLE_LISTING_ONE, { payload }),
	get_routes_list: (payload) => createAction(GET_ROUTE_LISTS,{payload}),
	get_routes_data: (payload) => createAction(GET_ROUTE_DATA_LIST, { payload }),
	get_routes_stops: (payload) => createAction(GET_ROUTE_STOPS, { payload }),
	get_routes_stops_data: (payload) => createAction(GET_ROUTE_STOPS_DATA, { payload }),
	create_routes_stops: (payload) => createAction(CREATE_ROUTE_STOPS, { payload }),
	edit_routes_stops: (payload) => createAction(UPDATE_ROUTE_STOPS, { payload }),
	routes_stops_response: (payload) => createAction(CREATE_ROUTE_STOPS_RESPONSE, { payload }),

	//create routes
	create_routes: (payload) => createAction(CREATE_ROUTE, { payload }),
	edit_routes: (payload) => createAction(UPDATE_ROUTE_DATA, { payload }),
	routes_response_api: (payload) => createAction(CREATE_ROUTE_RESPONSE, { payload }),
	getTransportAssignedStudent: (payload) => createAction(GET_TRANSPORT_ASSIGNED_STUDENT, { payload }),
	transportAssignedStudentSuccess: (payload) => createAction(TRANSPORT_ASSIGNED_STUDENT_SUCCESS, { payload }),
	assignStudentToTransport: (payload) => createAction(ASSIGN_STUDENT_TO_TRANSPORT, { payload }),


	createBusStudentTransport: (payload) => createAction(CREATE_ROUTE_BUS_STUDENT, { payload }),
	createBusAttendanceRes: (payload) => createAction(CREATE_ROUTE_BUS_ATTENDANCE, { payload }),
	getBusStudentTransport: (payload) => createAction(GET_ROUTE_BUS_STUDENT, { payload }),
	getBusStudentTransportData: (payload) => createAction(GET_ROUTE_BUS_STUDENT_DATA, { payload }),
	addStudentTransportAttendance: (payload) => createAction(ADD_TRANSPORT_STUDENT_ATTE, { payload }),

	searchEmployeeList: (payload: any) => createAction(SEARCH_VEHICLE_LIST, { payload }),
	searchEmployeeSuccess: (payload: []) => createAction(SEARCH_VEHICLE_SUCCESS, { payload }),
	
};
export type transactionActions = ExtractActionTypes<typeof transactionAction>;
