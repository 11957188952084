import { DOCUMENT } from '@angular/common'
import { Directive, ElementRef, HostBinding, HostListener, Inject } from '@angular/core'
@Directive({
  selector: '[onlyAlphabets]',
})
export class OnlyAlphabetsDirective {
  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event:any) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/^[^a-zA-Z]+$/g, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}