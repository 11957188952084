<div *ngIf="onlineStatus === 'online'" class="online">
    <span>{{onlineStatusMessage}}</span>
</div>
<div *ngIf="onlineStatus === 'offline'" class="offline">
    <span>{{onlineStatusMessage}}</span>  

    <section>
        <!-- <div class="example-label">Internet connetion is lost</div> -->
        <div class="example-button-row">
          <button mat-raised-button (click)="reload()">Try Again</button>
        </div>
    </section>
</div>