import {
	ACADEMIC_SESSIONS_SUCCESS,
	BUS_ROUTE_LIST_SUCCESS,
	BUS_ROUTE_STOPS_LIST_SUCCESS,
	CLASSES_AND_SECTIONS_LIST_SUCCESS,
	CLEAR_COMMON_STATE,
	CommonActions,
	DEPARTMENT_LIST_SUCCESS,
	DIVISION_LIST_SUCCESS,
	ROLES_LIST_SUCCESS,
	SET_SELECTED_ACADEMIC_YEAR,
	SMS_TEMPLATE_SUCCESS,
	STUDENT_BARRIER_SUCCESS,
	SUBJECTS_LIST_SUCCESS,
	TRANSPORT_VEHICLE_SUCCESS,
	UPDATE_API_STATUS,
	WINGS_LIST_SUCCESS,
} from './common.action';
import { CommonState } from './common.types';

const initialCommonState: CommonState = {
	selectedAcademicYear: null,
	wings: [],
	department: [],
	roles: [],
	division: [],
	classesAndSections: null,
	subjects: null,
	studentBarrier: null,
	smsTemplate: [],
	academicSession: null,
	apiStatus: null,
	busRoutes: [],
	busRouteStops: [],
	vehicles: [],
};

export const commonReducer = (state: CommonState = initialCommonState, action: CommonActions): CommonState => {
	switch (action.type) {
		case SET_SELECTED_ACADEMIC_YEAR:
			return { ...state, selectedAcademicYear: action.year };
		case WINGS_LIST_SUCCESS:
			return { ...state, wings: action.payload };
		case DEPARTMENT_LIST_SUCCESS:
			return { ...state, department: action.payload };
		case ROLES_LIST_SUCCESS:
			return { ...state, roles: action.payload };
		case DIVISION_LIST_SUCCESS:
			return { ...state, division: action.payload };
		case CLASSES_AND_SECTIONS_LIST_SUCCESS:
			return { ...state, classesAndSections: action.payload };
		case STUDENT_BARRIER_SUCCESS:
			return { ...state, studentBarrier: action.payload };
		case CLEAR_COMMON_STATE:
			return clearCommonState(state, action.stateToClear);
		case SMS_TEMPLATE_SUCCESS:
			return { ...state, smsTemplate: action.payload };
		case ACADEMIC_SESSIONS_SUCCESS:
			return { ...state, academicSession: action.payload };
		case SUBJECTS_LIST_SUCCESS:
			return { ...state, subjects: action.payload };
		case UPDATE_API_STATUS:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					[action.payload.type]: action.payload,
				},
			};
		case BUS_ROUTE_LIST_SUCCESS:
			return { ...state, busRoutes: action.payload };
		case BUS_ROUTE_STOPS_LIST_SUCCESS:
			return { ...state, busRouteStops: action.payload };
		case TRANSPORT_VEHICLE_SUCCESS:
			return { ...state, vehicles: action.payload };
		default:
			return state;
	}
};

const clearCommonState = (state, stateToClear) => {
	if (stateToClear == 'department') return { ...state, department: [] };
	if (stateToClear == 'roles') return { ...state, roles: [] };
	if (stateToClear == 'divisions') return { ...state, division: [] };
	if (stateToClear == 'classes') return { ...state, classesAndSections: [] };
	return state;
};
