<section *ngIf="{
  selectedVisitor: selectedVisitor$ | async
} as observable">
    <div class="row m-0">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 bg-white">
        <div class="notfiHead rounded-top">
          <h3 class="m-0">Update Permission Pass</h3>
          <div>
            <mat-icon class="ml-2" style="cursor:pointer;" (click)="onClose()">close</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="totalCard scrollAdmin p-3" #scroll>
      <div class="d-flex justify-content-end mb-3">
        <button title="Update Visitor" *ngIf="isDeleteAllowed(observable.selectedVisitor)" (click)="deleteVisitor()" class="rounded-pill text-danger mr-2" mat-stroked-button>
          Delete
        </button>
        <button title="Update Visitor" type="button" form="ngForm" *ngIf="
        observable.selectedVisitor?.student_return_date_time === null"
         (click)="updateVisitor()" class="rounded-pill color-primary" mat-stroked-button>
          Update
        </button>
      </div>

      <div class="border mt-2" *ngIf="
      observable.selectedVisitor?.student_return_date_time !== null
      ">
        <h1 class="border-bottom pl-2 pt-1 pb-1 m-0 group-title">Return Status</h1>
        <div class="d-flex p-2">
          <div class="row w-100">
            <div class="col-12 d-flex">
                <label class="label-text w-50">Status:</label>
                <label class="label-value flex-fill">{{observable.selectedVisitor?.student_status}}</label>
            </div>
            <div class="col-12 d-flex">
              <label class="label-text w-50">Dropped Relative Name:</label>
              <label class="label-value flex-fill">{{observable.selectedVisitor?.dropped_visitor_name}}</label>
            </div>
            <div class="col-12 d-flex">
              <label class="label-text w-50">Dropped Relative Mobile:</label>
              <label class="label-value flex-fill">{{observable.selectedVisitor?.dropped_visitor_mobile_number}}</label>
            </div>
            <div class="col-12 d-flex">
              <label class="label-text w-50">Relation with student:</label>
              <label class="label-value flex-fill">{{observable.selectedVisitor?.dropped_visitor_relation_with_student }}</label>
            </div>
            <div class="col-12 d-flex">
              <label class="label-text w-50">Return Date & Time:</label>
              <label class="label-value flex-fill">{{observable.selectedVisitor?.student_return_date_time | date: 'short' }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="border mt-2" *ngIf="
      observable.selectedVisitor?.student_return_date_time === null
      ">
        <h1 class="border-bottom pl-2 pt-1 pb-1 m-0 group-title">Update Status</h1>
        <div class="d-flex flex-row  flex-wrap p-2">
          <form [formGroup]="editPermissionFormGroup" #editPermissionForm="ngForm" class="d-block w-100">
          <div class="d-flex flex-fill">
            <mat-form-field  appearance="outline" class="w-100 materialInputHeight">
              <mat-label>Status</mat-label>
              <mat-select formControlName="student_status">
                  <mat-option value="Returned with delay">Returned with delay</mat-option>
                  <mat-option value="Returned">Returned</mat-option>
                  <mat-option value="Not Returned">Not Returned</mat-option>
                  <mat-option value="Cancelled">Cancelled</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="d-flex flex-fill">
            <mat-form-field  appearance="outline" class="w-100 mr-2 materialInputHeight">
              <mat-label>Relation with Student</mat-label>
              <mat-select formControlName="dropped_visitor_relation_with_student">
                  <mat-option value="Self">Self</mat-option>
                  <mat-option value="Father">Father</mat-option>
                  <mat-option value="Mother">Mother</mat-option>
                  <mat-option value="Guardian">Guardian</mat-option>
                  <mat-option [value]="'Brother'">Brother</mat-option>
                  <mat-option [value]="'Sister'">Sister</mat-option>
                  <mat-option [value]="'Uncle'">Uncle</mat-option>
                  <mat-option [value]="'Aunty'">Aunty</mat-option>
                  <mat-option [value]="'Brother in Law'">Brother in Law</mat-option>
                  <mat-option [value]="'Sister in Law'">Sister in Law</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="materialInputHeight"  appearance="outline">
              <mat-label>Dropped Visitor Name</mat-label>
              <input matInput formControlName="dropped_visitor_name" maxlength="32" name="visitor name"/>
            </mat-form-field>

          </div>
          <div class="d-flex flex-fill">
            <mat-form-field  appearance="outline" class="w-100 mr-2 materialInputHeight">
              <mat-label>Gender</mat-label>
              <mat-select formControlName="dropped_visitor_gender">
                  <mat-option value="Male">Male</mat-option>
                  <mat-option value="Female">Female</mat-option>
                  <mat-option value="Other">Other</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="materialInputHeight"  appearance="outline">
              <mat-label>Dropped Visitor Mobile</mat-label>
              <input matInput (keypress)='numberOnly($event)' maxlength="10" formControlName="dropped_visitor_mobile_number" name="visitor mobile"/>
            </mat-form-field>
          </div>
          <div class="d-flex flex-fill">
            <mat-form-field  appearance="outline" class="flex-fill materialInputHeight">
                <mat-label>Return Date</mat-label>
                <input matInput [matDatepicker]="returnDate"  [max]="maxDate" [min]="minDate"
                (click)='returnDate.open()'   (focus)='returnDate.open()' formControlName="returnDate"
                name="returnDate" readonly/>
                <mat-datepicker-toggle matSuffix [for]="returnDate" style="color: #f79d04;"></mat-datepicker-toggle>
                <mat-datepicker #returnDate></mat-datepicker>
            </mat-form-field>
            <mat-form-field  appearance="outline" class="flex-fill ml-2 materialInputHeight">
              <mat-label>Return Time</mat-label>
              <input  formControlName="returnTime" matInput [ngxTimepicker]="returnTime" readonly>
              <mat-datepicker-toggle matSuffix [for]="returnTime">
                  <mat-icon matDatepickerToggleIcon style="color: #f79d04;">
                      schedule</mat-icon>
              </mat-datepicker-toggle>
              <ngx-material-timepicker [min]="minTime" #returnTime></ngx-material-timepicker>
            </mat-form-field>
          </div>
        </form>
        </div>
      </div>

      <div class="border mt-2">
        <h1 class="border-bottom pl-2 pt-1 pb-1 m-0 group-title">Permission Pass ID</h1>
        <div class="d-flex w-100 flex-column p-2" id="visitorcard">
          <div class="id-card">
              <div class="card-inner">
                  <div class="profile pt-3">
                    <div class="row m-0">
                      <div class="col-3">
                        <div class="circular mb-2">
                          <img [defaultImageSet]="school_data?.school_logo"
                          [src]="school_data?.school_logo" alt="image" />
                       </div>
                      </div>
                      <div class="col-9">
                        <h3 class="m-0 font-weight-bold">{{school_data?.institution_name}}</h3>
                        <address>
                          {{school_data?.city}}, {{school_data?.state}}
                        </address>
                        <div class="passNumber">
                            <label>Pass Number</label> :
                            <span class="flex-fill"> {{observable.selectedVisitor?.visitor_number}}</span>
                        </div>
                      </div>

                    </div>
                    <!-- <div class="logo">


                    </div> -->
                    <!-- <div class="profile-photo">
                      <img [defaultImageSet]="observable.selectedVisitor?.visitor_id_card_photo"
                       [src]="observable.selectedVisitor?.visitor_id_card_photo"
                      class="userImg" alt="image">
                    </div> -->
                    <div class="base-line">
                      <h3 class="m-0">Student Out Pass</h3>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col-9">
                      <div class="d-flex">
                        <label class="label-text">Student Name :</label>
                        <span class="flex-fill label-value"> &nbsp; {{observable.selectedVisitor?.student?.first_name}}
                          {{observable.selectedVisitor?.student?.last_name}}
                        </span>
                      </div>
                      <div class="d-flex">
                        <label class="label-text">Student ID :</label>
                        <span class="flex-fill label-value"> &nbsp;
                          {{observable.selectedVisitor?.student?.custom_id ? observable.selectedVisitor?.student?.custom_id:"-"}}
                        </span>
                      </div>
                      <div class="d-flex content-text">
                        <label>Class - Section </label>
                        <span class="flex-fill label-value">:
                          {{observable.selectedVisitor?.student?.class_name}} - {{observable.selectedVisitor?.student?.section_name}}
                        </span>
                      </div>
                      <div class="d-flex flex-column">
                        <div class="d-flex content-text justify-content-between">
                          <div class="d-flex">
                            <label>Block </label>
                            <span class="flex-fill label-value">:
                              {{observable.selectedVisitor?.hostel_block?.block_name}}
                            </span>
                          </div>
                          <div class="d-flex">
                            <label>Room </label>
                            <span class="flex-fill label-value">:
                              {{observable.selectedVisitor?.student_room_number}}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex content-text">
                        <label>Relation Name  </label>
                        <span class="flex-fill label-value">: {{observable.selectedVisitor?.visitor_name}}</span>
                      </div>
                      <div class="d-flex content-text">
                        <label>Out With </label>
                        <span class="flex-fill label-value">: {{observable.selectedVisitor?.relation_to_student}}</span>
                      </div>
                    </div>
                    <div class="col-3 p-0">
                      <div class="d-flex justify-content-end mr-2">
                        <div class="profile-photo">
                          <img   [src]="visitorData?.student.photo"  class="userImg" alt="image"
                          student-default-image    [image]="visitorData.student.photo"
                          [path]="observable.selectedVisitor.student.gender === 'Male' ? 'assets/images/Boy.png':
                          observable.selectedVisitor.student.gender === 'Female' ? 'assets/images/Girl.png':
                           'assets/images/Boy.png'"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="content-text">
                        <u> Permission date time </u> :<br>
                        <span class="flex-fill content-text-value">
                          {{observable.selectedVisitor?.from_datetime | date:'mediumDate'}}
                          {{observable.selectedVisitor?.from_datetime | date:'shortTime'}}
                        </span>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="content-text text-right">
                        <u> Stipulated Days/Time </u>:<br>
                        <span class="flex-fill content-text-value">
                          {{observable.selectedVisitor?.permission_time}}
                        </span>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="d-flex content-text-value">
                        <label>Purpose </label>
                        <span class="flex-fill">: {{observable.selectedVisitor?.purpose}}</span>
                      </div>
                    </div>
                  </div>

                  <mat-divider class="my-1"></mat-divider>
                  <div class="row m-0">
                    <div class="col-4 d-flex align-items-center justify-content-center">
                      <div class="my-2">
                        <img    [src]="visitorData?.visitor_qr_code"
                         [defaultImageSet]="visitorData.visitor_qr_code"
                         width="100" height="100"
                        title="QR code" alt="Image"/>
                     </div>
                    </div>
                    <div class="col-8">
                      <div class="content-text mt-2">
                        <u> Permitted  by </u>
                      </div>
                      <div class="d-flex content-text-sign">
                        <label> Name </label>
                        <span class="flex-fill content-text-value">:
                          {{observable.selectedVisitor?.added_by?.first_name}}  {{observable.selectedVisitor?.added_by?.last_name}}
                        </span>
                      </div>
                      <div class="d-flex content-text-sign">
                        <label>  ID  </label>
                        <span class="flex-fill content-text-value">:
                          {{observable.selectedVisitor?.added_by?.custom_employee_id ? observable.selectedVisitor?.added_by?.custom_employee_id:"-"}}
                        </span>
                      </div>
                      <div class="d-flex content-text-sign">
                        <label>Designation</label>
                        <span class="flex-fill content-text-value">:
                          {{observable.selectedVisitor?.added_by?.role_name ? observable.selectedVisitor?.added_by?.role_name:"-"}}
                        </span>
                      </div>
                      <div class="mb-2">
                        <div class="flex-container content-text-sign">
                          <label style="flex-grow: 1">Sign :</label>
                          <label style="flex-grow: 9"
                            class="studentData borderData">  </label>
                        </div>
                      </div>
                    </div>
                  </div>

              </div>
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center m-3">
          <!-- <a href="javascript:void(0)" class="mt-3 mb-3" (click)="downloadVisitorCard()">Click here to download</a> -->
          <!-- <span class="ml-3 mr-3">Or</span> -->
          <!-- <ngx-print-element #element="element">
            <span class="printBtn" mat-raised-button
            (click)="element.print('visitorcard', {printMode: 'template', pageTitle: 'Permission Pass'})">
                Print
            </span>
          </ngx-print-element> -->
          <ngx-print-element #element="element" tabIndex="1">
            <button class="printBtn" mat-raised-button
                  (click)="element.print('visitorcard', {printMode: 'template', pageTitle: 'Permission Pass'})">
                  Print
          </button>
          </ngx-print-element>

          <!-- <a href="javascript:void(0)" class="mt-3 mb-3" (click)="print.print('visitorcard')">Print</a> -->
        </div>
      </div>

      <div class="border mt-2">
        <h1 class="border-bottom pl-2 pt-1 pb-1 m-0 group-title">Basic Details</h1>
        <div class="d-flex p-2">
          <div class="flex-fill d-flex flex-column">
            <div class="circular mx-auto">
              <img     [src]="observable.selectedVisitor?.visitor_photo" student-default-image
              [image]="observable.selectedVisitor?.visitor_photo"
              [path]="observable.selectedVisitor?.student?.gender === 'Male' ? 'assets/images/Boy.png':
              observable.selectedVisitor?.student?.gender === 'Female' ? 'assets/images/Girl.png':
                                                   'assets/images/Boy.png'"
              (click)="visitor_photo.click()" title="Choose Image" alt="Image"/>
                <input type="file"  accept=".jpg,.png,.jpeg"
                    (change)="onFileInput($event)"
                    style="display:none;"
                    #visitor_photo />
              </div>
              <div class="flex-fill d-flex flex-column pt-2">
                <div class="row">
                  <div class="col-6 d-flex">
                    <label class="label-text">Name:</label>
                    <label class="label-value flex-fill">{{observable.selectedVisitor?.visitor_name}}</label>
                  </div>
                  <div class="col-6 d-flex">
                    <label class="label-text">Gender:</label>
                    <label class="label-value flex-fill">{{observable.selectedVisitor?.gender}}</label>
                  </div>
                  <div class="col-6 d-flex">
                    <label class="label-text">Mobile:</label>
                    <label class="label-value flex-fill">{{observable.selectedVisitor?.mobile_number}}</label>
                  </div>
                  <div class="col-6 d-flex">
                    <label class="label-text">Relation:</label>
                    <label class="label-value flex-fill">{{observable.selectedVisitor?.relation_to_student}}</label>
                  </div>
                  <div class="col-6 d-flex">
                    <label class="label-text">Purpose:</label>
                    <label class="label-value flex-fill">{{observable.selectedVisitor?.purpose}}</label>
                  </div>
                </div>
              </div>
          </div>
      </div>
      </div>

      <div class="border mt-2">
        <h1 class="border-bottom pl-2 pt-1 pb-1 m-0 group-title">To Meet</h1>
        <div class="d-flex p-2">
          <div class="row w-100">
            <div class="col-6 d-flex">
                <label class="label-text">Hostel: </label>
                <label class="label-value flex-fill">{{observable.selectedVisitor?.address}}</label>
            </div>
            <div class="col-6 d-flex">
              <label class="label-text">Block: </label>
              <label class="label-value flex-fill">{{observable.selectedVisitor?.hostel_block?.block_name}}</label>
            </div>
            <div class="col-6 d-flex">
              <label class="label-text">To Meet: </label>
              <label class="label-value flex-fill">{{observable.selectedVisitor?.meet_choices}}</label>
            </div>
            <div class="col-6 d-flex">
              <label class="label-text">Student Name: </label>
              <label class="label-value flex-fill">{{observable.selectedVisitor?.student?.first_name}} {{observable.selectedVisitor?.student?.last_name}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="border mt-2">
        <h1 class="border-bottom pl-2 pt-1 pb-1 m-0 group-title">Attachment</h1>
        <div class="d-flex p-2 justify-content-center">
          <div class="attachment d-flex flex-column align-items-center">
            <label>ID CARD</label>
            <img  student-default-image  [image]="observable.selectedVisitor?.visitor_id_card_photo"
            [path]="'assets/images/attachementproof.png'"
            [src]="observable.selectedVisitor?.visitor_id_card_photo" class="rounded"
            title="Choose Image" alt="Image"/>
        </div>
        <div class="attachment ml-2 d-flex flex-column align-items-center">
            <label>SIGNATURE</label>
            <img [image]="observable.selectedVisitor?.signature" student-default-image
            [path]="'assets/images/attachementproof.png'"
            [src]="observable.selectedVisitor?.signature" class="rounded"
            title="Choose Image" alt="Image"/>
        </div>
        </div>
      </div>

    </div>
</section>
