import { HttpClient } from '@angular/common/http';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';
import { Injectable } from '@angular/core';
import { jsonToFormData } from 'src/app/helpers/utils';
import { environment } from 'src/environments/environment';

interface RequestData {
	headers?: { [key: string]: string };
	params?: { [key: string]: string };
	body?: any;
}
interface RequestOptions extends RequestData {
	method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
	endpoint: string;
}
@Injectable()
export class ApiService {
	constructor(private httpClient: HttpClient) {}

	private generateRequestOptions(options: RequestOptions) {
		const { headers, body, params } = options;
		return {
			headers: this.getHeaders(headers),
			body,
			params,
		};
	}

	private getHeaders(headers?: { [key: string]: any }) {
		if (headers)
			return {
				...{ 'Content-Type': 'application/json' },
				...headers,
			};
		else return {};
	}

	doRequest<T>(options: RequestOptions) {
		const url = `${environment.base_url}${options.endpoint}`;
		const requestOptions = this.generateRequestOptions(options);
		return this.httpClient.request<T>(options.method, url, requestOptions);
	}

	doGet = <T>(endpoint: string, params?: { [key: string]: any }) =>
		this.doRequest<T>({
			method: 'GET',
			endpoint,
			params,
		});

	doPost = <T>(endpoint: string, params?: { [key: string]: any }) =>
		this.httpClient.post<T>(`${environment.base_url}${endpoint}`, jsonToFormData(params));

	doPatch = <T>(endpoint: string, params?: { [key: string]: any }) =>
		this.httpClient.patch<T>(`${environment.base_url}${endpoint}`, jsonToFormData(params));

	doDelete = <T>(endpoint: string, params?: any) =>
		this.httpClient.delete<T>(`${environment.base_url}${endpoint}`, { body: jsonToFormData(params) } as any);
}
