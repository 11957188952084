import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ApiStatus } from '../store.types';
import { CommonState } from './common.types';

const selectFeatureCommon = createFeatureSelector<CommonState>('common');

export const selectSelectedAcademicYear = createSelector(selectFeatureCommon, (state) => state.selectedAcademicYear);

export const selectWingsList = createSelector(selectFeatureCommon, (state) => state.wings);

export const selectDepartmentList = createSelector(selectFeatureCommon, (state) => state.department);

export const selectRolesList = createSelector(selectFeatureCommon, (state) => state.roles);

export const selectDivisionList = createSelector(selectFeatureCommon, (state) => state.division);

export const selectSubjectsList = createSelector(selectFeatureCommon, (state) => state.subjects);

export const selectClassesList = createSelector(selectFeatureCommon, (state) => state.classesAndSections);

export const selectSmsTemplateList = createSelector(selectFeatureCommon, (state) => state.smsTemplate);

export const selectBusRoutesList = createSelector(selectFeatureCommon, (state) => state.busRoutes);

// export const selectStatusList = createSelector(selectFeatureCommon, (state) => state.apiStatus);

export const selectBusRouteStopsList = createSelector(selectFeatureCommon, (state) => state.busRouteStops);

export const selectTransportVehicleList = createSelector(selectFeatureCommon, (state) => state.vehicles);

export const selectStudentBarrier = createSelector(selectFeatureCommon, ({ studentBarrier }) => {
	if (studentBarrier !== null && studentBarrier.length) {
		return studentBarrier[0];
	}
	return {
		minimum_percentage_required: 40,
		father_qualification: 'Not Mandatory',
		mother_qualification: 'Not Mandatory',
		no_of_guardians: 2,
	};
});

export const selectAcademicSessions = createSelector(selectFeatureCommon, (state) => state.academicSession);

export const selectUpdateExamScheduleStatusApiStatusSuccess = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['update_exam_schedule_status'].status === ApiStatus.SUCCESS
);

export const selectExamTimetableScheduleApiStatusSuccess = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['exam_timetable_schedule'].status === ApiStatus.SUCCESS
);

export const selectStudentExamAttendanceSaveApiStatusSuccess = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['student_exam_attendance'].status === ApiStatus.SUCCESS
);

export const selectStudentExamMarksSaveApiStatusSuccess = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['student_exam_marks'].status === ApiStatus.SUCCESS
);
export const selectStudentExamMarksSaveApiStatusFailure = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['student_exam_marks'].status === ApiStatus.ERROR
);

export const selectStudentAttendanceBarrierSaveApiStatusSuccess = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['student_attendance_barrier'].status === ApiStatus.SUCCESS
);

export const selectHostelSMSSaveApiStatusSuccess = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['hostel_sms'].status === ApiStatus.SUCCESS
);

export const selectStudentMarksUploadApiStatusSuccess = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['exam_excel'].status === ApiStatus.SUCCESS
);
export const selectStudentMarksUploadApiStatusFailure = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['exam_excel_failure'].status === ApiStatus.ERROR
);

export const selectHostelBedsSaveApiStatusSuccess = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['student_bed_allocation'].status === ApiStatus.SUCCESS
);

export const selectStudentTransportSaveApiStatusSuccess = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['transport_student'].status === ApiStatus.SUCCESS
);

export const selectExamMarkSendSMSApiStatus = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['exam_sender'].status
);

export const selectSavePermissionPassApiStatus = createSelector(
	selectFeatureCommon,
	(state) => state.apiStatus && state.apiStatus['school_hostel_visitor'].status
);
