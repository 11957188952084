import { createFeatureSelector, createSelector } from '@ngrx/store';
import { groupBy } from 'ramda';
import { isNullOrUndefined } from 'src/app/helpers/utils';
import { AdminState } from '../admin.store';

const selectFeatureAdmin = createFeatureSelector<AdminState>('admin');

const selectAmissionState = createSelector(selectFeatureAdmin, (state) => state.enrolment);

export const selectStudentList = createSelector(selectAmissionState, (state) => state.studentList);

export const selectSearchStudentResult = createSelector(selectAmissionState, (state) => state.studentSearchResult);

export const selectSelectedStudentData = createSelector(
	selectAmissionState,
	(state) => state.studentDetailView.selectedStudent
);

export const selectSelectedStudentPrimaryDetail = createSelector(selectSelectedStudentData, (state) => {
	if (localStorage.getItem('academicDataObj') === null) {
		return;
	}
	const {
		schoolID: school,
		academicID: academic_year,
		employeeID: added_by,
	} = JSON.parse(localStorage.getItem('academicDataObj'));
	const primary_key = isNullOrUndefined(state)
		? null
		: isNullOrUndefined(state.primary_key)
		? state.student_id
		: state.primary_key;
	return {
		school,
		academic_year,
		primary_key,
		added_by,
	};
});

export const selectStudentStatus = createSelector(selectSelectedStudentData, (state) => state.status);

export const selectStudentPersonalDetails = createSelector(
	selectAmissionState,
	({ studentDetailView }) => studentDetailView.personalDetails
);

export const selectStudentParentDetails = createSelector(
	selectAmissionState,
	(state) => state.studentDetailView.parentDetails
);

export const selectStudentAddressDetails = createSelector(
	selectAmissionState,
	(state) => state.studentDetailView.addressDetails
);

export const selectParentAndAddressDetails = createSelector(
	selectStudentParentDetails,
	selectStudentAddressDetails,
	(parent, address) => ({ parent, address })
);

export const selectStudentGuardianDetails = createSelector(
	selectAmissionState,
	(state) => state.studentDetailView.guardianDetails
);

export const selectStudentTransportDetails = createSelector(
	selectAmissionState,
	(state) => state.studentDetailView.transportDetails
);

export const selectStudentDelete = createSelector(selectAmissionState, (state) => state.studentDelete);

export const selectStudentAttendanceDetails = createSelector(selectAmissionState, ({ studentDetailView }) => {
	//const grouped = groupBy((each) => (each.status as string).toLocaleLowerCase());
	return {data:studentDetailView.attendanceDetails};
});

export const selectStudentAndParentId = createSelector(
	selectSelectedStudentPrimaryDetail,
	selectStudentParentDetails,
	({ primary_key, ...rest }, parent) => {
		return {
			...rest,
			student: primary_key,
			parent: parent !== null ? parent.primary_key : '',
		};
	}
);

export const selectStudentFacilityDetails = createSelector(
	selectAmissionState,
	(state) => state.studentDetailView.facilityDetails
);

export const studentStatusChnage = createSelector(selectAmissionState, (state) => state.status_Change);

export const selectStudentFeesDetails = createSelector(
	selectAmissionState,
	(state) => state.studentDetailView.feesDetails
);
export const get_studnets_list_export = createSelector(selectAmissionState, (state) => state.studentListExport);
export const get_staff_list_export = createSelector(selectAmissionState, (state) => state.staffListExport);

export const student_promotion_list = createSelector(selectAmissionState, (state) => state.promotionStudents);
export const student_promotion_result = createSelector(selectAmissionState, (state) => state.studentPromotionResult);
export const builkStudentLoginCredRes = createSelector(selectAmissionState, (state) => state.studentLoginInvitaionRes);
