import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { createSelector, Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { equals } from 'ramda';
import { hostelActions } from 'src/app/admin/nav/store/hostel/hostel.actions';
import {
	selectHostelsBlocksList,
	selectHostelsList,
	selectSearchHostelStudentList,
} from 'src/app/admin/nav/store/hostel/hostel.selector';
import { StudentAndEmployeeSearchComponent } from '../../../communication/sms-notification/sms-form/employee-and-student-search/employee-and-student-search.component';
import { alertAction } from 'src/app/store/alert/alert.actions';
import { admissionActions } from 'src/app/admin/nav/store/admission/admission.action';
import {
	selectStudentAddressDetails,
	selectStudentGuardianDetails,
	selectStudentParentDetails,
} from 'src/app/admin/nav/store/admission/admission.selectors';
import { sideNavigationActions } from 'src/app/store/side-navigation/side-navigation.actions';
import { commonActions } from 'src/app/store/common/common.action';
import { ApiStatus } from 'src/app/store/store.types';
import { selectSavePermissionPassApiStatus, selectSelectedAcademicYear } from 'src/app/store/common/common.selector';
import { defaultEmployeesVisitor, permissionPurposeTypes } from 'src/app/admin/nav/store/hostel/hostel.types';
import { AlertService } from 'src/app/shared/Services/alert.service';
import { HostelAllocateService } from 'src/app/admin/shared/services/hostel-allocate.service';
import { name, name_regex } from 'src/app/shared/constants/constant-values';
import { HostelBarrierService } from 'src/app/master-admin/shared/hostel-barrier.service';


@Component({
	selector: 'app-visitor-form',
	templateUrl: './visitor-form.component.html',
	styleUrls: ['./visitor-form.component.scss'],
})
export class VisitorFormComponent implements OnInit, OnDestroy {
	minDate = new Date();
	maxDate = new Date(new Date().getTime()+(30*24*60*60*1000));
	visitorForm: FormGroup;
	hostelList$: Observable<any[]>;
	//hostelBlocksList$: Observable<any[]>;
	purposeReason:boolean = false;
	toMeetList = ['Student', 'Staff', 'Both'];
	selectStudentDetails:any;
	purposeTypes = permissionPurposeTypes;
	selectedStudentOrEmployee = [];
	studentRelationTypes = [];
	typeOfStudent:string='';
	employeeListNames = [];
	hostelStudentSearchResult$: Observable<any[]>;
	displayWithSearch = (value) => (value !== '' ? `${value.first_name} ${value.last_name}` : '');
	unsubscribe$ = new Subject<undefined>();
	captureImagesAsFile: any = {};
	emp_current_page_folder:number=0;
	stopEmpList:boolean = false;
	isShowdata : boolean = false;
	guardianList$: Observable<any[]>;
  @ViewChild('studentID', { static: false }) studentID: ElementRef;
  @ViewChild('searchStudent', { static: false }) searchStudent: ElementRef;

	//defaultEmployeesVisitor = defaultEmployeesVisitor;
	constructor(private fb: FormBuilder, private store$: Store, public dialog: MatDialog,
		private _alertService:AlertService,private hostelService:HostelAllocateService,
    private hostelBarrier:HostelBarrierService) {
		this.visitorForm = this.fb.group({
			visitor_name: ['',[Validators.pattern('^[a-zA-Z ]*$')]],
			gender: [''],
			mobile_number: ['', [Validators.pattern(/^[6-9]\d{9}$/)]],
			relation_to_student: [''],
			purpose: [''],
			purpose_description: [''],
			address: [''],
			visitor_photo: [''],
			visitor_id_card_photo: [''],
			signature: [''],
			student: ['', Validators.required],

			hostel: ['', [Validators.required]],
			from_date: [new Date(), Validators.required],
			from_time: [moment(new Date()).format('HH:mm'), Validators.required],
			to_date: [''],
			to_time: [''],
			added_by:[''],
			days_time_type: ['Days'],
		});
	}

	ngOnInit(): void {
		this.store$.dispatch(commonActions.updateApiStatus({ type: 'school_hostel_visitor', status: ApiStatus.NONE }));
		this.store$
			.select(selectHostelsList)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((hostel) => {
				this.hostelList$ = of(hostel);
				this.visitorForm.patchValue({ hostel: hostel.length > 0 ? (hostel as any[])[0].primary_key : '' });
				this.store$.dispatch(hostelActions.getHostelBlocksList({hostel: hostel.length > 0 ? (hostel as any[])[0].primary_key : ''}));
			});
		//this.hostelBlocksList$ = this.store$.select(selectHostelsBlocksList).pipe(takeUntil(this.unsubscribe$));
		this.hostelStudentSearchResult$ = this.store$
			.select(selectSearchHostelStudentList)
			.pipe(takeUntil(this.unsubscribe$));
			this.store$
			.select(selectSearchHostelStudentList)
			.pipe(takeUntil(this.unsubscribe$)).subscribe((res:any)=>{
				if(res.length>0 && this.typeOfStudent === 'search'){
					this.onStudentSelection(res[0],this.typeOfStudent);
				}
			})
      this.store$.dispatch(hostelActions.searchHostelStudentSuccess([]))
		this.store$
			.select(selectStudentParentDetails)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((parent) => {
				let formData = { visitor_name: '', gender: '', mobile_number: '' };
				const { relation_to_student } = this.visitorForm.value;
				if (equals(relation_to_student, 'Father') && parent !== null) {
					formData = {
						...formData,
						visitor_name: `${parent.father_first_name} ${parent.father_last_name}`,
						gender: 'Male',
						mobile_number: String(parent.father_mobile_number),
					};
				}
				if (equals(relation_to_student, 'Mother') && parent !== null) {
					formData = {
						...formData,
						visitor_name: `${parent.mother_first_name} ${parent.mother_last_name}`,
						gender: 'Female',
						mobile_number: String(parent.mother_mobile_number),
					};
				}
				this.visitorForm.patchValue(formData);
			});

		this.store$
			.select(selectStudentGuardianDetails)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((guardian) => {
				if (guardian.length > 0) {
					this.guardianList$ = of(guardian);
				} else {
					this.guardianList$ = of([]);
				}
			});

		this.store$
			.select(selectStudentAddressDetails)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((address: any) => {
				if (address !== null) {
					this.visitorForm.patchValue({ address: this.getAddress(address) });
				}
			});

		this.store$
			.select(selectSavePermissionPassApiStatus)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((status) => {
				if (status === 2) {
					this.onClose();
				}
			});
			this.getEmployeesList();
			this.getStudentRelationTypes();
	}

  getStudentRelationTypes():void{
    let data = {
      status:"True"
    };
    this.studentRelationTypes = [];
    this.hostelBarrier.get_relation_types(data).subscribe((res:any)=>{
      this.studentRelationTypes = res.data;
    },()=>{})
  }

	onHostelSelection(primary_key) {
		//this.store$.dispatch(hostelActions.getHostelBlocksList(primary_key));

    this.studentID.nativeElement.value = "";
    this.searchStudent.nativeElement.value = "";
		this.visitorForm.patchValue({
			visitor_name: '',
			gender: '',
			mobile_number: '',
			relation_to_student: '',
			purpose: '',
			address: '',
			visitor_photo: '',
			visitor_id_card_photo: '',
			signature: '',
			student: '',
			to_date: '',
			to_time: '',
		});
    this.selectStudentDetails = {};
    this.store$.dispatch(hostelActions.searchHostelStudentSuccess([]));
	}

	onStudentOrEmployeeSelection(searchType) {
		this.dialog.open(StudentAndEmployeeSearchComponent, {
			width: '60vh',
			data: {
				selectedList: this.selectedStudentOrEmployee,
				searchType,
				selectionType: 'single',
			},
		});
	}

	onFileInput(event: any, formControlName) {
		this.captureImagesAsFile[formControlName] = event.target.files[0];
		if (event.target.files) {
			const reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (e: any) => {
				this.visitorForm.get(formControlName).setValue(e.target.result);
			};
		}
	}

	onStudentSelection(selectedStudent,type:string) {

		this.typeOfStudent = type;
		this.selectStudentDetails = selectedStudent;
		this.visitorForm.get('student').setValue(selectedStudent.primary_key);

		this.store$.dispatch(admissionActions.studentAddressDetailsSuccess(null));
		this.store$.dispatch(admissionActions.studentParentDetailsSuccess(null));
		this.store$.dispatch(admissionActions.studentGuardianDetailsSuccess([]));
		this.visitorForm.patchValue({
			visitor_name: '',
			gender: '',
			mobile_number: '',
			relation_to_student: '',
			address: '',
		});
		this.store$.dispatch(admissionActions.setSelectedStudent(selectedStudent));
	}

	onRelationToStudentChange(event) {
		this.store$.dispatch(admissionActions.studentAddressDetailsSuccess(null));
		this.store$.dispatch(admissionActions.studentParentDetailsSuccess(null));
		this.store$.dispatch(admissionActions.studentGuardianDetailsSuccess([]));
		this.visitorForm.patchValue({
			visitor_name: '',
			gender: '',
			mobile_number: '',
			address: '',
		});
		if (equals(event.value, 'Father') || equals(event.value, 'Mother')) {
			this.store$.dispatch(admissionActions.getStudentParentDetails());
			this.store$.dispatch(admissionActions.getStudentAddressDetails());
		}

		if (equals(event.value, 'Guardian')) {
			this.store$.dispatch(admissionActions.getStudentGuardianDetails());
		}
	}

	onGuardianChange(event) {
		const { value } = event;
		this.visitorForm.patchValue({
			visitor_name: `${value.guardian_first_name} ${value.guardian_last_name}`,
			mobile_number: value.guardian_mobile_number,
			address: this.getAddress(value),
		});
	}

	getAddress({ door_no, street_name, locality, landmark, city, state, country, pincode }) {
		return `${door_no}, ${street_name}, ${locality}, ${landmark}, ${city}, ${state}, ${country}, ${pincode}`;
	}

	onMeetChoiceChange(event) {
		if (equals(event.value, 'Student') || equals(event.value, 'Both')) {
			this.visitorForm.get('student').setValidators([Validators.required]);
		} else {
			this.visitorForm.get('student').clearValidators();
		}
	}

	onVisitorSubmit() {
		if (this.visitorForm.valid) {
			const { from_date, from_time, to_date, to_time, student,purpose,
				purpose_description,days_time_type,added_by, ...rest }
			= this.visitorForm.value;

			let purposeType:string;
			if(purpose === 'Other'){
				purposeType = purpose_description;
			}else{
				purposeType = purpose;
			}

			if(!student){
				this._alertService.setAlert({message:'Please select student',type:'danger'});
				return;
			};
			const timeConvert = moment(from_time, ["h:mm A"]).format("HH:mm");
			const new_date = moment(from_date).add(to_date, 'days');
			const to_date_format = moment(new_date).format('YYYY-MM-DD');
			const date1 = moment(from_date);
			const date2 = moment(new_date);
			const days = date2.diff(date1, 'days');
			let permissionDays:number | string = 0;
			if(to_date !== ''){
				permissionDays = days +" "+days_time_type;
			}else{
				permissionDays = 0 +" "+ days_time_type;
			};

			const {employeeID: empID} = JSON.parse(localStorage.getItem('academicDataObj'));
			let permitted_by:string;
			if(added_by === ''){
				permitted_by = 	empID;
			}else{
				permitted_by = added_by;
			}
			let formattedValue = {
				...rest,

				meet_choices: 'Student',
				purpose:purposeType,
				permission_time:permissionDays,
				student: student,
				hostel_block: student.block_pk,
				added_by:permitted_by,
				from_datetime: `${moment(from_date).format('YYYY-MM-DD')} ${timeConvert}`,
				to_datetime: `${to_date_format} ${timeConvert}`,
				...this.captureImagesAsFile,
			};

			this.store$.dispatch(
				commonActions.updateApiStatus({ type: 'school_hostel_visitor', status: ApiStatus.PROGRESS })
			);
			this.store$.dispatch(hostelActions.saveVisitor(formattedValue));
			// this.dialog.close('');
			this.store$.dispatch(sideNavigationActions.closeSideNav());
		} else {
			this.visitorForm.markAsTouched();
		}



	}

	searchEvent(event,type:string) {
		if (event.target.value.length > 0) {
			this.isShowdata = true;
		}
		this.typeOfStudent = type;
		let searchInputVal:string=event.target.value;
		if (event.keyCode === 13) {
			   if (!searchInputVal) {
				   return this._alertService.setAlert({ message: 'Please enter student data', type: 'danger' });
			   }
			   this.store$.dispatch(
				hostelActions.searchHostelStudent({
					search_input: event.target.value,
					hostel: this.visitorForm.value.hostel,
				})
			);
		   }else{
			   return;
		   }
	}
	selectPurposeType(type:string){
		type === 'Other' ? this.purposeReason=true:this.purposeReason = false;
	}

	onClose() {
		this.store$.dispatch(sideNavigationActions.closeSideNav());
	}

	getEmployeesList(page:number=1){
		if (page === 1) {
			this.employeeListNames = [];
		}
		let params = {
			page:page,
			employee_status:"Approved"
		};
		const {schoolID: school} = JSON.parse(localStorage.getItem('academicDataObj'));
		if(school === '192dc1dc-4f06-4b25-ac9f-5d285cf74e72'){
			this.employeeListNames = defaultEmployeesVisitor;
			return;
		}else{
			params['school'] = school;
		}

		this.hostelService.getSchoolAllEmployee(params).subscribe((res:any)=>{
			if(res['status code'] === 200){
				this.employeeListNames.push(...res.data);
				this.emp_current_page_folder = res.current_page_number;
				this.stopEmpList = false;
			}else{
				this.stopEmpList = true;
			}
		},()=>{
			this.stopEmpList = true;
		})
	}

	getNextEmployees(data: any): void {
		 let page = this.emp_current_page_folder + 1;
		 this.getEmployeesList(page);
	}

	lettersAndSpace(event): boolean {
		let pattern = /^[a-zA-Z\s]*$/;
		let check = pattern.test(event.key);
		if (check) {
			return true;
		} else {
			return false;
		}
	}

	ngOnDestroy(): void {
		this.store$.dispatch(admissionActions.studentAddressDetailsSuccess(null));
		this.store$.dispatch(admissionActions.studentParentDetailsSuccess(null));
		this.store$.dispatch(admissionActions.studentGuardianDetailsSuccess([]));
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
