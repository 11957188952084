import { Directive, ElementRef, HostListener } from '@angular/core'
@Directive({
  selector: '[alphaNumSpecialChar]'
})
export class AlphaNumSpecialCharDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event:any) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^0-9a-zA-Z-_.# ]/, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

