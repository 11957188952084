import { Component, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import { AlertService } from '../../Services/alert.service';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  data: any;
  subscription: Subscription;
  alert: Alert;
  staticAlertClosed = false;
  successMessage = '';

  private success = new Subject<string>();

  constructor(private alertService: AlertService) {
    this.subscription = this.alertService.getAlert().subscribe((res: Alert) => {
      console.log(res)
      this.alert = {
        type: res.type,
        message: res.message
      };
      this.success.next(this.alert.message);
    });
  }

  ngOnInit(): void {
    this.success.subscribe(message => this.successMessage = message);
    this.success.pipe(
      debounceTime(5000)
    ).subscribe(() => this.successMessage = '');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
