import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-logout-confirmation',
	templateUrl: './logout-confirmation.component.html',
	styleUrls: ['./logout-confirmation.component.scss'],
})
export class LogoutConfirmationComponent implements OnInit {
	loadBtn: boolean = true;
	logoutSubscribe!: Subscription;
	constructor(
		private _router: Router,
		public dialogRef: MatDialogRef<LogoutConfirmationComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {}

	confirmExist(status: boolean) {
		if (status) {
			this.loadBtn = false;
			localStorage.clear();
			this._router.navigate(['/login']);
			location.reload();
			this.onNoClick(status);
		} else if (!status) {
			this.onNoClick(status);
		}
	}

	onNoClick(confirm: boolean): void {
		this.dialogRef.close(confirm);
	}

	//destory
	ngOnDestroy() {
		this.logoutSubscribe && this.logoutSubscribe.unsubscribe();
	}
}
