import { createAction, ExtractActionTypes } from "src/app/admin/nav/store/store.types";

export const ALERT_SUCCESS = '[Alert] Success';
export const ALERT_ERROR = '[Alert] Error';

export const alertAction = {
    success: (message: string) => createAction(ALERT_SUCCESS, { message }),
    error: (message: string) => createAction(ALERT_ERROR, { message })
}

export type AlertActions = ExtractActionTypes<typeof alertAction>