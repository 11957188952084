import {
	AfterContentChecked,
	AfterViewChecked,
	AfterViewInit,
	Component,
	ElementRef,
	OnChanges,
	OnDestroy,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import * as html2pdf from 'html2pdf.js';
import { hostelActions } from 'src/app/admin/nav/store/hostel/hostel.actions';
import { selectSelectedVisitor } from 'src/app/admin/nav/store/hostel/hostel.selector';
import { ApiService } from 'src/app/shared/Services/api.service';
import { HttpClient } from '@angular/common/http';
import { sideNavigationActions } from 'src/app/store/side-navigation/side-navigation.actions';
import { selectSelectedAcademicYear } from 'src/app/store/common/common.selector';
import { NgxPrintElementService } from 'ngx-print-element';
import { CommonService } from 'src/app/admin/shared/services/common.service';

@Component({
	selector: 'app-visitor-edit-form',
	templateUrl: './visitor-edit-form.component.html',
	styleUrls: ['./visitor-edit-form.component.scss'],
})
export class VisitorEditFormComponent implements OnInit, OnDestroy {
	selectedVisitor$: Observable<any>;
	minDate = new Date();
	maxDate = new Date();
	unsubscribe$ = new Subject<undefined>();
	captureImagesAsFile = null;
	// base64Images: any = {
	// 	logo: '',
	// 	profile: '',
	// 	qrCode: '',
	// };
	visitorData:any;
	school_data:any;
	@ViewChild('scroll') topScroll: ElementRef;
	editPermissionFormGroup: FormGroup;
	constructor(
		private store$: Store,
		private apiService: HttpClient,
		private fb: FormBuilder,
		public print: NgxPrintElementService,
		private commonService:CommonService
	) {
	
	}

	ngOnInit() {
		this.createUpdatePermissionForm();
		this.store$
			.select(selectSelectedVisitor)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(async (data) => {
				console.log(data);
				
				if (data === null) this.onClose();
				else {
					this.selectedVisitor$ = of(data);
					this.editPermissionFormGroup.patchValue({
						dropped_visitor_relation_with_student: data.relation_to_student,
						dropped_visitor_name: data.visitor_name,
						dropped_visitor_mobile_number: data.mobile_number,
						dropped_visitor_gender: data.gender,
					});
					this.visitorData = data;
					console.log(this.visitorData);
					
					// const schoolImage = JSON.parse(localStorage.getItem('schoolData'))[0].school_logo;
					// this.base64Images = {
					// 	logo: await this.getBase64FromUrl(schoolImage),
					// 	profile: await this.getBase64FromUrl(data.visitor_id_card_photo || ''),
					// 	qrCode: await this.getBase64FromUrl(data.visitor_qr_code || ''),
					// };
					//console.log(this.base64Images);
					
					if (this.topScroll !== undefined) this.topScroll.nativeElement.scrollTo(0, 0);
				}
			});

		this.store$
			.select(selectSelectedAcademicYear)
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((year) => {
				this.minDate = new Date(year.academic_start_date);
				this.maxDate = new Date(year.academic_end_date);
			});

			console.log(this.selectedVisitor$, "Z zxc zxczxczxczx czxc zxc zxc xzc")
		this.commonService.getSchoolInformation().subscribe((res:any)=>{
			this.school_data = res.data;
		});
		
	}

	createUpdatePermissionForm() {
		this.editPermissionFormGroup = this.fb.group({
			dropped_visitor_name: [''],
			dropped_visitor_mobile_number: ['', [Validators.pattern(/^[6-9]\d{9}$/)]],
			dropped_visitor_gender: [''],
			dropped_visitor_relation_with_student: ['', Validators.required],
			student_status: ['Returned', Validators.required],
			returnDate: [new Date(), Validators.required],
			returnTime: [moment(new Date()).format('HH:mm'), Validators.required],
		});
	}

	numberOnly(event): boolean {
		const charCode = event.which ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	isDeleteAllowed(selectedVisitor) {
		if (selectedVisitor !== null) {
			return (
				moment(new Date(selectedVisitor.student_return_date_time)).isValid() &&
				moment(new Date(selectedVisitor.student_return_date_time)).isAfter(new Date())
			);
		}
		return false;
	}

	updateVisitor() {
		if (this.editPermissionFormGroup.valid) {
			const { returnDate, returnTime, ...rest } = this.editPermissionFormGroup.value;
			const formattedValue = {
				...rest,
				student_return_date_time: `${moment(returnDate).format('YYYY-MM-DD')} ${returnTime}`,
			};
			this.store$.dispatch(hostelActions.updateVisitor(formattedValue));
		} else {
			this.editPermissionFormGroup.markAllAsTouched();
		}
	}

	deleteVisitor() {
		this.store$.dispatch(hostelActions.deleteVisitor());
	}

	onFileInput(event: any) {
		this.captureImagesAsFile = event.target.files[0];
		if (event.target.files) {
			const reader = new FileReader();
			reader.readAsDataURL(event.target.files[0]);
			reader.onload = (e: any) => {
				this.selectedVisitor$ = this.store$.select(selectSelectedVisitor).pipe(
					map((data) => ({
						...data,
						visitor_photo: e.target.result,
					})),
					takeUntil(this.unsubscribe$)
				);
			};
		}
	}

	getBase64FromUrl = async (url) => {
		const blob: any = await this.apiService.get(url, { responseType: 'blob' }).pipe(take(1)).toPromise();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = reader.result;
				resolve(base64data);
			};
		});
	};

	downloadVisitorCard() {
		this.selectedVisitor$.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
			var element = document.getElementById('visitor-card');
			var opt = {
				margin: 1,
				filename: `${data.visitor_number}.pdf`,
				image: { type: 'jpg', quality: 0.98 },
				html2canvas: { scale: 2 },
				jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
			};

			html2pdf(element, opt);
		});
	}

	printPermissionPass() {}

	onClose() {
		this.store$.dispatch(sideNavigationActions.closeSideNav());
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
		this.unsubscribe$.complete();
	}
}
