import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private _router:Router) { }
  loggedIn():boolean{
    return !!localStorage.getItem('employee_id');
  }
  
  _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable() };

  notifyUserAction() {
    this._userActionOccured.next();
  }

  loginUser() {
    //console.log('user login');
  }

  logOutUser() {
    this._router.navigate(['/login']);
    localStorage.clear();
  }

}
