import { Directive, ElementRef, HostListener, Input, OnDestroy,OnInit } from '@angular/core';

@Directive({
	selector: 'img[appImageUrlFallback]',
})
export class ImageUrlFallbackDirective implements OnDestroy {
	@Input() appImageUrlFallback: string;

	@Input() image: string;
    @Input() path: string;
	eRef: any;
    constructor(private elementRef: ElementRef) {
    }
    ngOnInit() {
        console.log("input-box keys  : ", this.image, this.path);
        if(!this.image){
            const element: HTMLImageElement = this.elementRef.nativeElement;
            element.setAttribute('src', this.path);
        }
    }
	count = 0;
	

	@HostListener('error')
	loadFallbackImage() {
		let fallbackUrl = JSON.parse(localStorage.getItem('schoolData'))[0].school_logo;
		const element: HTMLImageElement = this.eRef.nativeElement;
		if (this.count >= 1) fallbackUrl = this.appImageUrlFallback;
		element.setAttribute('src', fallbackUrl);
		this.count++;
	}

	ngOnDestroy(): void {
		this.count = 0;
	}
}
